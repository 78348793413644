<template>
    <div class="flex justify-end mb-5">
		<button class="default-button" @click="showDippingStockModal = true">New Dipping Entry</button>
        
        <el-dialog 
            title="New Dipping Entry" 
            :visible.sync="showDippingStockModal" 
            width="50%">

            <el-form ref="form" :model="dippingStock" :rules="rules">

                <el-row :gutter="10">
                    <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="Product" prop="product" size="mini" required>
                            <el-select v-model="dippingStock.product" placeholder="select product" size="small" class="w-full">
                                <el-option 
                                    v-for="item in GET_PRODUCTS.results" 
                                    :label="item.name" 
                                    :value="item.id" 
                                    :key="item.id">   
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="Opening" prop="opening" size="mini" required>
                            <el-input-number :min="0" v-model="dippingStock.opening" size="small" class="w-full"></el-input-number>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="Fuel Added" prop="added" size="mini">
                            <el-input-number :min="0" v-model="dippingStock.added" size="small" class="w-full"></el-input-number>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="8">
                        <el-form-item label="Dipping Readings" prop="dipping" size="mini" required>
                            <el-input-number :min="0" v-model="dippingStock.dipping" size="small" class="w-full"></el-input-number>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="8">
                    <el-form-item label="Date" prop="posting_date" size="mini" required>
                        <el-input type="date" v-model="dippingStock.posting_date" size="small"></el-input>
                    </el-form-item>
                </el-col>

                </el-row>

                <el-row :gutter="10">
                    <el-col :span="4">
                        <el-form-item>
                            <el-button 
                                @click="createDippingStock('form')" :loading="loading"
                                class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                                Create
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
               
            </el-form>

        </el-dialog>

    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
    name: "DippingStockCreate",

    data() {
        return {
            showDippingStockModal: false,
            search: "",
            sortField: "",
            loading: false,
            dippingStock: {
                product: null,
                opening: 0,
                added: 0,
                dipping: 0,
                posting_date: null
            },
            rules: {
				product: [
					{ required: true, message: "Please input product", trigger: "change" },
				],
				opening: [
					{ required: true, message: "Please input opening", trigger: "change" },
                ],
                dipping: [
					{ required: true, message: "Please input dipping", trigger: "change" },
                ]
			},
        }
    },
    computed: {
        ...mapGetters(["GET_PRODUCTS"])
    },

    methods: {
        ...mapActions(["handleDippingStockCreate", "fetchDippingStock", "fetchProducts"]),

        createDippingStock(form) {
            this.$refs[form].validate((valid) => {
				if(valid) {
                    this.handleDippingStockCreate(this.dippingStock).then(data => {
                        this.showDippingStockModal = false;
                        this.loading = false; 

                		this.$notify.success({ 
                            title: "Operation Successful", 
                            message: `Dipping Entry created` 
                        });

                        this.$refs[form].resetFields();
                        this.fetchDippingStock();
                    }).catch(err => {
                        this.$notify.error({ 
                            title: "Operation Failed", 
                            message: `Fill correct important fields` 
                        });
                        this.loading = false;
                    })
                } else { return false }
            })
        }
    },

    mounted() {
        this.fetchProducts({
            filter: {
                dipping: true
            }
        })
    }
}
</script>