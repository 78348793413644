<template>
    <div class="flex justify-end mb-5">
        <button class="default-button" @click="showCustomerModal = true">New Customer</button>
            
        <el-dialog 
            title="Create Customer"
			:visible.sync="showCustomerModal" 
            width="50%">

            <el-form ref="form" :model="customer" :rules="rules">

                <el-row :gutter="10">
                    <el-col :sm="12" :md="12" :lg="12" :xl="8">
                        <el-form-item label="Name" prop="name" size="mini" required>
                            <el-input type="text" v-model="customer.name" size="small"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="12" :lg="12" :xl="8">
                        <el-form-item label="Email" prop="email" size="mini">
                            <el-input type="email" v-model="customer.email" size="small"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="8">
                        <el-form-item label="Phone" prop="phone" size="mini">
                            <el-input type="text" v-model="customer.phone" placeholder="eg +255710202020" size="small"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="8">
                        <el-form-item label="LandLine" prop="landline" size="mini">
                            <el-input type="text" v-model="customer.landline" placeholder="eg +255710202020" size="small"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="8">
                        <el-form-item label="Region" prop="region" size="mini">
                            <el-input type="text" v-model="customer.region" size="small"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="12" :lg="6" :xl="8">
                        <el-form-item label="District" prop="district" size="mini">
                            <el-input type="text" v-model="customer.district" size="small"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="12" :lg="12" :xl="8">
                        <el-form-item label="Address" prop="address" size="mini">
                            <el-input type="text" v-model="customer.address" size="small"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="12" :lg="6" :xl="8">
                        <el-form-item label="Website" prop="website" size="mini">
                            <el-input type="text" v-model="customer.website" size="small"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="12" :lg="8" :xl="8">
                        <el-form-item label="TIN" prop="tin" size="mini">
                            <el-input type="text" v-model="customer.tin" size="small"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                
                <el-row :gutter="10">
                    <el-col :span="4">
                        <el-form-item>
                            <el-button 
                                @click="createCustomer('form')" :loading="loading"
                                class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                                Create
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

    </div>

</template>

<script>
import { mapActions } from "vuex"
import { errorRecursion } from "@/helpers/index.js"


export default {
    name: "CustomerCreate",

    data() {
        return {
            showCustomerModal: false,
            loading: false,
            customer: {
                name: "",
                email: "",
                phone: "",
                landline: "",
                region: "",
                district: "",
                ward: "",
                website: "",
                tin: "",
                address: ""    
            },
            rules: {
				name: [
					{ required: true, message: "Please input name", trigger: "change" },
                ],
				phone: [
					{ required: true, message: "Please input phone", trigger: "change" },
                ]
			},
        }
    },

    methods: {
        ...mapActions(["handleCustomerCreate", "fetchCustomers"]),

        createCustomer(form) {
    		this.$refs[form].validate((valid) => {
    			if(valid) {
                    this.loading = true
                	this.handleCustomerCreate(this.customer).then(data => {
                		this.showCustomerModal = false;
                        this.loading = false; 

                		this.$notify.success({ 
                            title: "Operation Successful", 
                            message: `Customer ${data.name} created` 
                        });

                        this.$refs[form].resetFields();
                        this.fetchCustomers();
                        
                	}).catch(error => {
                        this.loading = false;

                        let errorList = []
                        let message = ""
                        errorRecursion(error.data, errorList);
                        errorList.forEach((item) => {
                            message += "<li>" + item + "</li>"
                        })

                        this.$notify.error({ 
                            title: "Error",
                            dangerouslyUseHTMLString: true, 
                            message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                        });
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>