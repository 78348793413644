import axios from "axios"
import router from "../../router/index"

export default {
	state: {
		branches: [],
        contentModel: {},
		groups: {},
		permissions: {},
		user: {},
        activeBranch: null
	},

	getters: {
		GET_GROUPS(state) {
            return state.groups
        },

		GET_TOKEN(state) {
            if(localStorage.getItem("token")) {
                return JSON.parse(localStorage.getItem("token"))
            }
            return {}
        },

        GET_ACTIVE_BRANCH(state) {
            return state.activeBranch
        },

        GET_BRANCHES(state) {
        	return state.branches
        },

        GET_CONTENT_MODEL(state) {
            return state.contentModel
        },

		GET_PERMISSIONS(state) {
            return state.permissions
        },

        GET_USER(state) {
        	return state.user
        }
	},

	mutations: {
		SET_TOKEN(state, payload) {
			localStorage.setItem("token", JSON.stringify(payload))
		},

		SET_GROUPS(state, payload) {
            state.groups = payload
        },

		SET_ACTIVE_BRANCH(state, payload) {
            state.activeBranch = payload
		},

        SET_CONTENT_MODEL(state, payload) {
            state.contentModel = payload
        },

		SET_BRANCH(state, payload) {
			state.branches = payload
		},

		SET_PERMISSIONS(state, payload) {
            state.permissions = payload
        },

		SET_USER(state, payload) {
			state.user = payload
		},

		DELETE_TOKEN(state) {
            localStorage.removeItem("token")
            localStorage.removeItem("AAS")
        },
	},

	actions: {
		fetchAccessToken({commit, getters}, payload) {
			return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_TOKEN_URL, payload)
                .then(res => {
                    commit("SET_TOKEN", res.data)
					resolve(res.data)
                })
                .catch(err => {
                    commit("DELETE_TOKEN")
                    reject(err)
                })
            })
		},
		
		fetchGroups({commit, getters}, payload={}) {
            axios.get(process.env.VUE_APP_MANAGEMENT_URL + "/groups/", {
                params: {
                    "page": payload.page
                },
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_GROUPS", res.data);
            })
            .catch((err) => {
                // pass
            })
        },

        fetchContentModel({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_MANAGEMENT_URL + "/content-model/", {
                params: {
                    "page": payload.page
                },
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_CONTENT_MODEL", res.data);
            })
            .catch((err) => {
                // pass
            })
        },

        fetchPermissions({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_MANAGEMENT_URL + "/permissions/", {
                params: {
                    "page": payload.page
                },
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_PERMISSIONS", res.data);
            })
            .catch((err) => {
                // pass
            })
        },

		fetchUser({commit, getters}, payload) {
			axios.get(process.env.VUE_APP_MANAGEMENT_URL + "/user/personale/", {
				headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
			})
			.then(res => {
				commit("SET_USER", res.data)

				if(res.data.stations.length >= 2 || res.data.stations.length == 0) {
					commit("SET_BRANCH", res.data.stations)
					router.push({ name: "portal" });
				} else {
					commit("SET_ACTIVE_BRANCH", res.data.stations[0])

					router.push({ name: "dashboard" }).catch(() => {});
				}
			})
			.catch(err => {
				//commit("DELETE_TOKEN")
			})
		},

		handlePasswordChange({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_MANAGEMENT_URL + "/user/update_password/", payload, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

		handleGroupCreate({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_MANAGEMENT_URL + "/groups/", payload, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleGroupUpdate({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.put(process.env.VUE_APP_MANAGEMENT_URL + "/groups/" + payload.id + "/", payload, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleGroupDelete({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.delete(process.env.VUE_APP_MANAGEMENT_URL + "/groups/" + payload.id, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        fetchOrganization({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_MANAGEMENT_URL + "/organization/" + payload + "/", {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

		signOut({commit}) {
			commit("DELETE_TOKEN")
            location.reload();
		}
	}
}