<template>
    <div>
        <button class="default-button" @click="showBulkPaymentDialog = true">
            Add Payment
        </button>

        <el-dialog 
            title="Add Payment" 
            :visible.sync="showBulkPaymentDialog" 
            width="70%">

            <el-form ref="form" :model="payment" :rules="rules">

                <el-row :gutter="10">
                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
                        <el-form-item label="Category" prop="category" size="mini">
                            <el-input type="text" v-model="payment.category" size="small" disabled="true"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                        <el-form-item label="Customer" prop="customer" size="mini">
                            <el-select v-model="payment.customer" placeholder="select customer" size="small" class="w-full">
                                <el-option 
                                    v-for="customer in GET_CUSTOMERS.results"
                                    :remote-method="remoteFetchCustomer" 
                                    :label="customer.name" 
                                    :value="customer.id"
                                    :key="customer.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
                        <el-form-item label="Date" prop="posting_date" size="mini" required>
                            <el-input type="date" v-model="payment.posting_date" size="small"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
                        <el-form-item label="Amount" prop="amount" size="mini">
                            <el-input-number :min="0" v-model="payment.amount" size="small" class="w-full">
                            </el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="10">
                    <el-col :sm="24" :md="16" :lg="12" :xl="16">
                        <el-form-item label="Description" prop="description" size="mini">
                            <el-input type="text" v-model="payment.description" size="small" clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-button 
                    @click="acceptPayment('form')" 
                    :loading="loading" 
                    class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                        Create
                </el-button>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { errorRecursion } from "@/helpers/index.js"


export default {
    name: "BulkPayment",

    data() {
        return {
            showBulkPaymentDialog: false,
            loading: false,
            payment: {
                category: "INVOICE",
                customer: null,
                amount: 0,
                description: "",
                posting_date: null,
            },
            rules: {
                customer: [
					{ required: true, message: "Please input customer", trigger: "change" },
				],
                amount: [
					{ required: true, message: "Please input amount greater than 0", trigger: "change" },
				],
				posting_date: [
					{ required: true, message: "Please input date", trigger: "change" },
                ]
            }
        }
    },

    computed: {
        ...mapGetters([
            "GET_ACCOUNTS",
            "GET_CUSTOMERS",
        ]),
    },

    methods: {
        ...mapActions([
            "handleCreatePayment", 
            "fetchAccounts", 
            "fetchCustomers", 
        ]),

        remoteFetchAccount(query) {
            if(query != "") {
                this.fetchAccounts({"search": query})
                query = "";
            }

        },

        remoteFetchCustomer(query) {
            if(query != "") {
                this.fetchCustomers({"search": query})
                query = "";
            }
        },

        acceptPayment(form) {
            this.$refs[form].validate((valid) => {
                if(valid) {
                    this.loading = true;

                    this.handleCreatePayment(this.payment).then(data => {
                        this.loading = false
                        this.showBulkPaymentDialog = false

                        this.$notify.success({ 
                            title: "Operation Successful", 
                            message: `${data.category} payment accepted` 
                        });

                        this.$refs[form].resetFields();
                    }).catch(error => {
                        this.loading = false;

                        let errorList = []
                        let message = ""
                        errorRecursion(error.data, errorList);
                        errorList.forEach((item) => {
                            message += "<li>" + item + "</li>"
                        })

                        this.$notify.error({ 
                            title: "Error",
                            dangerouslyUseHTMLString: true, 
                            message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                        });
                    })
                } else {
                    return False
                }
            })
        },
    }
}
</script>