<template>
    <div class="flex justify-end mb-5">
        <button class="default-button" @click="showSupplierModal = true">New Supplier</button>

        <el-dialog 
            title="Create Supplier" 
            :visible.sync="showSupplierModal" 
            width="50%">

            <el-form ref="form" :model="supplier" :rules="rules">
                <el-row :gutter="10">
                    <el-col :sm="12" :md="12" :lg="12" :xl="8">
                        <el-form-item label="Name" prop="name" size="mini" required>
                            <el-input type="text" v-model="supplier.name"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="12" :lg="12" :xl="8">
                        <el-form-item label="Email" prop="email" size="mini">
                            <el-input type="email" v-model="supplier.email"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="8">
                        <el-form-item label="Phone" prop="phone" size="mini">
                            <el-input type="text" v-model="supplier.phone" placeholder="eg +255710202020"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="8">
                        <el-form-item label="LandLine" prop="landline" size="mini">
                            <el-input type="text" v-model="supplier.landline" placeholder="eg +255710202020"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="8">
                        <el-form-item label="Region" prop="region" size="mini" required>
                            <el-input type="text" v-model="supplier.region"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="12" :lg="6" :xl="8">
                        <el-form-item label="District" prop="district" size="mini" required>
                            <el-input type="text" v-model="supplier.district"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="12" :lg="12" :xl="8">
                        <el-form-item label="Address" prop="address" size="mini">
                            <el-input type="text" v-model="supplier.address"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="12" :lg="6" :xl="8">
                        <el-form-item label="Website" prop="website" size="mini">
                            <el-input type="text" v-model="supplier.website"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="12" :lg="8" :xl="8">
                        <el-form-item label="TIN" prop="tin" size="mini">
                            <el-input type="text" v-model="supplier.tin"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="12" :lg="8" :xl="8">
                        <el-form-item label="VRN" prop="vrn" size="mini">
                            <el-input type="text" v-model="supplier.vrn"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="10">
                    <el-col :span="4">
                        <el-form-item>
                            <el-button 
                                @click="createSupplier('form')" :loading="loading"
                                class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                                Create
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>
        </el-dialog>
    </div>

</template>

<script>
import { mapActions } from "vuex"

export default {
    name: "SupplierCreate",

    data() {
        return {
            showSupplierModal: false,
            loading: false,
            supplier: {
                name: "",
                email: "",
                phone: "",
                landline: "",
                region: "",
                district: "",
                ward: "",
                website: "",
                tin: "",
                vrn: "",
                address: "",
                account: null,       
            },
            rules: {
				name: [
					{ required: true, message: "Please input name", trigger: "change" },
                ],
                email: [
					{ required: true, message: "Please input email", trigger: "change" },
                ],
				phone: [
					{ required: true, message: "Please input phone", trigger: "change" },
                ],
                region: [
					{ required: true, message: "Please input region", trigger: "change" },
                ],
                district: [
					{ required: true, message: "Please input district", trigger: "change" },
                ]
			},
        }
    },

    methods: {
        ...mapActions(["handleSupplierCreate", "fetchSuppliers"]),

        createSupplier(form) {
    		this.$refs[form].validate((valid) => {
    			if(valid) {
                    this.loading = true
                	this.handleSupplierCreate(this.supplier).then(data => {
                		this.showSupplierModal = false;
                        this.loading = false; 

                		this.$notify.success({ 
                            title: "Operation Successful", 
                            message: `Supplier ${data.name} created` 
                        });

                        this.$refs[form].resetFields();
                        this.fetchSuppliers();
                	}).catch(err => {
                        this.loading = false; 
                        this.$notify.error({ 
                            title: "Operation Failed", 
                            message: `Fill correct important fields` 
                        });
                    })
                } else {
                    return false
                }
            })
        }
    },
}
</script>