import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import axios from "axios"
import Vue2Filters from "vue2-filters"
import excel from "vue-excel-export"
import "./plugins/element.js"
import "./assets/tailwind.css"
import "@/helpers/filters/datetime.js"
import "@/helpers/filters/words.js"
import VueApexCharts from "vue-apexcharts"
import Print from "vue-print-nb"

Vue.config.productionTip = false
Vue.http = Vue.prototype.$http = axios
axios.defaults.headers["Content-Type"] = "application/json"

Vue.use(axios)
Vue.use(Vue2Filters)
Vue.use(excel)
Vue.use(VueApexCharts)
Vue.component("apexchart", VueApexCharts)
Vue.use(Print);
require("dotenv").config()

import pdfMake from "pdfmake/build/pdfmake.js"
import pdfFonts from "pdfmake/build/vfs_fonts.js"
pdfMake.vfs = pdfFonts.pdfMake.vfs;

Vue.prototype.$http = axios;

axios.interceptors.response.use((response) => { return response }, (error)  => {
    if(error.response.status == 401 && error.response.data.code == "token_not_valid") {
        localStorage.removeItem("token")
    } else {
        return Promise.reject(error)
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem("token") == undefined) {
            next({name: "login"});
        } 
        else {
            next();
        }
    } 
    else {
        next();
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app")
