<template>
    <div>
        <el-tabs v-model="tabName">
            <el-tab-pane label="Products" name="products">
                <ProductCreate v-if="addProductPermission" />
                <ProductList />
            </el-tab-pane>

            <el-tab-pane label="Inventory" name="inventory">
                <ProductInventoryCreate v-if="addProductInventoryPermission" />
                <ProductInventoryList />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import ProductCreate from "@/components/products/ProductCreate"
import ProductList from "@/components/products/ProductList"
import ProductInventoryCreate from "@/components/stock/ProductInventoryCreate"
import ProductInventoryList from "@/components/stock/ProductInventoryList"
import { mapGetters } from "vuex"

export default {
    name: "Products",

    components: {
        ProductCreate,
        ProductList,
        ProductInventoryCreate,
        ProductInventoryList
    },

    data() {
        return {
            tabName: "products"
        }
    },

    computed: {
        ...mapGetters(["GET_USER"]),

        addProductPermission() {
            return this.GET_USER.permissions.some(item => item === "inventory.add_product")
        },

        addProductInventoryPermission() {
            return this.GET_USER.permissions.some(item => item === "inventory.add_productinventory")
        },
    }
}
</script>