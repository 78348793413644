<template>
    <div>
        <div class="space-y-10">
            <div class="flex items-center space-x-4">
                <img class="inline-block h-20 w-20 object-cover rounded-full ring-2 ring-gray-800" :src="user.photo" alt="avatar">

                <div class="space-y-2">
                    <p class="lg:text-base 2xl:text-lg font-medium">Account Information</p>
                    <p class="text-gray-400 text-xs italic">Updated on {{ user.updated_at | writtenDateTime }}</p>
                </div>
            </div>

            <div class="text-xs 2xl:text-sm">
                <div class="grid grid-cols-1 md:grid-cols-2">
                
                    <div class="grid grid-cols-2 gap-4 py-3 pr-3 border-b">
                        <p class="text-gray-400">Name</p>
                        <p>{{ user.first_name }} {{ user.middle_name }} {{ user.last_name }}</p>
                    </div>

                    <div class="grid grid-cols-2 py-3 pr-3 border-b">
                        <p class="text-gray-400">Birth Date</p>
                        <p>{{ user.dob }}</p>
                    </div>

                    <div class="grid grid-cols-2 gap-4 py-3 pr-3 border-b">
                        <p class="text-gray-400">Gender</p>
                        <p>{{ user.gender }}</p>
                    </div>

                    <div class="grid grid-cols-2 gap-4 py-3 pr-3 border-b">
                        <p class="text-gray-400">Marial Status</p>
                        <p>{{ user.martial_status }}</p>
                    </div>

                    <div class="grid grid-cols-2 gap-4 py-3 pr-3 border-b">
                        <p class="text-gray-400">Email</p>
                        <p>{{ user.email }}</p>
                    </div>

                    <div class="grid grid-cols-2 gap-4 py-3 pr-3 border-b">
                        <p class="text-gray-400">Mobile Number</p>
                        <p>{{ user.phone }}</p>
                    </div>

                    <div class="grid grid-cols-2 gap-4 py-3 pr-3 border-b">
                        <p class="text-gray-400">Account Status</p>
                        <p>
                            <span class="custom-label text-green-600 bg-green-200" v-if="user.is_active">active</span>
                            <span class="custom-label text-red-600 bg-red-200" v-else>deactivate</span>
                        </p>
                    </div>

                    <div class="grid grid-cols-2 gap-4 py-3 pr-3 border-b">
                        <p class="text-gray-400">Login</p>
                        <p>{{ user.last_login | writtenDateTime }}</p>
                    </div>
                </div>

                <div class="grid grid-cols-4 gap-4 py-3 pr-3 border-b">
                    <p class="text-gray-400">Roles</p>
                    <div class="col-span-3">
                        <span class="custom-label" v-for="group in user.groups" :key="group.id">{{ group.name }}</span>
                    </div>
                </div>

            </div>

            <!-- Password Change and Account activate -->
            <div class="grid grid-cols-3 gap-5" v-if="hasObjectRights">
                <div>
                    <div class="mb-5">
                        <p class="lg:text-base font-medium">Change password</p>
                        <p class="text-xs">
                            We will email you a confirmation when changing your password, so please expect that email after submitting.
                        </p>
                    </div>

                    <el-form ref="form" :model="passwordChange" :rules="rules">
                        <el-form-item label="Current password" size="mini">
                            <el-input type="password" v-model="passwordChange.currentPassword" prop="currentPassword" :show-password="true" size="small">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="New password" size="mini">
                            <el-input type="password" v-model="passwordChange.newPassword" prop="newPassword" :show-password="true" size="small">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="Repeat new password" size="mini">
                            <el-input type="password" v-model="passwordChange.repeatNewPassword" prop="repeatNewPassword" :show-password="true" size="small">
                            </el-input>
                        </el-form-item>

                        <el-form-item>
                            <button @click="performPasswordChange('form')" class="action-button">
                                Save Changes
                            </button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
    name: "MemberDetail",

    props: {
        userId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            user: {},
            passwordChange: {
                currentPassword: "",
                newPassword: "",
                repeatNewPassword: "",
            },
            rules: {
                currentPassword: [
                    { required: true, message: "Please fill current password", trigger: "change" }
                ],

                newPassword: [
                    { required: true, message: "Please fill new password", trigger: "change" },
                    { min: 6, max: 20, message: "Length should be 6 to 20", trigger: "blur" }
                ],

                repeatNewPassword: [
                    { required: true, message: "Please fill field', trigger", trigger: "change" },
                    { min: 6, max: 20, message: "Length should be 6 to 20", trigger: "change" }
                ],
            }
        }
    },

    computed: {
        ...mapGetters(["GET_USER"]),

        hasObjectRights() {
            if(this.GET_USER.permissions.some(item => item === "can_edit_personale") 
                && this.userId === this.GET_USER.email) {
                return true
            }
            return false
        }
    },

    methods: {
        ...mapActions(["fetchMemberDetail", "handlePasswordChange", "signOut"]),

        performPasswordChange(form) {
            this.$refs[form].validate((valid) => {
    			if(valid) {
                    this.handlePasswordChange(this.passwordChange).then(data => {
                        this.$notify.success({ 
                            title: "Operation Successful",  
                        });
                        this.signOut();
                    }).catch(err => {
                        this.$notify.error({ 
                            title: "Operation Successful", 
                            message: "Password did not match or wrong old password"
                        });
                    })
                } else {
                    this.$refs[form].resetFields();
                    return false
                }
            })
        }

    },

    mounted() {
        this.fetchMemberDetail(this.userId).then(data => {
            this.user = data;
        })
    }
}
</script>