<template>
    <el-dialog
        title="Edit Invoice" 
        :visible.sync="showInvoiceDialog" 
        width="50%">

        <el-form ref="form" :model="invoice">

            <el-row :gutter="10">
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="6">
                    <el-form-item label="Title" prop="title" size="mini">
                        <el-input type="text" placeholder="invoice for ..." v-model="invoice.title" size="small"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="6">
                    <el-form-item label="Customer" prop="customer" size="mini" required>
                        <el-select	v-model="invoice.customer" 
                                    filterable remote
                                    :remote-method="remoteFetchCustomer" 
                                    placeholder="select customer" 
                                    size="small" class="w-full">

                            <el-option	v-for="customer in GET_CUSTOMERS.results" 
                                        :label="customer.name" 
                                        :value="customer.id" 
                                        :key="customer.id">   
                            </el-option>

                        </el-select>
                    </el-form-item>
                </el-col>
                
                <el-col :xs="24" :sm="9" :md="12" :lg="9" :xl="6">
                    <el-form-item label="Invoice Date" prop="invoice_date" size="mini" required>
                        <el-date-picker	v-model="invoice.invoice_date"
                                        type="date" format="yyyy-MM-dd" 
                                        placeholder="Pick a day" size="small"
                                        @change="changedInvoiceDate">
                        </el-date-picker>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="9" :md="12" :lg="9" :xl="6">
                    <el-form-item label="Due Date" prop="due_date" size="mini" required>
                        <el-date-picker	v-model="invoice.due_date" 
                                        type="date" format="yyyy-MM-dd" 
                                        placeholder="Pick a day" size="small"
                                        @change="changedDueDate">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="10">
                <el-col :span="4">
                    <el-form-item>
                        <el-button 
                            @click="updateInvoice" :loading="loading"
                            class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                            Save Changes
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>
    </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { DateTime } from "luxon"
import { errorRecursion } from "@/helpers/index.js"

export default {
    name: "AccountUpdate",

    props: {
        updateObject: {
            type: Object,
            required: true
        },

        showInvoiceDialog: {
            type: Boolean,
            default: false
        }
    },

    data() {
    	return {
    		invoice: {},
            loading: false
    	}
    },

    watch: {
        updateObject: function(val) {
            this.invoice = JSON.parse(JSON.stringify(val));
            this.invoice.customer = val.customer.id
            
        }
    },

    computed: {
    	...mapGetters(["GET_CUSTOMERS"])
    },

    methods: {
    	...mapActions(["handleInvoiceUpdate", "fetchInvoices", "fetchCustomers"]),

        changedInvoiceDate(value) {
            this.invoice.invoice_date = DateTime.fromISO(value.toISOString()).toISODate();  
        },

        changedDueDate(value) {
            this.invoice.due_date = DateTime.fromISO(value.toISOString()).toISODate();
        },

    	updateInvoice() {
            this.loading = true;

            this.handleInvoiceUpdate(this.invoice).then(data => {
                this.$emit("onSuccess");
                this.loading = false;

                this.$notify.success({ 
                    title: "Operation Successful", 
                    message: `Invoice with code ${data.code} updated` 
                });

                this.fetchInvoices();
            }).catch(error => {
                this.$emit("onSuccess");
                this.loading = false;

                let errorList = []
                let message = ""
                errorRecursion(error.data, errorList);
                errorList.forEach((item) => {
                    message += "<li>" + item + "</li>"
                })

                this.$notify.error({ 
                    title: "Error",
                    dangerouslyUseHTMLString: true, 
                    message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                });
            })
    	},

        remoteFetchCustomer(query) {
            if(query != "") {
                this.fetchCustomers({"search": query})
                query = "";
            }

        }
    }
}
</script>