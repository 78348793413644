<template>
    <el-dialog
        title="Edit Member"
        :visible.sync="showMemberModal"
        width="50%">

        <el-form ref="form" :model="member">
            <el-row :gutter="10">
            
                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="First Name" prop="first_name" size="mini" required>
                            <el-input type="text" v-model="member.first_name" size="small" clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="Middle Name" prop="middle_name" size="mini" required>
                            <el-input type="text" v-model="member.middle_name" size="small" clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="Surname Name" prop="last_name" size="mini" required>
                            <el-input type="text" v-model="member.last_name" size="small" clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="Date of Birth" prop="dob" size="mini" required>
                            <el-input type="date" v-model="member.dob" size="small" clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="Gender" prop="gender" size="mini" required>
                            <el-select v-model="member.gender" size="small" class="w-full">
                                <el-option value="M" label="Male"></el-option>
                                <el-option value="F" label="Female"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="Martial Status" prop="martial_status" size="mini" required>
                            <el-select v-model="member.martial_status" size="small" class="w-full">
                                <el-option value="SINGLE" label="Single"></el-option>
                                <el-option value="MARRIED" label="Married"></el-option>
                                <el-option value="DIVORCED" label="Divorced"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="Phone" prop="phone" size="mini" required>
                            <el-input type="text" v-model="member.phone" placeholder="eg +255710202020" size="small" clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="Role" prop="groups" size="mini" required>
                            <el-select v-model="member.groups" size="small" class="w-full" multiple collapse-tags>
                                <el-option 
                                    :label="option.name" :value="option.id" 
                                    v-for="option in GET_GROUPS.results" 
                                    :key="option.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <!-- <el-col :span="12">
                        <el-form-item label="Photo" size="mini">
                            <FileInput label="document-update" :files="member.photo" @upload-single="onImageUpload">
                                <template v-slot:placeholder>
                                    <span class="truncate text-xs" v-if="member.photo">{{ member.photo.name }}</span>
                                </template>
                            </FileInput>
                        </el-form-item>
                    </el-col> -->
                    
                </el-row>

            <el-row :gutter="10">
                <el-col :span="4">
                    <el-form-item>
                        <el-button 
                            @click="updateMember" :loading="loading"
                            class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                            Save Changes
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>
        
    </el-dialog>
</template>

<script>
import FileInput from "@/components/common/FileInput"
import { mapActions, mapGetters } from "vuex";

export default {
    name: "GroupUpdate",

    components: {
        FileInput
    },

    props: {
        updateObject: {
            type: Object,
            required: true
        },
        showMemberModal: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            member: {},
            loading: false
        }
    },

    watch: {
        updateObject: function(val) {
            this.member = (
                (
                    { 
                        id, first_name, middle_name, last_name, dob, gender,
                        martial_status, email, phone, groups, is_active, photo, signature 
                    }
                ) => (
                        { 
                            id, first_name, middle_name, last_name, dob, gender,
                            martial_status, email, phone, groups, is_active, photo, signature
                        }
                    ))(val);
        },

        member: function(val) {
            val.groups = val.groups.map(j => j.id);
        }
    },

    computed: {
    	...mapGetters(["GET_GROUPS"]),
    },

    methods: {
    	...mapActions(["handleMemberUpdate", "fetchMembers"]),

        onImageUpload(value) {
            this.member.photo = value
        },

    	updateMember() {
            if(this.member["photo"] == null || typeof this.member["photo"] == "string") {
                delete this.member["photo"]
            }

            if(this.member["signature"] == null || typeof this.member["signature"] == "string") {
                delete this.member["signature"]
            }
            
            const formData = new FormData();
            Object.keys(this.member).forEach(k => {
                if(Array.isArray(this.member[k])) {

                    this.member[k].forEach(subItem => formData.append(k, subItem));

                } else {
                    formData.append(k, this.member[k]);
                }
            })
            this.loading = true;

    		this.handleMemberUpdate({"email": this.member.email, "data": formData}).then(data => {
                this.$emit("onSuccess")
                this.loading = false;

                this.$notify.success({ 
                    title: "Operation Successful", 
                    text: `User with mail, ${data.email} updated`
                });

                this.fetchMembers();
            })
    	}
    }
}
</script>