<template>
	<div class="flex flex-col justify-center items-center px-10 min-h-screen text-white bg-blue-600">
		<div class="w-3/4 lg:w-1/3 2xl:w-1/4 space-y-5">
			<div class="text-center">
				<p class="text-3xl font-bold mb-1">Aula Sales System</p>
				<p class="text-base">Sign in to continue</p>
			</div>

			<el-form ref="form" :model="form" :rules="rules" class="w-full bg-blue-800 px-8 py-12 border-none shadow rounded-md">
				<div class="w-full">
					<el-form-item>
						<el-input type="email" placeholder="email" v-model="form.email" prop="email" prefix-icon="el-icon-message" clearable>
						</el-input>
					</el-form-item>

					<el-form-item>
						<el-input type="password" placeholder="password" v-model="form.password" prop="password" prefix-icon="el-icon-lock" :show-password="true">
						</el-input>
					</el-form-item>

					<el-form-item>
						<el-button @click="signIn('form')" :loading="loading" 
							class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-600 border border-blue-600 rounded-md px-6 py-2 focus:outline-none w-full">
							Login
						</el-button>
					</el-form-item>
				</div>
				<div class="flex justify-between items-center">
					<p class="text-sm">Forgot Password</p>
					<p class="text-sm">Terms & Agreement</p>
				</div>
			</el-form>

		</div>
		
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { errorRecursion } from "@/helpers/index.js"

export default {
	name: "SignIn",
	
	data() {
		return {
			form: {
				email: "", // justin123@mail.com
				password: "", // Fifa@2014
			},
			loading: false,
			rules: {
				email: [
					{ required: true, message: 'Please input username', trigger: "change" },
				],
				password: [
					{ required: true, message: 'Please input password', trigger: "change" },
				] 
			},
		}
	},

	computed: {
		...mapGetters(["GET_TOKEN"])
	},

	methods: {
		...mapActions(["fetchAccessToken"]),

		signIn(form) {

			this.$refs[form].validate((valid) => {
				if(valid) {
					this.loading = true;

					this.fetchAccessToken(this.form)
					.then(data => {
						this.loading = false
						location.reload();

					}).catch(error => {
						let errorList = []
						let message = ""
						errorRecursion(error.data, errorList);
						errorList.forEach((item) => {
							message += "<li>" + item + "</li>"
						})

						this.$notify.error({ 
							title: "Error",
							dangerouslyUseHTMLString: true, 
							message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
						});
					})

				} else { return false; }
			});

		}
	}, 
	
	mounted() {
		if(this.GET_TOKEN?.access) {
			this.$router.push({path: "/"})
		}
	}
}
</script>
