<template>
	<div class="flex justify-end mb-5">
		<button class="default-button" @click="showGroupModal = true">New Group</button>

		<el-dialog
			title="Create Group"
			:visible.sync="showGroupModal"
			width="30%">

			<el-form ref="form" :model="group" :rules="rules">
				<el-form-item label="Name" prop="name" size="mini" required>
                    <el-input type="text" v-model="group.name" size="small" clearable>
                    </el-input>
                </el-form-item>

                <el-form-item label="Permissions" prop="permissions" size="mini" required>
                    <el-select v-model="group.permissions" size="small" class="w-full" multiple collapse-tags>
						<el-option 
							:label="option.name" :value="option.id" 
							v-for="option in GET_PERMISSIONS.results" 
							:key="option.id">
						</el-option>
					</el-select>
                </el-form-item>

                <el-form-item>
                    <el-button 
                        @click="createGroup('form')" :loading="loading"
                        class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                        Create
                    </el-button>
                </el-form-item>
			</el-form>
			
		</el-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "GroupCreate",

    data() {
    	return {
    		showGroupModal: false,
            loading: false,
    		group: {
                name: "",
                permissions: []
            },

            rules: {
            	name: [
                    { required: true, message: "Please input name", trigger: "change" },
                ],
                permissions: [
                    { required: true, message: "Please input permissions", trigger: "change" },
                ]
            }
    	}
    },

    computed: {
    	...mapGetters(["GET_PERMISSIONS"])
    },

    methods: {
    	...mapActions(["handleGroupCreate", "fetchGroups"]),

    	createGroup(form) {
    		this.$refs[form].validate((valid) => {
    			if(valid) {
                	this.handleGroupCreate(this.group).then(data => {
                		this.showGroupModal = false;
                        this.loading = false;

                		this.$notify.success({ 
                            title: "Operation Successful", 
                            message: `Group ${data.name} created` 
                        });

                        this.$refs[form].resetFields();
                        this.fetchGroups();
                	}).catch(err => {
                        this.showGroupModal = false;
                        this.loading = false;
                    })
                } else {
                	return false
                }
    		})
    	}
    }
}
</script>