<template>
    <div class="centered-middle">
        <h4 class="text-center">SELECT STATION</h4>

        <el-row :gutter="20" type="flex" justify="center">
            <el-col :xs="12" :sm="8" :md="4" :lg="4" v-for="station in GET_BRANCHES" :key="station.id">
                <el-tag effect="plain" type="info" @click="navigate_to_station(station)">
                    <span class="text-center">{{ station.name }}</span>
                </el-tag>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import {  mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    name: 'Portal',

    computed: {
        ...mapGetters([
            'GET_BRANCHES'
        ])
    },

    methods: {
        ...mapActions([
            'fetchUser'
        ]),

        ...mapMutations([
            'SET_ACTIVE_STATION'
        ]),

        navigate_to_station(payload) {
            this.SET_ACTIVE_STATION(payload);
            
			this.$router.push({path: '/'});
        }
    },

    mounted() {
        this.fetchUser();
    }
}
</script>

<style scoped>
.centered-middle {
    margin-top: 200px;
    overflow-x: hidden;
}

.text-center {
    text-align: center;
}

.el-tag > span {
    padding: 15px 7px;
    font-size: 14px;
    font-weight: 500;
}

</style>