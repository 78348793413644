import axios from "axios"

export default {
    state: {
        members: {},
    },

    getters: {
        GET_MEMBERS(state) {
            return state.members
        }
    },

    mutations: {
        SET_MEMBERS(state, payload) {
            state.members = payload
        }
    },

    actions: {
        fetchMembers({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_MANAGEMENT_URL + "/user/", {
                params: Object.assign({
                    "ordering": payload.ordering,
                    "page": payload.page,
                    "search": payload.search,
                    "branch": getters.GET_ACTIVE_BRANCH.id 
                }, payload.filter),
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_MEMBERS", res.data);
            })
            .catch((err) => {
                // pass
            })
        },

        fetchMemberDetail({ commit, getters }, userId) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_MANAGEMENT_URL + "/user/" + userId + "/", {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleMemberCreate({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_MANAGEMENT_URL + "/user/", payload, 
                {
                    params: {
                        "branch": getters.GET_ACTIVE_BRANCH.id
                    },
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleMemberUpdate({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.put(process.env.VUE_APP_MANAGEMENT_URL + "/user/" + payload.email + "/", payload.data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleToggleAccount({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_MANAGEMENT_URL + "/user/" + payload.email + "/",
                { 
                    "is_active": payload.is_active ? false : true
                },
                {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },
    }
}
