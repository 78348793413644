<template>
    <div class="flex justify-end mb-5">
		<button 
            class="default-button" 
            @click="showDeliveryDialog = true">
            New Delivery
        </button>

        <el-dialog 
            title="New Delivery" 
            :visible.sync="showDeliveryDialog" 
            width="50%">

            <el-form ref="form" :model="delivery" :rules="rules">

                <el-row :gutter="10">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                        <el-form-item label="Code" prop="code" size="mini">
                            <el-input type="text" v-model="delivery.code" size="small"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                        <el-form-item label="Invoice" prop="invoice" size="mini" required>
                            <el-select	v-model="delivery.invoice" 
                            			filterable remote
                                		:remote-method="remoteFetchInvoice" 
                                		placeholder="select invoice" 
                                        size="small" class="w-full">

                                <el-option	v-for="invoice in GET_INVOICES.results" 
                                			:label="invoice.code" 
                                			:value="invoice.id" 
                                			:key="invoice.id">   
                                </el-option>

                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                        <el-form-item label="Delivered By" prop="deliverer" size="mini">
                            <el-input type="text" v-model="delivery.deliverer" size="small"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                        <el-form-item label="Received By" prop="receiver" size="mini">
                            <el-input type="text" v-model="delivery.receiver" size="small"></el-input>
                        </el-form-item>
                    </el-col>
                    
                    <el-col :xs="24" :sm="9" :md="12" :lg="9" :xl="6">
                        <el-form-item label="Delivery Date" prop="delivery_date" size="mini" required>
                            <el-date-picker	v-model="delivery.delivery_date"
                            				type="date" format="yyyy-MM-dd" 
                            				placeholder="Pick a day" size="small">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="10">
                    <el-col :span="4">
                        <el-form-item>
                            <el-button 
                                @click="createDelivery('form')" :loading="loading"
                                class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                                Create
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>
        </el-dialog>

    </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { errorRecursion } from "@/helpers/index.js"

export default {
    name: "InvoiceCreate",

    data() {
        return {
            showDeliveryDialog: false,
            loading: false,
            delivery: {
                code: "",
                invoice: null,
                deliverer: "",
                receiver: "",
                delivery_date: "",   
            },
            rules: {
                code: [
                    { required: true, message: "Please input code", trigger: "change" },
                ],
                invoice: [
                    { required: true, message: "Please select invoice", trigger: "change" },
                ],
                delivery_date: [
                    { required: true, message: "Please input delivery date", trigger: "change" },
                ],
                deliverer: [
                    { required: true, message: "Please input deliverer", trigger: "change" },
                ]
            }
        }
    },

    computed: {
        ...mapGetters(["GET_INVOICES"])
    },

    methods: {
        ...mapActions(["handleDeliveryCreate", "fetchDeliveries", "fetchInvoices"]),

        createDelivery(form) {
            this.$refs[form].validate((valid) => {
                if(valid) {
                    this.delivery.delivery_date = new Date(this.delivery.delivery_date).toISOString().slice(0, 10)

                    this.loading = true; 
                	this.handleDeliveryCreate(this.delivery).then(data => {
                		this.showDeliveryDialog = false;
                        this.loading = false; 

                		this.$notify.success({ 
                            title: "Operation Successful", 
                            message: `Delivery with code ${data.code} created` 
                        });

                        this.$refs[form].resetFields();
                        this.fetchDeliveries();
                	}).catch(error => {
                        this.loading = false;

                        let errorList = []
                        let message = ""
                        errorRecursion(error.data, errorList);
                        errorList.forEach((item) => {
                            message += "<li>" + item + "</li>"
                        })

                        this.$notify.error({ 
                            title: "Error",
                            dangerouslyUseHTMLString: true, 
                            message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                        });
                    })

                } else { return false; }
            })
        },

        remoteFetchInvoice(query) {
            if(query != "") {
                this.fetchInvoices({"search": query})
                query = "";
            }
        }
    }
}
</script>