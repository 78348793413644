<template>
    <div>
        <el-tabs v-model="tabName">
            <el-tab-pane label="Pumps" name="pumps">
                <PumpCreate v-if="addPumpPermission" />
                <PumpList />
            </el-tab-pane>

            <el-tab-pane label="Readings" name="readings">
                <PumpReadingCreate v-if="addPumpReadingPermission" />
                <PumpReadingList />
            </el-tab-pane>
            
            <el-tab-pane label="Stock" name="sales">
                <DippingStockCreate v-if="addDippingStockPermission" />

                <DippingStockList />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import DippingStockCreate from "@/components/fuel/DippingStockCreate"
import DippingStockList from "@/components/fuel/DippingStockList"
import PumpCreate from "@/components/pumps/PumpCreate"
import PumpList from "@/components/pumps/PumpList"
import PumpReadingCreate from "@/components/pumps/PumpReadingCreate"
import PumpReadingList from "@/components/pumps/PumpReadingList"
import { mapGetters } from "vuex"

export default {
    name: "Pumps",

    components: {
        DippingStockCreate,
        DippingStockList,
        PumpCreate, 
        PumpList,
        PumpReadingCreate,
        PumpReadingList
    },

    data() {
        return {
            tabName: "pumps"
        }
    },

    computed: {
        ...mapGetters(["GET_USER"]),

        addDippingStockPermission() {
            return this.GET_USER.permissions.some(item => item === "inventory.add_dippingstock")
        },

        addPumpPermission() {
            return this.GET_USER.permissions.some(item => item === "inventory.add_pump")
        },

        addPumpReadingPermission() {
            return this.GET_USER.permissions.some(item => item === "inventory.add_pumpreading")
        },
    }
}
</script>