import axios from "axios"

export default {
    state: {
        accounts: {},
    },

    getters: {
        GET_ACCOUNTS(state) {
            return state.accounts
        },

        GET_ACCOUNT_CATEGORIES(state) {
            return [
                { db: "CURRENT-ASSETS", text: "Current Assets" },
                { db: "FIXED-ASSETS", text: "Fixed Assets" }, 
                { db: "EQUITY", text: "Equity" },
                { db: "EXPENDITURE", text: "Expenditure" },
                { db: "CURRENT-LIABILITY", text: "Current Liability" },
                { db: "LONG-TERM-LIABILITY", text: "Long Term Liability" },  
                { db: "REVENUE", text: "Revenue" }
            ]
        }
    },

    mutations: {
        SET_ACCOUNTS(state, payload) {
            state.accounts = payload
        }
    },

    actions: {
        fetchAccounts({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_FINANCE_URL + "/accounts/", {
                params: Object.assign({
                    "ordering": payload.ordering, 
                    "page": payload.page, 
                    "search": payload.search,
                    "branch": getters.GET_ACTIVE_BRANCH.id 
                }, payload.filter),
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_ACCOUNTS", res.data);
            })
            .catch((err) => {
                // pass
            })
        },

        handleAccountCreate({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_FINANCE_URL + "/accounts/", payload, 
                {
                    params: {
                        "branch": getters.GET_ACTIVE_BRANCH.id
                    },
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleAccountUpdate({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_FINANCE_URL + "/accounts/" + payload.id + "/", payload, 
                {
                    params: {
                        "branch": getters.GET_ACTIVE_BRANCH.id
                    },
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleAccountDelete({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_FINANCE_URL + "/accounts/" + payload.id + "/", {"trashed": true}, {
                    params: {
                        "branch": getters.GET_ACTIVE_BRANCH.id
                    },
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        }
    }
}