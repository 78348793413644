<template>
    <div>
        <div class="flex justify-between items-center mb-5">
            <p class="font-medium">{{ customer.name }}</p>

            <el-button icon="el-icon-message" circle></el-button>
        </div>

        <el-tabs v-model="tabName" @tab-click="handleTabChange">

            <el-tab-pane label="General" name="general">
                <section className="py-10">
                    <h2 class="text-lg font-medium mb-6">Customer Details</h2>

                    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-8 text-xs">
                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 py-2">
                            <p>Name</p>
                            <p class="font-medium">{{customer.name}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 py-2">
                            <p>Phone Number</p>
                            <p class="font-medium">{{customer.phone}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 py-2">
                            <p>Landline</p>
                            <p class="font-medium">{{customer.landline}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 py-2">
                            <p>E-mail</p>
                            <p class="font-medium">{{customer.email}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 py-2">
                            <p>Website</p>
                            <p class="font-medium">{{customer.website}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 py-2">
                            <p>Region</p>
                            <p class="font-medium">{{customer.region}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 py-2">
                            <p>District</p>
                            <p class="font-medium">{{customer.district}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 py-2">
                            <p>Ward</p>
                            <p class="font-medium">{{customer.ward}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 py-2">
                            <p>Address</p>
                            <p class="font-medium">{{customer.address}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 py-2">
                            <p>TIN</p>
                            <p class="font-medium">{{customer.tin}}</p>
                        </div>
                    </div>

                </section>
            </el-tab-pane>

            <!-- Invoice -->
            <el-tab-pane label="Invoice" name="invoice">
                <section class="py-4">
                    <div class="flex justify-between space-x-4 items-center mb-4">
                        <div>
                            <el-date-picker 
                                v-model="invoice_date_range" 
                                size="mini" 
                                type="daterange"
                                range-separator="To"
                                start-placeholder="Start date"
                                end-placeholder="End date"
                                @change="handleInvoiceDateChange">
                            </el-date-picker>
                        </div>

                        <button class="basic-button" v-if="selectedCustomerInvoices.length > 0" plain>
                            <export-excel 
                                :data="selectedCustomerInvoices" 
                                :fields="exportInvoiceFields" 
                                :name="customer.name + ' Invoices.xls'">
                                Export Excel
                            </export-excel>
                        </button>
                    </div>

                    <el-table
                        border size="mini" 
                        :data="GET_INVOICES.results" 
                        @selection-change="handleSelectedCustomerInvoice" 
                        @sort-change="OrderInvoiceData" 
                        style="width: 100%"
                        v-if="GET_INVOICES">
                        <el-table-column type="selection" width="55"></el-table-column>

                        <el-table-column prop="code" label="Number" sortable="custom"></el-table-column>

                        <el-table-column prop="invoice_date" label="Invoice Date" align="center" sortable="custom"></el-table-column>

                        <el-table-column prop="due_date" label="Due Date" align="center" sortable="custom"></el-table-column>

                        <el-table-column prop="status" label="Status" align="center">
                            <template slot-scope="scope">
                                <el-tag 
                                    effect="plain" type="info" 
                                    v-if="scope.row.status == 'PENDING' ">
                                    {{ scope.row.status }}
                                </el-tag>
                                <el-tag 
                                    effect="plain" type="success" 
                                    v-else-if="scope.row.status == 'COMPLETE' ">
                                    {{ scope.row.status }}
                                </el-tag>
                                <el-tag 
                                    effect="plain" 
                                    v-else-if="scope.row.status == 'OPEN' ">
                                    {{ scope.row.status }}
                                </el-tag>
                                <el-tag 
                                    effect="plain" type="danger" 
                                    v-else>
                                    {{ scope.row.status }}
                                </el-tag>
                            </template>
                        </el-table-column>

                        <el-table-column prop="amount" label="Amount" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.amount !== null">
                                    {{ scope.row.amount | number('0,0') }}
                                </span>
                                <span v-else>0</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="balance" label="Balance" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.balance !== null">
                                    {{ scope.row.balance | number('0,0') }}
                                </span>
                                <span v-else>0</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="Action" align="center" width="150">
                            <template slot-scope="scope">
                                
                                <button 
                                    class="table-button" @click="$router.push({name: 'invoice-detail', params: { invoiceId: scope.row.id} })">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                    </svg>
                                </button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="flex justify-end mt-4" v-if="GET_INVOICES">
                        <el-pagination 
                            background layout="prev, pager, next" 
                            :total="GET_INVOICES.count" :page-size="50"
                            @current-change="currentInvoicePageChanged">
                        </el-pagination>
                    </div>
                </section>
            </el-tab-pane>
            <!-- End Invoice -->
            
            <!-- Account Statement -->
            <el-tab-pane label="Statement of Account" name="accountStatement">
                <section class="py-4">
                    <div class="flex justify-end space-x-4 items-center mb-4">
                        <button class="basic-button" v-if="selectedCustomerAccountStatement.length > 0" plain>
                            <export-excel 
                                :data="selectedCustomerAccountStatement" 
                                :fields="exportAccountStatementFields" 
                                :name="customer.name + ' Statement of Accounts.xls'">
                                Export Excel
                            </export-excel>
                        </button>
                    </div>

                    <el-table 
                        border size="mini" 
                        :data="accountStatement.results.reverse()" 
                        style="width: 100%" 
                        header-cell-class-name="bg-gray-50 text-gray-500"
                        @selection-change="handleSelectedCustomerAccountStatement"
                        v-if="accountStatement">

                        <el-table-column type="selection" width="55"></el-table-column>

                        <el-table-column prop="posting_date" label="Date"></el-table-column>

                        <el-table-column prop="description" label="Detail"></el-table-column>

                        <el-table-column prop="amount" label="Dr" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.types == 'DEBIT' ">
                                    {{ scope.row.amount | number('0,0') }}
                                </span>
                                <span v-else>0</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="amount" label="Cr" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.types == 'CREDIT' ">
                                    {{ scope.row.amount | number('0,0') }}
                                </span>
                                <span v-else>0</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="amount" label="Balance" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.balance | number('0,0') }}
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="flex justify-end mt-4">
                        <el-pagination 
                            background layout="prev, pager, next" 
                            :total="accountStatement.count" :page-size="50"
                            @current-change="currentAccountStatementPageChanged">
                        </el-pagination>
                    </div>
                </section>
            </el-tab-pane>
            <!-- End Account Statement -->

        </el-tabs>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
    name: "CustomerDetail",

    props: {
        customerId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            customer: {},
            invoice: {},
            accountStatement: {},
            invoice_date_range: [],
            tabName: "general",
            selectedCustomerInvoices: [],
            selectedCustomerAccountStatement: []
        }
    },

    watch: {
        customer: function(newValue, oldValue) {
            this.fetchInvoices({
                filter: {
                    customer: newValue.id
                }
            })
        }
    },

    computed: {
        ...mapGetters(["GET_ACTIVE_BRANCH", "GET_TOKEN", "GET_ENTRIES", "GET_INVOICES"]),

        exportInvoiceFields() {
            return {
                "Invoice Number": "code",
                "Title": "title",
                "Customer": {
                    field: "customer",
                    callback: (value) => {
                        return value.name;
                    }
                },
                "Invoice Date": "invoice_date",
                "Due Date": "due_date",
                "Amount": "amount"
            }
        },

        exportAccountStatementFields() {
            return {
                "Date": "posting_date",
                "Description": "description",
                "Dr/Cr": "types",
                "Amount": "amount",
                "Balance": "balance"
            }
        }
    },

    methods: {
        ...mapActions(["fetchEntries", "fetchInvoices"]),

        handleTabChange(tab, event) {
            if(tab.name === "invoice") {
                this.fetchInvoices({
                    filter: {
                        customer: this.customerId
                    }
                })
            }

            if(tab.name === "accountStatement" && this.customer) {
                this.fetchCustomerAccountStatement()
            }
        },

        handleInvoiceDateChange() { 
            this.fetchInvoices({
                filter: {
                    invoice_min: this.invoice_date_range[0].toISOString().split('T')[0],
					invoice_max: this.invoice_date_range[1].toISOString().split('T')[0],
                    customer: this.customerId
                }
            })
        },

        handleSelectedCustomerInvoice(val) {
            this.selectedCustomerInvoices = val;
        },
        
        handleSelectedCustomerAccountStatement(val) {
            this.selectedCustomerAccountStatement = val;
        },

        fetchCustomerAccountStatement(page={}) {
            this.$http.get(process.env.VUE_APP_FINANCE_URL + "/account-statement/", {
                params: Object.assign({
                    "branch": this.GET_ACTIVE_BRANCH.id,
                    "customer": this.customerId
                }, page),
                headers: {
                    "Authorization": "Bearer " + this.GET_TOKEN.access
                }
            }).then(res => {
                this.accountStatement = res.data
            })
        },

        fetchCustomerDetail() {
            this.$http.get(process.env.VUE_APP_CUSTOMER_URL + this.customerId + "/", {
                headers: {
                    "Authorization": "Bearer " + this.GET_TOKEN.access
                }
            }).then(res => {
                this.customer = res.data
            })
        },

        OrderInvoiceData(val) {
            if(val.order == "descending") {
                this.sortField = "-" + val.prop
            } else {
                this.sortField = val.prop
            }

            this.fetchInvoices({
                ordering: this.sortField,
                filter: {
                    customer: this.customerId
                }
            })
        },

        currentInvoicePageChanged(value) {
            this.fetchInvoices({
                page: value,
                filter: {
                    customer: this.customerId
                }
            })
        },

        currentAccountStatementPageChanged(value) {
            this.fetchCustomerTransactions({
                page: value
            })
        },
    },

    mounted() {
        this.fetchCustomerDetail();
    }
}
</script>