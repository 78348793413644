<template>
    <div>
        <div class="flex justify-between space-x-4 items-center mb-2">
            <div class="w-full md:w-1/2 xl:w-1/4">
                <el-input v-model="search" size="mini" placeholder="Type to search" @input="searchReceipt" />
            </div>

            <button class="basic-button" v-if="selectedReceipts.length > 0">
                <export-excel :data="selectedReceipts" :fields="exportFields" name="Receipts.xls">
                    Export Excel
                </export-excel>
            </button>
        </div>

        <el-table 
            border size="mini" 
            :data="GET_RECEIPTS.results" 
            @selection-change="handleSelectedReceipts" 
            @sort-change="OrderReceiptData" 
            style="width: 100%"
            v-if="GET_RECEIPTS">
            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column prop="code" label="Number" sortable="custom"></el-table-column>

            <el-table-column prop="customer" label="Customer" sortable="custom"></el-table-column>

            <el-table-column prop="amount" label="Amount" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.amount !== null">
                        {{ scope.row.amount | number('0,0') }}
                    </span>
                    <span v-else>0</span>
                </template>
            </el-table-column>

            <el-table-column prop="created" label="Date" align="center" sortable="custom"></el-table-column>

            <el-table-column label="Action" align="center" width="150">
				<template slot-scope="scope">
                    <button 
                        class="table-button" @click="$router.push({name: 'invoice-detail', params: { invoiceId: scope.row.invoice} })">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                        </svg>
                    </button>

                    <button 
                        class="table-button" @click="updateCurrentReceipt(scope.row)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                    </button>
                </template>
            </el-table-column>

        </el-table>

        <div class="flex justify-end mt-4" v-if="GET_RECEIPTS">
            <el-pagination 
                background layout="prev, pager, next" 
                :total="GET_RECEIPTS.count" :page-size="50"
                @current-change="currentPageChanged">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"

export default {
    name: "ReceiptList",

    data() {
        return {
            search: "",
            sortField: "",
            selectedReceipts: [],
            organization: {},
        }
    },

    computed: {
        ...mapGetters(["GET_RECEIPTS", "GET_ACTIVE_BRANCH"]),

        exportFields() {
            return {
                "Receipt No": "code",
                "Customer": "customer",
                "Amount": "amount",
                "Date": "created"
            }
        }
    },

    methods: {
        ...mapMutations(["SET_ACTIVE_RECEIPT"]),

        ...mapActions(["fetchReceipts", "fetchOrganization"]),

        retrieveOrganizationDetail() {
            this.fetchOrganization(this.GET_ACTIVE_BRANCH.organization)
            .then(data => {
                this.organization = data;
            })
        },

        updateCurrentReceipt(receipt) {
            this.SET_ACTIVE_RECEIPT(receipt);
            this.$router.push({name: "receipt-detail", params: { receiptId: receipt.id } });
        },

        handleSelectedReceipts(val) {
            this.selectedReceipts = val
        },

        OrderReceiptData(val) {
            if(val.order == "descending") {
                this.sortField = "-" + val.prop
            } else {
                this.sortField = val.prop
            }

            this.fetchReceipts({
                ordering: this.sortField
            })
        },

        searchReceipt() {
            this.fetchReceipts({
                search: this.search
            })
        },

        currentPageChanged(value) {
            this.fetchReceipts({
                page: value
            })
        }
    },

    mounted() {
        this.fetchReceipts();
        this.retrieveOrganizationDetail();
    }

}
</script>