import Vue from "vue";

const converter = require("number-to-words");

Vue.filter("splitUnderScore", value => {
    let text = "";
    if(value !== undefined || value !== null) {
        value?.split("_").forEach(s => text = text + s + ' ');
    }
    return text
})

Vue.filter("toWords", function (value) {
    if (!value) return "";
    return converter.toWords(value);
})