<template>
	<div>
		<MemberCreate v-if="addPermission" />

		<MemberUpdate :showMemberModal="updateModal" :updateObject="updateObject" @onSuccess="updateModal = false" />

		<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mb-2">
            <div>
                <el-input v-model="search" size="mini" placeholder="Type to search" @input="searchMember" />
            </div>
        </div>

		<el-table 
			border size="mini" 
			:data="GET_MEMBERS.results" 
			style="width: 100%">

			<el-table-column type="selection" width="55"></el-table-column>
			
			<el-table-column prop="first_name" label="First Name"></el-table-column>

            <el-table-column prop="last_name" label="Last Name"></el-table-column>

            <el-table-column prop="gender" label="Gender" align="center" width="100"></el-table-column>

            <el-table-column prop="email" label="Email"></el-table-column>

            <el-table-column prop="phone" label="Phone" align="center"></el-table-column>

			<el-table-column prop="is_active" label="Status" align="center" width="100">
				<template slot-scope="scope">
					<el-tag type="success" size="mini" v-if="scope.row.is_active">activated</el-tag>

					<el-tag type="info" size="mini" v-else>suspended</el-tag>
				</template>
			</el-table-column>

			<el-table-column label="Action" align="center" width="150">
				<template slot-scope="scope" v-if="scope.row.email !== GET_USER.email">

					<div v-if="scope.row.is_active">
						<button 
							class="table-button" @click="$router.push({name: 'member-detail', params: { userId: scope.row.email} })">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
							</svg>
						</button>

						<button class="table-button" @click="updateObject = scope.row; updateModal = true" v-if="changePermission">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
							</svg>
						</button>

						

						<el-popconfirm
							title="Are you sure to deactivate this?"
							cancel-button-text="No, Thanks"
							confirm-button-text="OK"
							@confirm="toggleAccount(scope.row)"
							v-if="deletePermission">

							<button class="table-button" slot="reference">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
								</svg>
							</button>
						</el-popconfirm>
					</div>

					<button class="table-button" @click="toggleAccount(scope.row)" v-else>
						<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
						</svg>
					</button>
					
				</template>
			</el-table-column>
		</el-table>
		
		<div class="flex justify-end mt-4">
            <el-pagination 
                background layout="prev, pager, next" 
                :total="GET_MEMBERS.count" :page-size="50"
                @current-change="currentPageChanged">
            </el-pagination>
        </div>

	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MemberCreate from "@/components/members/MemberCreate.vue"
import MemberUpdate from "@/components/members/MemberUpdate.vue";

export default {
    name: "MemberList",

	components: {
		MemberCreate,
		MemberUpdate
	},

    data() {
    	return {
			updateObject: {},
			updateModal: false,
			search: ""
    	}
    },

    computed: {
    	...mapGetters(["GET_MEMBERS", "GET_USER"]),

		addPermission() {
            return this.GET_USER.permissions.some(item => item === "management.add_customuser")
        },

        changePermission() {
            return this.GET_USER.permissions.some(item => item === "management.change_customuser")
        },

        deletePermission() {
            return this.GET_USER.permissions.some(item => item === "management.delete_customuser")
        }
    },

	methods: {
		...mapActions(["handleToggleAccount", "fetchMembers"]), 

		toggleAccount(item) {
			this.handleToggleAccount(item).then(data => {
				this.fetchMembers();

				this.$notify.success({ 
					title: "Operation Successful", 
					message: "User deactivated" 
				});
			})
		},

		OrderMemberData(val) {
            if(val.order == "descending") {
                this.sortField = "-" + val.prop
            } else {
                this.sortField = val.prop
            }

            this.fetchMembers({
                ordering: this.sortField
            })
        },

        searchMember() {
            this.fetchMembers({
                search: this.search
            })
        },

        currentPageChanged(value) {
            this.fetchMembers({
                page: value
            })
        }
	},

	mounted() {
		this.fetchMembers()
	}
}
</script>