import axios from "axios"

export default {
    state: {
        acccountStatement: {},
        activeReceipt: {},
        receipts: {},
        entries: {},
        purchases: {},
        sales: {}
    },

    getters: {
        GET_ACCOUNT_STATEMENT(state) {
            return state.acccountStatement
        },

        GET_ACTIVE_RECEIPT(state) {
            return state.activeReceipt
        },

        GET_RECEIPTS(state) {
            return state.receipts
        },

        GET_ENTRIES(state) {
            return state.entries
        },

        GET_ENTRY_CATEGORIES() {
            return ["EXPENSE", "PURCHASE", "SALE", "OTHER_INCOME"]
        },

        GET_PURCHASES(state) {
            return state.purchases
        },

        GET_SALES(state) {
            return state.sales
        }
    },

    mutations: {
        SET_ACCOUNT_STATEMENT(state, payload) {
            state.acccountStatement = payload
        },

        SET_ACTIVE_RECEIPT(state, payload) {
            state.activeReceipt = payload
        },

        SET_RECEIPTS(state, payload) {
            state.receipts = payload
        },

        SET_ENTRIES(state, payload) {
            state.entries = payload
        },

        SET_PURCHASES(state, payload) {
            state.purchases = payload
        },

        SET_SALES(state, payload) {
            state.sales = payload
        }
    },

    actions: {
        fetchAccountStatement({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_FINANCE_URL + "/account-statement/", {
                params: Object.assign({
                    "ordering": payload.ordering, 
                    "page": payload.page, 
                    "search": payload.search,
                    "branch": getters.GET_ACTIVE_BRANCH.id 
                }, payload.filter),
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_ACCOUNT_STATEMENT", res.data);
            })
            .catch((err) => {
                // pass
            })
        },

        fetchReceipts({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_FINANCE_URL + "/receipts/", {
                params: Object.assign({
                    "ordering": payload.ordering, 
                    "page": payload.page, 
                    "search": payload.search,
                    "branch": getters.GET_ACTIVE_BRANCH.id 
                }, payload.filter),
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_RECEIPTS", res.data);
            })
            .catch((err) => {
                // pass
            })
        },

        fetchEntries({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_FINANCE_URL + "/entry/", {
                params: Object.assign({
                    "ordering": payload.ordering, 
                    "page": payload.page, 
                    "search": payload.search,
                    "branch": getters.GET_ACTIVE_BRANCH.id 
                }, payload.filter),
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_ENTRIES", res.data);
            })
            .catch((err) => {
                // pass
            })
        },

        handleCreatePayment({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_FINANCE_URL + "/payments/", payload, 
                {
                    params: {
                        "branch": getters.GET_ACTIVE_BRANCH.id
                    },
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleEntryCreate({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_FINANCE_URL + "/entry/", payload, 
                {
                    params: {
                        "branch": getters.GET_ACTIVE_BRANCH.id
                    },
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleEntryUpdate({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_FINANCE_URL + "/entry/" + payload.id + "/", payload, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleEntryDelete({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_FINANCE_URL + "/entry/" + payload + "/", {"trashed": true}, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        fetchPurchases({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_FINANCE_URL + "/purchases/", {
                params: Object.assign({
                    "ordering": payload.ordering, 
                    "page": payload.page, 
                    "search": payload.search,
                    "branch": getters.GET_ACTIVE_BRANCH.id 
                }, payload.filter),
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_PURCHASES", res.data);
            })
            .catch((err) => {
                // pass
            })
        },

        fetchSales({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_FINANCE_URL + "/sales/", {
                params: Object.assign({
                    "ordering": payload.ordering, 
                    "page": payload.page, 
                    "search": payload.search,
                    "branch": getters.GET_ACTIVE_BRANCH.id 
                }, payload.filter),
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_SALES", res.data);
            })
            .catch((err) => {
                // pass
            })
        },
    }
}