<template>
    <div>
        <DeliveryCreate v-if="addPermission" />

        <!-- <DeliveryUpdate :showInvoiceDialog="updateModal" :updateObject="updateObject" @onSuccess="updateModal = false" /> -->

        <div class="flex justify-between space-x-4 items-center mb-2">
            <div class="w-full md:w-1/2 xl:w-1/4">
                <el-input 
                    v-model="search" 
                    size="mini" 
                    placeholder="Type to search" 
                    @input="searchDelivery" />
            </div>

            <button class="basic-button" v-if="selectedDelivery.length > 0">
                <export-excel 
                    :data="selectedDelivery" 
                    :fields="exportFields" 
                    name="Delivery.xls">
                    Export Excel
                </export-excel>
            </button>
        </div>

        <el-table 
            border size="mini" 
            :data="GET_DELIVERIES.results" 
            @selection-change="handleSelectedDelivery" 
            @sort-change="orderDeliveryData" 
            style="width: 100%"
            v-if="GET_DELIVERIES">
            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column prop="code" label="Number" sortable="custom" />

            <el-table-column prop="ms" label="Customer" sortable="custom" />

            <el-table-column prop="delivery_date" label="Date" align="center" sortable="custom" />

            <el-table-column prop="deliverer" label="Delivered By" align="center" sortable="custom" />

            <el-table-column prop="receiver" label="Received By" align="center" sortable="custom" />

            <el-table-column prop="invoice" label="Invoice" align="center">
                <template slot-scope="scope">
                    <router-link 
                        :to="{
                            name: 'invoice-detail', 
                            params: { invoiceId: scope.row.invoice} 
                        }" 
                        class="text-blue-600">
                        INVOICE
                    </router-link>
                </template>
            </el-table-column>

            <el-table-column label="Action" align="center" width="150">
				<template slot-scope="scope">
                    
                    <button 
                        class="table-button" 
                        @click="$router.push({name: 'delivery-detail', params: { deliveryId: scope.row.id} })">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                    </button>

                    <!-- <button 
                        class="table-button" 
                        @click="updateObject = scope.row; updateModal = true" 
                        v-if="changePermission">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                    </button> -->

                    <el-popconfirm
                        title="Are you sure to delete this?"
                        cancel-button-text="No, Thanks"
                        confirm-button-text="OK"
                        @confirm="deleteDelivery(scope.row)"
                        v-if="deletePermission">

                        <button class="table-button" slot="reference">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </el-popconfirm>

				</template>
			</el-table-column>
        </el-table>

        <div class="flex justify-end mt-4" v-if="GET_DELIVERIES">
            <el-pagination 
                background layout="prev, pager, next" 
                :total="GET_DELIVERIES.count" :page-size="50"
                @current-change="currentPageChanged">
            </el-pagination>
        </div>

    </div>
</template>

<script>
import DeliveryCreate from "@/components/delivery/DeliveryCreate"
import { mapActions, mapGetters } from "vuex"
import { errorRecursion } from "@/helpers/index.js"

export default {
    name: "DeliveryList",
    
    components: {
        DeliveryCreate
    },

    data() {
        return {
            updateObject: {},
			updateModal: false,
            search: "",
            sortField: "",
            selectedDelivery: []
        }
    },

    computed: {
        ...mapGetters(["GET_DELIVERIES", "GET_USER"]),

        addPermission() {
            return this.GET_USER.permissions.some(item => item === "finance.add_delivery")
        },

        changePermission() {
            return this.GET_USER.permissions.some(item => item === "finance.change_delivery")
        },

        deletePermission() {
            return this.GET_USER.permissions.some(item => item === "finance.delete_delivery")
        },

        exportFields() {
            return {
                "Delivery Number": "code",
                "Customer": "ms",
                "Deliverer": "deliverer",
                "Receiver": "receiver",
                "Delivery Date": "delivery_date",
            }
        }
    },

    methods: {
        ...mapActions(["handleDeliveryDelete", "fetchDeliveries"]),

        deleteDelivery(item) {
            this.handleDeliveryDelete(item).then(data => {
				this.fetchDeliveries();

				this.$notify.success({ 
					title: "Operation Successful", 
					message: "Delivery deleted" 
				});
			}).catch(error => {
                let errorList = []
                let message = ""
                errorRecursion(error.data, errorList);
                errorList.forEach((item) => {
                    message += "<li>" + item + "</li>"
                })

                this.$notify.error({ 
                    title: "Error",
                    dangerouslyUseHTMLString: true, 
                    message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                });
            })
        },

        handleSelectedDelivery(val) {
            this.selectedDelivery = val
        },

        orderDeliveryData(val) {
            if(val.order == "descending") {
                this.sortField = "-" + val.prop
            } else {
                this.sortField = val.prop
            }

            this.fetchDeliveries({
                ordering: this.sortField
            })
        },

        searchDelivery() {
            this.fetchDeliveries({
                search: this.search
            })
        },

        currentPageChanged(value) {
            this.fetchDeliveries({
                page: value
            })
        }
    },

    mounted() {
        this.fetchDeliveries()
    }
}
</script>