<template>
    <div>
        <PumpUpdate 
            :showPumpModal="updateModal" 
            :updateObject="updateObject" 
            @onSuccess="updateModal = false" />

        <el-table 
            border size="mini" 
            :data="GET_PUMPS.results"
            style="width: 50%">
            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column prop="name" label="Name"></el-table-column>

            <el-table-column prop="product" label="Product">
                <template slot-scope="scope">
                    <span>{{ scope.row.product.name }}</span>
                </template>
            </el-table-column>

            <el-table-column label="Action" align="center" width="150">
				<template slot-scope="scope">

                    <button class="table-button" @click="updateObject = scope.row; updateModal = true" v-if="changePermission">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                    </button>

                    <el-popconfirm
                        title="Are you sure to delete this?"
                        cancel-button-text="No, Thanks"
                        confirm-button-text="OK"
                        @confirm="deletePump(scope.row)"
                        v-if="deletePermission">

                        <button class="table-button" slot="reference">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </el-popconfirm>

				</template>
			</el-table-column>
        </el-table>
    </div>  
</template>

<script>
import PumpUpdate from "@/components/pumps/PumpUpdate.vue"
import { mapActions, mapGetters } from "vuex"
import { errorRecursion } from "@/helpers/index.js"

export default {
    name: "PumpList",

    components: {
        PumpUpdate
    },

    data() {
        return {
            search: "",
            updateObject: {},
			updateModal: false,
        }
    },

    computed: {
        ...mapGetters(["GET_PUMPS", "GET_USER"]),

        addPermission() {
            return this.GET_USER.permissions.some(item => item === "inventory.add_pump")
        },

        changePermission() {
            return this.GET_USER.permissions.some(item => item === "inventory.change_pump")
        },

        deletePermission() {
            return this.GET_USER.permissions.some(item => item === "inventory.delete_pump")
        },
    },

    methods: {
        ...mapActions(["handlePumpDelete", "fetchPumps"]),

        deletePump(item) {
            this.handlePumpDelete(item).then(data => {
				this.fetchPumps();

				this.$notify.success({ 
					title: "Operation Successful", 
					message: "Pump deleted" 
				});
			}).catch(error => {
                let errorList = []
                let message = ""
                errorRecursion(error.data, errorList);
                errorList.forEach((item) => {
                    message += "<li>" + item + "</li>"
                })

                this.$notify.error({ 
                    title: "Error",
                    dangerouslyUseHTMLString: true, 
                    message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                });
            })
        },

        currentPageChanged(value) {
            this.fetchPumps({"page": value})
        }
    },

    mounted() {
        this.fetchPumps()
    }
}
</script>