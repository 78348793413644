<template>
	<div>
		<div class="flex justify-between space-x-4 items-center my-5">
            <p>Sales Summary</p>

            <div class="flex space-x-2">
                <el-button v-if="rawData.length > 0" size="mini" plain>
                    <export-excel :data="rawData" :fields="exportFields" name="Sales Report.xls">
                        Export Excel
                    </export-excel>
                </el-button>

				<el-button	size="mini" v-print="printOject" v-if="sales.length > 0" plain>
					Export PDF
				</el-button>

                <el-date-picker
                    v-model="salesDate"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="Start Date"
                    end-placeholder="End date"
                    size="mini">
                </el-date-picker>
            </div>
        </div>

        <div class="overflow-auto">   
			<table class="w-full bg-white border" id="salesReport">
				<thead class="border-b bg-gray-50 text-gray-600 text-sm">
					<tr class="divide-x divide-gray-200">
						<th rowspan="2" class="table-head">Date</th>
						<th colspan="2" class="table-head">PMS</th>
						<th colspan="2" class="table-head">GO</th>
						<th colspan="2" class="table-head">IK</th>
						<th colspan="2" class="table-head">Gas</th>
						<th colspan="2" class="table-head">Lubricants</th>
					</tr>
					<tr class="divide-x divide-gray-200">
						<th class="table-head">Qty</th>
						<th class="table-head">Total</th>

						<th class="table-head">Qty</th>
						<th class="table-head">Total</th>

						<th class="table-head">Qty</th>
						<th class="table-head">Total</th>

						<th class="table-head">Qty</th>
						<th class="table-head">Total</th>

						<th class="table-head">Qty</th>
						<th class="table-head">Total</th>
					</tr>
				</thead>
				<tbody class="text-xs 2xl:text-sm divide-y divide-gray-200">
					<tr class="divide-x divide-gray-200 text-center" v-for="item in sales" :key="item.date">
						<td class="px-4 py-2 truncate">{{ item.date }}</td>

						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'PMS')">
								{{ findObject(item.data, "PMS").quantity| number('0,0') }}
							</span>
							<span v-else>0</span>
							
						</td>
						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'PMS')">
								{{ findObject(item.data, "PMS").total| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>

						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'GO')">
								{{ findObject(item.data, "GO").quantity| number('0,0') }}
							</span>
							<span v-else>0</span>
							
						</td>
						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'GO')">
								{{ findObject(item.data, "GO").total| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>

						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'IK')">
								{{ findObject(item.data, "IK").quantity| number('0,0') }}
							</span>
							<span v-else>0</span>
							
						</td>
						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'IK')">
								{{ findObject(item.data, "IK").total| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>

						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'Gas')">
								{{ findObject(item.data, "Gas").quantity| number('0,0') }}
							</span>
							<span v-else>0</span>
							
						</td>
						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'Gas')">
								{{ findObject(item.data, "Gas").total| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>

						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'Lubricants')">
								{{ findObject(item.data, "Lubricants").quantity| number('0,0') }}
							</span>
							<span v-else>0</span>
							
						</td>
						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'Lubricants')">
								{{ findObject(item.data, "Lubricants").total| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>
					</tr>
				</tbody>

			</table>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex"
import { DateTime } from "luxon"

export default {
	name: "SaleReport",

	data() {
		return {
			salesDate: null,
			rawData: [],
			sales: [],
			printOject: {
                id: "salesReport",
                popTitle: "Sales Report"
            }
		}
	},

	watch: {
        salesDate: function(val) {
            this.fetchSaleReport()
        },
    },

	computed: {
		...mapGetters(["GET_ACTIVE_BRANCH", "GET_TOKEN"]),
		
        exportFields() {
            return {
                "Date": "date",
                "Product": "commodity",
                "Quantity": "quantity",
                "Total": "total"
            }
        }
    },

    methods: {
   		structureSalesReport(value) {
			value.forEach((element) => {
            	if(this.sales.find(item => item.date == element.date)) {
            		// pass
            	} else {
            		this.sales.push({
            			"date": element.date,
            			"data": value.filter(obj => obj.date == element.date)
            		})
            	}
            })

            this.sales.sort();
		},

		findObject(data, name) {
			return data.find(element => element.commodity == name)
		},

        fetchSaleReport() {
            this.$http.get(process.env.VUE_APP_REPORT_URL + "sale/", {
                params: Object.assign({
					"branch": this.GET_ACTIVE_BRANCH.id
					}, this.salesDate !== null ? {
	                "min_date": DateTime.fromFormat(this.salesDate[0].toDateString(), "EEE MMM dd yyyy").toISODate(),
					"max_date": DateTime.fromFormat(this.salesDate[1].toDateString(), "EEE MMM dd yyyy").toISODate()
	            } : {}),
                headers: { 
					"Authorization": "Bearer " + this.GET_TOKEN.access
				}
            })
            .then(res => { 
            	this.rawData = res.data;
            	this.sales = [];
                this.structureSalesReport(res.data);
            })
            .catch(err => {
                this.$notify({title: "Failure", type: "error", text: "" })
            })
        },

    },

	mounted() {
		this.fetchSaleReport()
	}
}
</script>