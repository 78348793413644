<template>
    <div class="relative flex w-full">
        <input type="file" @change="fileChange" :id="label" class="hidden" accept="application/pdf, image/*">

        <label :for="label" class="flex flex-col justify-center items-center space-y-1.5 border border-dashed p-2 w-full">
            <slot name="icon">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                </svg>
            </slot>

            <p class="text-xs text-gray-400">
                <span>drag & drop file here or </span>
                <span class="underline text-blue-500 cursor-pointer">click here</span>
            </p>

            <slot name="placeholder"></slot>
        </label>
    </div>
</template>

<script>
export default {
    name: "FileInput",

    props: {
        files: {
            default: null
        },

        label: {
            type: String,
            required: true
        },

        many: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        fileChange(context) {
            if(this.many) {
                this.$emit("upload-many", context.target.files)
            } else {
                this.$emit("upload-single", context.target.files[0])
            }
        }
    }
}
</script>