<template>
    <el-dialog 
        title="Edit Pump" 
        :visible.sync="showPumpModal" 
        width="30%">

        <el-form ref="form" :model="pump">

            <el-row :gutter="10">
                <el-col :sm="24" :md="12">
                    <el-form-item label="Name" prop="name" size="mini" required>
                        <el-input type="text" v-model="pump.name" size="small"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="24" :md="12">
                    <el-form-item label="Product" prop="product" size="mini" required>
                        <el-select v-model="pump.product" placeholder="select product" size="small" class="w-full">
                            <el-option  v-for="item in GET_PRODUCTS.results" 
                                        :label="item.name" 
                                        :value="item.id" 
                                        :key="item.id">
                                <span style="float: left">{{ item.name }}</span>
                                <span style="float: right; font-size: 13px" v-if="item.weight > 0">{{ item.weight }}</span> 
                                <span style="float: right; font-size: 13px" v-else>{{ item.volume }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item>
                <el-button 
                    @click="updatePump" :loading="loading"
                    class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                    Save Changes
                </el-button>
            </el-form-item>

        </el-form>
    </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { errorRecursion } from "@/helpers/index.js"

export default {
    name: "PumpUpdate",

    props: {
        updateObject: {
            type: Object,
            required: true
        },

        showPumpModal: {
            type: Boolean,
            default: false
        }
    },

    data() {
    	return {
    		pump: {},
            loading: false
    	}
    },

    watch: {
        updateObject: function(val) {
            this.pump = val;
        },

        pump: function(val) {
            val.product = val.product.id;
        }
    },

    computed: {
    	...mapGetters(["GET_PRODUCTS"])
    },

    methods: {
        ...mapActions(["handlePumpUpdate", "fetchPumps", "fetchProducts"]),

        updatePump() {
            this.loading = true;
            this.handlePumpUpdate(this.pump).then(data => {
                this.$emit("onSuccess");
                this.loading = false;

                this.$notify.success({ 
                    title: "Operation Successful", 
                    message: `Pump ${data.name} updated` 
                });

                this.fetchPumps();
            }).catch(error => {
                this.$emit("onSuccess");
                this.loading = false;

                let errorList = []
                let message = ""
                errorRecursion(error.data, errorList);
                errorList.forEach((item) => {
                    message += "<li>" + item + "</li>"
                })

                this.$notify.error({ 
                    title: "Error",
                    dangerouslyUseHTMLString: true, 
                    message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                });
            })
    	}
    },

    mounted() {
        this.fetchProducts({
            filter: {
                dipping: true
            }
        })
    }
}
</script>