import axios from 'axios'

export default {
	state: {
		suppliers: {}
	},

	getters: {
		GET_SUPPLIERS(state) {
			return state.suppliers
		}
	},

	mutations: {
		SET_SUPPLIERS(state, payload) {
			state.suppliers = payload;
		}
	},

    actions: {
		fetchSuppliers({ commit, getters }, payload={}) {
			axios.get(process.env.VUE_APP_SUPPLIER_URL, {
                params: Object.assign({
                    "ordering": payload.ordering, 
					"page": payload.page, 
					"search": payload.search,
					"branch": getters.GET_ACTIVE_BRANCH.id 
                }, payload.filter),
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_SUPPLIERS", res.data);
            })
            .catch((err) => {
                // pass
            })
		},

		handleSupplierCreate({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_SUPPLIER_URL, payload, 
				{
					params: {
                        "branch": getters.GET_ACTIVE_BRANCH.id
                    },
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleSupplierUpdate({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_SUPPLIER_URL + payload.id + "/", payload, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleSupplierDelete({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_SUPPLIER_URL + payload.id + "/", {"trashed": true}, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        }
	}
}