<template>
    <div>
        <CustomerCreate v-if="addPermission" />

        <CustomerUpdate :showCustomerModal="updateModal" :updateObject="updateObject" @onSuccess="updateModal = false" />

        <div class="flex justify-between space-x-4 items-center mb-2">
            <div class="w-full md:w-1/2 xl:w-1/4">
                <el-input v-model="search" size="mini" placeholder="Type to search" @input="searchCustomer" />
            </div>

            <button class="basic-button" v-if="selectedCustomers.length > 0" plain>
                <export-excel :data="selectedCustomers" :fields="exportFields" name="Customers.xls">
                    Export Excel
                </export-excel>
            </button>

            <BulkPayment />
        </div>

        <el-table 
            border size="mini" 
            :data="GET_CUSTOMERS.results" 
            @selection-change="handleSelectedCustomers" 
            @sort-change="OrderCustomerData" 
            style="width: 100%">
            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column prop="name" label="Name" sortable="custom"></el-table-column>

            <el-table-column prop="phone" label="Phone" align="center" sortable="custom"></el-table-column>

            <el-table-column prop="email" label="Email" sortable="custom"></el-table-column>

            <el-table-column prop="address" label="Address" sortable="custom"></el-table-column>

            <el-table-column prop="tin" label="TIN #" align="center" sortable="custom"></el-table-column>

            <el-table-column label="Action" align="center" width="150">
				<template slot-scope="scope">
                    
                    <button 
                        class="table-button" @click="$router.push({name: 'customer-detail', params: { customerId: scope.row.id} })">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                    </button>

                    <button class="table-button" @click="updateObject = scope.row; updateModal = true" v-if="changePermission">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                    </button>

                    <el-popconfirm
                        title="Are you sure to delete this?"
                        cancel-button-text="No, Thanks"
                        confirm-button-text="OK"
                        @confirm="deleteCustomer(scope.row)"
                        v-if="deletePermission">

                        <button class="table-button" slot="reference">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </el-popconfirm>

				</template>
			</el-table-column>
        </el-table>

        <div class="flex justify-end mt-4">
            <el-pagination 
                background layout="prev, pager, next" 
                :total="GET_CUSTOMERS.count" :page-size="50"
                @current-change="currentPageChanged">
            </el-pagination>
        </div>

    </div>
</template>

<script>
import BulkPayment from "@/components/common/BulkPayment"
import CustomerCreate from "@/components/customers/CustomerCreate"
import CustomerUpdate from "@/components/customers/CustomerUpdate"
import { mapActions, mapGetters } from "vuex"
import { errorRecursion } from "@/helpers/index.js"

export default {
    name: "CustomerList",

    components: {
        BulkPayment,
        CustomerCreate,
        CustomerUpdate
    },

    data() {
        return {
            updateObject: {},
			updateModal: false,
            search: "",
            selectedCustomers: [],
            sortField: "",
        }
    },

    computed: {
        ...mapGetters(["GET_CUSTOMERS", "GET_USER"]),

        addPermission() {
            return this.GET_USER.permissions.some(item => item === "customers.add_customer")
        },

        changePermission() {
            return this.GET_USER.permissions.some(item => item === "customers.change_customer")
        },

        deletePermission() {
            return this.GET_USER.permissions.some(item => item === "customers.delete_customer")
        },

        exportFields() {
            return {
                "Name": "name",
                "Region": "region",
                "District": "district",
                "Address": "address",
                "Email": "email",
                "Phone": "phone",
                "Landline": "landline",
                "Website": "website",
                "TIN": "tin"
            }
        }
    },
  
    methods: {
        ...mapActions(["handleCustomerDelete", "fetchCustomers"]),

        deleteCustomer(item) {
            this.handleCustomerDelete(item).then(data => {
				this.fetchCustomers();

				this.$notify.success({ 
					title: "Operation Successful", 
					message: "Customer deleted" 
				});
			}).catch(error => {
                let errorList = []
                let message = ""
                errorRecursion(error.data, errorList);
                errorList.forEach((item) => {
                    message += "<li>" + item + "</li>"
                })

                this.$notify.error({ 
                    title: "Error",
                    dangerouslyUseHTMLString: true, 
                    message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                });
            })
        },

        handleSelectedCustomers(val) {
            this.selectedCustomers = val;
        },

        OrderCustomerData(val) {
            if(val.order == "descending") {
                this.sortField = "-" + val.prop
            } else {
                this.sortField = val.prop
            }

            this.fetchCustomers({
                ordering: this.sortField
            })
        },

        searchCustomer() {
            this.fetchCustomers({
                search: this.search
            })
        },

        currentPageChanged(value) {
            this.fetchCustomers({
                page: value
            })
        }
    },

    mounted() {
        this.fetchCustomers()
    }
}
</script>