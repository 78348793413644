<template>
    <div>
        <div class="mb-4">
            <button class="table-button" v-print="'#paymentReceipt'">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2"></path>
                    <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4"></path>
                    <rect x="7" y="13" width="10" height="8" rx="2"></rect>
                </svg>
            </button>
        </div>

        <div id="paymentReceipt">
            <section class="flex justify-center items-center space-x-10 mb-8">
                <img src="@/assets/aula-logo.png" class="w-20 h-auto" alt="logo" />

                <div class="text-center font-medium tracking-wider space-y-1">
                    <h2 class="text-lg font-bold">{{ organization.name }}</h2>

                    <div class="flex justify-around space-x-4">
                        <p>
                            <span>Tel:</span>
                            <span class="ml-2">{{ organization.phone }}</span>
                        </p>
                        <p>
                            <span>Email:</span>
                            <span class="ml-2">{{ organization.email }}</span>
                        </p>
                    </div>
                    <p>P.O Box {{ organization.postal_code }} {{ GET_ACTIVE_BRANCH.district }} {{ GET_ACTIVE_BRANCH.region }}</p>
                    <p class="space-x-2">
                        <span>TIN: {{ organization.tin }}</span>
                        <span>|</span>
                        <span>VRN: {{ organization.vrn }}</span>
                    </p>
                </div>
            </section>

            <section class="tracking-wider space-y-2">
                <h2 class="text-center text-lg font-bold">
                    Receipt
                </h2>

                <div class="flex justify-between space-x-4">
                    <p>
                        <span>No:</span>
                        <span class="ml-2 font-bold">
                            {{ GET_ACTIVE_RECEIPT.code }}
                        </span>
                    </p>

                    <p>
                        <span>Date:</span>
                        <span class="ml-2 font-bold">{{ GET_ACTIVE_RECEIPT.created }}</span>
                    </p>
                </div>

                <p class="flex">
                    <span class="w-48">Received from M/s:</span>
                    <span class="pl-2 font-bold border-b-2 border-gray-200 flex-grow">
                        {{ GET_ACTIVE_RECEIPT.customer }}
                    </span>
                </p>

                <p class="flex">
                    <span class="w-32">The sum of:</span>
                    <span class="pl-2 font-bold border-b-2 border-gray-200 flex-grow capitalize">
                        {{ GET_ACTIVE_RECEIPT.amount | toWords }} only
                    </span>
                </p>

                <p class="flex">
                    <span class="w-72">Being Payment in respect of:</span>
                    <span class="pl-2 font-bold border-b-2 border-gray-200 flex-grow"></span>
                </p>

                <p class="text-right italic">With Thanks</p>

                <p class="flex">
                    <span>Tsh:</span>
                    <span class="ml-2 font-bold border-b-2 border-dotted border-gray-200 w-1/4">
                        {{ GET_ACTIVE_RECEIPT.amount | number('0,0') }}
                    </span>
                </p>

                <div class="flex justify-between items-center">
                    <p class="flex w-1/2">
                        <span>Cash/Cheque No</span>
                        <span class="ml-2 font-bold border-b-2 border-dotted border-gray-200 w-1/2">
                        </span>
                    </p>

                    <p class="flex flex-col space-y-1">
                        <span class="block font-bold border-b-2 border-dotted border-gray-200 w-full">
                        </span>
                        <span class="block">For Aula Investment Co.Ltd</span>
                    </p>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
    name: "ReceiptDetail",

    props: {
        receiptId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            search: "",
            sortField: "",
            showPrintDialog: false,
            printOject: {
                id: "paymentReceipt",
                preview: false,
                popTitle: "Payment Receipt"
            },
            selectedReceipts: [],
            organization: {},
        }
    },

    computed: {
        ...mapGetters(["GET_ACTIVE_RECEIPT", "GET_ACTIVE_BRANCH"]),
    },

    methods: {
        ...mapActions(["fetchOrganization"]),

        retrieveOrganizationDetail() {
            this.fetchOrganization(this.GET_ACTIVE_BRANCH.organization)
            .then(data => {
                this.organization = data
            })
        }
    },

    mounted() {
        this.retrieveOrganizationDetail();
    }

}
</script>