<template>
    <el-tabs v-model="reportTab" @tab-click="handleTabChange">

        <!-- <el-tab-pane label="Account Payables" name="payables">Account Payable</el-tab-pane> -->

        <el-tab-pane label="Account Receivables" name="receivables">
            <AccountReceivable />
        </el-tab-pane>

        <el-tab-pane label="Balance Sheet" name="balance_sheet">
            <BalanceSheet />
        </el-tab-pane>

        <el-tab-pane label="Income & Expenditure" name="income_expenditure">
            <IncomeExpenditure />
        </el-tab-pane>

        <el-tab-pane label="Trial Balance" name="trial_balance">
            <TrialBalance />
        </el-tab-pane>

        <el-tab-pane label="Expense" name="expense">
            <ExpenseReport />
        </el-tab-pane>

        <el-tab-pane label="Purchase" name="purchase">
            <PurchaseReport />
        </el-tab-pane>

        <el-tab-pane label="Sales" name="sales">
            <SalesReport />
        </el-tab-pane>

    </el-tabs>
</template>

<script>
import AccountReceivable from "@/components/reports/AccountReceivable"
import BalanceSheet from "@/components/reports/BalanceSheet"
import ExpenseReport from "@/components/reports/ExpenseReport"
import IncomeExpenditure from "@/components/reports/IncomeExpenditure"
import PurchaseReport from "@/components/reports/PurchaseReport"
import SalesReport from "@/components/reports/SalesReport"
import TrialBalance from "@/components/reports/TrialBalance"

export default {
    name: "Reports",

    components: {
        AccountReceivable,
        BalanceSheet,
        ExpenseReport,
        IncomeExpenditure,
        TrialBalance,
        PurchaseReport,
        SalesReport
    },

    data() {
        return {
            reportTab: "receivables"
        }
    },

    methods: {
        handleTabChange(tab, event) {
            // pass
        }
    }
}
</script>