<template>
    <el-dialog 
        title="Edit Reading" 
        :visible.sync="showReadingModal" 
        width="50%">

        <el-form ref="form" :model="reading">

            <el-row :gutter="10">
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                    <el-form-item label="Pump" prop="pump" size="mini" required>
                        <el-select v-model="reading.pump" placeholder="select pump" size="small" class="w-full">
                            <el-option  v-for="item in GET_PUMPS.results" 
                                        :label="item.name" 
                                        :value="item.id" 
                                        :key="item.id">   
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                    <el-form-item label="Opening Reading" prop="opening" size="mini" required>
                        <el-input-number :min="0" v-model="reading.opening" size="small" class="w-full"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                    <el-form-item label="Closing Reading" prop="closing" size="mini" required>
                        <el-input-number :min="0" v-model="reading.closing" size="small" class="w-full"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                    <el-form-item label="Fuel Return" size="mini">
                        <el-input-number :min="0" v-model="reading.fuel_return" size="small" class="w-full"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                    <el-form-item label="Date" prop="posting_date" size="mini" required>
                        <el-input type="date" v-model="reading.posting_date" size="small"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="10">
                <el-col :span="4">
                    <el-form-item>
                        <el-button 
                            @click="updateReading" :loading="loading"
                            class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                            Save Changes
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>

    </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { errorRecursion } from "@/helpers/index.js"

export default {
    name: "PumpReadingUpdate",

    props: {
        updateObject: {
            type: Object,
            required: true
        },

        showReadingModal: {
            type: Boolean,
            default: false
        }
    },

    data() {
    	return {
    		reading: {},
            loading: false
    	}
    },

    watch: {
        updateObject: function(val) {
            this.reading = val;
        },

        reading: function(val) {
            val.pump = val.pump.id
        }
    },

    computed: {
    	...mapGetters(["GET_PUMPS"])
    },

    methods: {
        ...mapActions(["handlePumpReadingUpdate", "fetchPumpReadings"]),

        updateReading() {
            this.loading = true;
            this.handlePumpReadingUpdate(this.reading).then(data => {
                this.$emit("onSuccess");
                this.loading = false;

                this.$notify.success({ 
                    title: "Operation Successful", 
                    message: `Reading updated` 
                });

                this.fetchPumpReadings();
            }).catch(error => {
                this.$emit("onSuccess");
                this.loading = false;

                let errorList = []
                let message = ""
                errorRecursion(error.data, errorList);
                errorList.forEach((item) => {
                    message += "<li>" + item + "</li>"
                })

                this.$notify.error({ 
                    title: "Error",
                    dangerouslyUseHTMLString: true, 
                    message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                });
            })
    	}
    }
}
</script>