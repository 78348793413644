import Vue from "vue"
import Vuex from "vuex"
import accounts from "./modules/accounts"
import auth from "./modules/auth"
import customers from "./modules/customers"
import delivery from "./modules/delivery"
import entry from "./modules/entry"
import invoice from "./modules/invoice"
import members from "./modules/members"
import products from "./modules/products"
import pumps from "./modules/pumps"
import suppliers from "./modules/suppliers"
import report from "./modules/report"

Vue.use(Vuex)

export default new Vuex.Store({
	state: {},

    getters: {},

	mutations: {},

	actions: {},
    
	modules: {
        accounts,
        auth,
        customers,
        delivery,
        entry,
        invoice,
        members,
        products,
        pumps,
        suppliers
        // report,
	}
})
