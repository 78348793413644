<template>
    <div>
        <div class="flex justify-end space-x-4">
            <button class="table-button" v-print="printOject">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2"></path>
                    <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4"></path>
                    <rect x="7" y="13" width="10" height="8" rx="2"></rect>
                </svg>
            </button>
        </div>

        <div class="text-base 2xl:text-lg py-8" id="delivery-receipt" v-if="delivery">
            <p class="text-2xl text-center font-bold mb-8">Delivery Note</p>

            <div class="flex items-center md:space-x-8 xl:space-x-16 mb-8">
                <div class="flex justify-center w-1/5 lg:w-2/12">
                    <img src="@/assets/aula-logo.png" class="w-20 lg:w-36 h-auto" alt="logo">
                </div>

                <div class="text-center w-3/5 xl:w-1/2">
                    <h2 class="text-xl font-semibold">
                        {{organization.name}}
                    </h2>

                    <div class="space-y-1">
                        <div class="space-x-4">
                            <span>Tel: {{organization.phone}}</span>
                            <span>Email: {{organization.email}}</span>
                        </div>

                        <div>
                            <span>P.O Box {{organization.postal_code}} {{GET_ACTIVE_BRANCH.region}}</span>
                        </div>

                        <div class="space-x-4">
                            <span>TIN: {{organization.tin}}</span>
                            <span>|</span>
                            <span>VRN: {{organization.vrn}}</span>
                        </div>

                        <h4 class="text-lg font-semibold uppercase">
                            {{GET_ACTIVE_BRANCH.name}}
                        </h4>
                    </div>
                </div>
            </div>

            <div>
                <div class="flex justify-between items-center mb-2">
                    <p>
                        <span>No:</span>
                        <span class="font-semibold pl-2">{{delivery.code}}</span>
                    </p>
                    <p>
                        <span>Date:</span>
                        <span class="font-semibold pl-2">{{delivery.delivery_date}}</span>
                    </p>
                </div>

                <p class="mb-8">
                    <span>To Ms:</span>
                    <span class="font-semibold pl-2">{{delivery.ms}}</span>
                </p>
                
                <h4 class="font-semibold">Please receive the following goods</h4>

                <table class="overflow-x-auto w-full bg-white mt-2 mb-8">
                    <thead class="border-b bg-blue-600 text-white text-sm">
                        <tr class="divide-x divide-blue-100">
                            <th class="px-3 py-2 text-center text-xs font-medium uppercase">#</th>
                            <th class="px-3 py-2 text-center text-xs font-medium uppercase">Quantity</th>
                            <th class="px-3 py-2 text-center text-xs font-medium uppercase">Item</th>
                            <th class="px-3 py-2 text-center text-xs font-medium uppercase">Description</th>
                        </tr>
                    </thead>

                    <tbody class="border-b border-gray-200 text-xs divide-y divide-blue-100">
                        <tr class="divide-x divide-gray-200" 
                            v-for="(particular, index) in invoice.particulars" 
                            :key="particular.id">

                            <td class="px-4 py-2 truncate text-center">{{index + 1}}</td>
                            <td class="px-4 py-2 truncate text-center">{{particular.quantity}}</td>
                            <td class="px-4 py-2 truncate text-center">
                                {{particular.item.name}} &nbsp;
                                {{particular.item.volume !== '0.00' ? particular.item.volume + 'ml' : ''}}
                                {{particular.item.weight !== '0.00' ? particular.item.weight + 'kg' : ''}}
                            </td>
                            <td class="px-4 py-2 truncate text-center">{{particular.description}}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="space-y-2">
                    <h4 class="font-semibold">
                        Receive the above mentioned goods in good order and condition
                    </h4>

                    <div class="flex justify-between items-center">
                        <p>
                            <span>Delivered By:</span>
                            <span class="font-semibold pl-2">{{delivery.deliverer}}</span>
                        </p>

                        <p class="flex w-1/4">
                            <span>Sign:</span>
                            <span class="font-semibold pl-2 border-b-2 border-dashed border-black w-3/4"></span>
                        </p>
                    </div>

                    <div class="flex justify-between items-center">
                        <p>
                            <span>Received By:</span>
                            <span class="font-semibold pl-2">{{delivery.receiver}}</span>
                        </p>

                        <p class="flex w-1/4">
                            <span>Sign:</span>
                            <span class="font-semibold pl-2 border-b-2 border-dashed border-black w-3/4"></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
    name: "DeliveryDetail",

    props: {
        deliveryId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            delivery: null,
            invoice: null,
            organization: {},
            printOject: {
                id: "delivery-receipt",
                preview: false,
                popTitle: "Delivery"
            }, 
        }
    },

    watch: {
        "delivery": function(newValue, oldValue) {
            if(newValue) {
                this.fetchInvoiceDetail()
                this.retrieveOrganizationDetail();
            }
        }
    },

    computed: {
        ...mapGetters(["GET_ACTIVE_BRANCH", "GET_TOKEN"]),
    },

    methods: {
        ...mapActions(["fetchOrganization"]),

        retrieveOrganizationDetail() {
            this.fetchOrganization(this.GET_ACTIVE_BRANCH.organization)
            .then(data => {
                this.organization = data
            })
        },

        fetchDeliveryDetail() {
            this.$http.get(process.env.VUE_APP_FINANCE_URL + "/delivery/" + this.deliveryId + "/", {
                headers: {
                    "Authorization": "Bearer " + this.GET_TOKEN.access
                }
            }).then(res => {
                this.delivery = res.data
            })
        },

        fetchInvoiceDetail() {
            this.$http.get(process.env.VUE_APP_FINANCE_URL + "/invoice/" + this.delivery.invoice + "/", {
                headers: {
                    "Authorization": "Bearer " + this.GET_TOKEN.access
                }
            }).then(res => {
                this.invoice = res.data
            })
        },
    },

    mounted() {
        this.fetchDeliveryDetail();
    }
}
</script>