<template>
    <div id="app" class="font-inter">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "App",
}
</script>

<style lang="css">
.default-button {
    @apply text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-700 rounded-md px-4 py-2 focus:outline-none;
}

.basic-button {
    @apply text-xs text-blue-500 hover:text-white bg-white hover:bg-blue-600 border border-blue-600 rounded-md px-4 py-2 focus:outline-none;
}

.table-button {
    @apply border border-gray-200 rounded-md p-1 text-gray-500 mx-0.5 focus:outline-none transform hover:scale-105 duration-75;
}

.custom-label {
    @apply text-xs rounded-md bg-gray-200 py-0.5 px-1.5 mb-1 mr-1;
}

.table-head {
    @apply px-3 py-2 text-center text-xs font-medium uppercase border;
}

.table-data {
    @apply px-4 py-2 truncate;
}

.el-table th {
    color: #4B5563 !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    background-color: #F9FAFB !important;
}

::-webkit-scrollbar {
    width: 8px;
    height: px;
}

::-webkit-scrollbar-track {
    border: 1px solid #dbdbdb;
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
}

::-webkit-scrollbar-thumb {
    background: #b4b4b4;
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
}

::-webkit-scrollbar-thumb:hover {
    background: #A3A3A3;
}
</style>

