<template>
    <div class="flex justify-end mb-5">
		<button class="default-button" @click="showEntryDialog = true">New Entry</button>

        <el-dialog 
            title="New Entry" 
            :visible.sync="showEntryDialog" 
            width="70%">

            <el-form ref="form" :model="entry" :rules="rules">
                <el-row :gutter="10">

                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
                        <el-form-item label="Category" prop="category" size="mini" required>
                            <el-select v-model="entry.category" placeholder="select category" size="small" class="w-full">
                                <el-option v-for="item in GET_ENTRY_CATEGORIES" :key="item" :label="item" :value="item"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
                        <el-form-item label="Reference" prop="reference" size="mini" v-if="entry.category !== 'INVOICE' ">
                            <el-input type="text" v-model="entry.reference" size="small"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" v-if="entry.category == 'PURCHASE' ">
                        <el-form-item label="Supplier" prop="supplier" size="mini">
                            <el-select v-model="entry.supplier" placeholder="select supplier" size="small" class="w-full">
                                <el-option 
                                    v-for="supplier in GET_SUPPLIERS.results"
                                    :remote-method="remoteFetchSupplier" 
                                    :label="supplier.name" 
                                    :value="supplier.id"
                                    :key="supplier.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" v-if="entry.category == 'INVOICE' ">
                        <el-form-item label="Customer" prop="customer" size="mini">
                            <el-select v-model="entry.customer" placeholder="select customer" size="small" class="w-full">
                                <el-option 
                                    v-for="customer in GET_CUSTOMERS.results"
                                    :remote-method="remoteFetchCustomer" 
                                    :label="customer.name" 
                                    :value="customer.id"
                                    :key="customer.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
                        <el-form-item label="Date" prop="posting_date" size="mini" required>
                            <el-input type="date" v-model="entry.posting_date" size="small"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
                        <el-form-item 
                            label="Amount" prop="amount" size="mini" 
                            v-if="entry.category == 'EXPENSE' || entry.category == 'OTHER_INCOME' || entry.category == 'INVOICE'">
                            <el-input-number :min="0" v-model="entry.amount" size="small" class="w-full"></el-input-number>
                        </el-form-item>
                    </el-col>  

                </el-row>

                <el-row :gutter="10">
                    <el-col :sm="12" :md="8" :lg="6" :xl="4">
                        <el-form-item label="Account(DR)" size="mini" required>
                            <el-select  v-model="entry.nodes[0].account" 
                                        filterable remote
                                        :remote-method="remoteFetchAccount" 
                                        placeholder="select account"
                                        size="small" class="w-full">

                                <el-option  v-for="account in GET_ACCOUNTS.results" 
                                            :label="account.name" 
                                            :value="account.id" 
                                            :key="account.id"
                                            class="flex justify-between space-x-3">
                                    <span>{{ account.code }}</span>
                                    <span>{{ account.name }}</span>   
                                </el-option>

                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="16" :lg="12" :xl="16">
                        <el-form-item label="Description" prop="description" size="mini">
                            <el-input type="text" v-model="entry.description" size="small" clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="6" :xl="4">
                        <el-form-item label="Account(CR)" size="mini" required>
                            <el-select  v-model="entry.nodes[1].account" 
                                        filterable remote
                                        :remote-method="remoteFetchAccount" 
                                        placeholder="select account"
                                        size="small" class="w-full">

                                <el-option  v-for="account in GET_ACCOUNTS.results" 
                                            :label="account.name" 
                                            :value="account.id" 
                                            :key="account.id"
                                            class="flex justify-between space-x-3">
                                    <span>{{ account.code }}</span>
                                    <span>{{ account.name }}</span>   
                                </el-option>

                            </el-select>
                        </el-form-item>
                    </el-col>

                </el-row>

                <!-- Purchase From Items -->
                <table class="overflow-x-hidden w-full bg-white border-0 my-5" v-if="entry.category == 'PURCHASE' ">
                    <thead class="bg-blue-50 text-gray-600 text-sm">
                        <tr class="divide-x divide-gray-200">
                            <th class="py-2 text-center text-xs font-medium uppercase">Product</th>
                            <th class="py-2 text-center text-xs font-medium uppercase">Quantity</th>
                            <th class="py-2 text-center text-xs font-medium uppercase">Buy Price</th>
                            <th class="py-2 text-center text-xs font-medium uppercase">Ordered</th>
                            <th class="py-2 text-center text-xs font-medium uppercase">Received</th>
                            <th class="py-2 text-center text-xs font-medium uppercase">Variation</th>
                            <th class="py-2 text-center text-xs font-medium uppercase">Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(item, index) in entry.purchases" :key="index">
                            <td class="pr-4 truncate">
                                <el-select v-model="item.product" placeholder="select product" size="mini" class="w-full">
                                    <el-option  v-for="product in GET_PRODUCTS.results"
                                                :remote-method="remoteFetchProducts" 
                                                :label="product.name" 
                                                :value="product.id" 
                                                :key="product.id"
                                                class="flex justify-between space-x-3">
                                            <span>{{ product.name }}</span>
                                            <span v-if="product.weight > 0">{{ product.weight }} kg</span> 
                                            <span v-if="product.volume > 0">{{ product.volume }} ml</span>
                                    </el-option>
                                </el-select>
                            </td>

                            <td class="pr-4 truncate">
                                <el-input-number :min="0" v-model="item.quantity" size="mini" class="w-full"></el-input-number>
                            </td>

                            <td class="pr-4 truncate">
                                <el-input-number :min="0" v-model="item.buy_price" size="mini" class="w-full"></el-input-number>
                            </td>

                            <td class="pr-4 truncate">
                                <el-input-number :min="0" v-model="item.fuel_ordered" size="mini" class="w-full"></el-input-number>
                            </td>

                            <td class="pr-4 truncate">
                                <el-input-number :min="0" v-model="item.fuel_received" size="mini" class="w-full"></el-input-number>
                            </td>

                            <td class="pr-4 truncate">
                                <el-input-number :min="0" v-model="item.variation" size="mini" class="w-full"></el-input-number>
                            </td>

                            <td class="flex justify-center px-4 py-2 truncate">
                                <button class="table-button" @click="removePurchaseItem(index)">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- End Purchase From Items -->

                <!-- Sale Form Items -->
                <table class="overflow-x-hidden w-full lg:w-3/4 2xl:w-1/2 bg-white border-0 my-5" v-if="entry.category == 'SALE' ">
                    <thead class="bg-blue-50 text-gray-600 text-sm">
                        <tr class="divide-x divide-gray-200">
                            <th class="py-2 text-center text-xs font-medium uppercase">Product</th>
                            <th class="py-2 text-center text-xs font-medium uppercase">Quantity</th>
                            <th class="py-2 text-center text-xs font-medium uppercase">Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(item, index) in entry.sales" :key="index">
                            <td class="pr-4 truncate">
                                <el-select v-model="item.product" placeholder="select product" size="mini" class="w-full">
                                    <el-option  v-for="product in GET_PRODUCTS.results"
                                                :remote-method="remoteFetchProducts" 
                                                :label="product.name" 
                                                :value="product.id" 
                                                :key="product.id"
                                                class="flex justify-between space-x-3">
                                            <span>{{ product.name }}</span>
                                            <span v-if="product.weight > 0">{{ product.weight }} kg</span> 
                                            <span v-if="product.volume > 0">{{ product.volume }} ml</span>
                                    </el-option>
                                </el-select>
                            </td>

                            <td class="pr-4 truncate">
                                <el-input-number :min="0" v-model="item.quantity" size="mini" class="w-full"></el-input-number>
                            </td>

                            <td class="flex justify-center px-4 py-2 truncate">
                                <button class="table-button" @click="removeSaleItem(index)">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- End Sale Form Items -->

                <div class="flex justify-between">
                    <div class="flex items-center space-x-2">
                        <div 
                            class="flex items-center space-x-2 basic-button" 
                            @click="addPurchaseItem" 
                            v-if="entry.category == 'PURCHASE' ">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 4v16m8-8H4" />
                            </svg>

                            <span>Purchase Row</span>
                        </div>

                        <div 
                            class="flex items-center space-x-2 basic-button" 
                            @click="addSaleItem" type="text" 
                            v-if="entry.category == 'SALE' ">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 4v16m8-8H4" />
                            </svg>

                            <span>Sale Row</span>
                        </div>
                    </div>

                    <el-button @click="createEntry('form')" :loading="loading" 
                        class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                            Create
                    </el-button>
                </div>

            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { errorRecursion } from "@/helpers/index.js"

export default {
    name: "EntryCreate",

    data() {
        return {
            showEntryDialog: false,
            loading: false,
            entry: {
                category: "",
                reference: "",
                supplier: null,
                amount: 0,
                description: "",
                posting_date: null,
                nodes: [
                    { types: "DEBIT", account: null },
                    { types: "CREDIT", account: null }
                ],
                sales: [],
                purchases: []
            },
            rules: {
                category: [
					{ required: true, message: "Please input category", trigger: "change" },
				],
				posting_date: [
					{ required: true, message: "Please input date", trigger: "change" },
                ]
            }
        }
    },

    computed: {
        ...mapGetters([
            "GET_ACCOUNTS",
            "GET_ENTRY_CATEGORIES", 
            "GET_CUSTOMERS", 
            "GET_PRODUCTS", 
            "GET_SUPPLIERS"
        ]),
    },

    methods: {
        ...mapActions([
            "handleEntryCreate", 
            "fetchAccounts", 
            "fetchEntries", 
            "fetchCustomers", 
            "fetchProducts", 
            "fetchSuppliers", 
            "fetchAccountStatement"
        ]),

        addPurchaseItem() {
            this.entry.purchases.push({ product: null, quantity: 0, buy_price: 0, fuel_ordered: 0, fuel_received: 0, variation: 0 });
        },

        removePurchaseItem(index) {
            this.entry.purchases.splice(index, 1);
        },

        addSaleItem() {
            this.entry.sales.push({ product: null, quantity: 0 });
        },

        removeSaleItem(index) {
            this.entry.sales.splice(index, 1);
        },

        filterUnusedData(val) {
            if (val === "SALE") {
                delete this.entry["purchases"];
            }

            else if (val === "PURCHASE") {
                delete this.entry["sales"];
            }
            else {
                delete this.entry["customer"];
                delete this.entry["purchases"];
                delete this.entry["sales"];
                delete this.entry["supplier"];
            }
        },

        createEntry(form) {
            this.$refs[form].validate((valid) => {
                if(valid) {
                    this.loading = true; 
                    this.filterUnusedData(this.entry.category);

                    this.handleEntryCreate(this.entry).then(data => {
                        this.loading = false
                        this.showEntryDialog = false

                        this.$notify.success({ 
                            title: "Operation Successful", 
                            message: `${data.category} entry created` 
                        });

                        this.$refs[form].resetFields();
                        this.fetchEntries();
                        this.fetchAccountStatement();
                    }).catch(error => {
                        this.loading = false;

                        let errorList = []
                        let message = ""
                        errorRecursion(error.data, errorList);
                        errorList.forEach((item) => {
                            message += "<li>" + item + "</li>"
                        })

                        this.$notify.error({ 
                            title: "Error",
                            dangerouslyUseHTMLString: true, 
                            message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                        });
                    })
                } else {
                    return False
                }
            })
        },

        remoteFetchAccount(query) {
            if(query != "") {
                this.fetchAccounts({"search": query})
                query = "";
            }

        },

        remoteFetchProducts(query) {
            if(query != "") {
                this.fetchProducts({"search": query})
                query = "";
            }
        },

        remoteFetchCustomer(query) {
            if(query != "") {
                this.fetchCustomers({"search": query})
                query = "";
            }
        },

        remoteFetchSupplier(query) {
            if(query != "") {
                this.fetchSuppliers({"search": query})
                query = "";
            }
        }
    }
}
</script>