import axios from "axios"

export default {
	state: {
        pumps: {},
        readings: {},
        dippingStock: {},
    },

	getters: {
        GET_PUMPS(state) {
            return state.pumps
        },

        GET_READINGS(state) {
            return state.readings
        },

        GET_DIPPING_STOCK(state) {
            return state.dippingStock
        },
    },

	mutations: {
        SET_PUMPS(state, payload) {
            state.pumps = payload
        },

        SET_READINGS(state, payload) {
            state.readings = payload
        },

        SET_DIPPING_STOCK(state, payload) {
            state.dippingStock = payload
        },
    },

    actions: {
        fetchDippingStock({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_INVENTORY_URL + "/dipping_stock/", {
                params: Object.assign({
                    "ordering": payload.ordering, 
                    "page": payload.page, 
                    "search": payload.search,
                    "branch": getters.GET_ACTIVE_BRANCH.id 
                }, payload.filter),
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_DIPPING_STOCK", res.data);
            })
            .catch((err) => {
                // pass
            })
        },

        handleDippingStockCreate({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_INVENTORY_URL + "/dipping_stock/", payload, 
				{
					params: {
                        "branch": getters.GET_ACTIVE_BRANCH.id
                    },
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleDippingStockUpdate({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.put(process.env.VUE_APP_INVENTORY_URL + "/dipping_stock/" + payload.id + "/", payload, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleDippingStockDelete({ getters }, payload={}) {
            console.log(payload)
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_INVENTORY_URL + "/dipping_stock/" + payload.id + "/", Object.assign(payload, {"trashed": true}), {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        fetchPumps({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_INVENTORY_URL + "/pump/", {
                params: Object.assign({
                    "ordering": payload.ordering, 
                    "page": payload.page, 
                    "search": payload.search,
                    "branch": getters.GET_ACTIVE_BRANCH.id 
                }, payload.filter),
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_PUMPS", res.data);
            })
            .catch((err) => {
                // pass
            })
        },

        handlePumpCreate({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_INVENTORY_URL + "/pump/", payload, 
				{
					params: {
                        "branch": getters.GET_ACTIVE_BRANCH.id
                    },
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handlePumpUpdate({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_INVENTORY_URL + "/pump/" + payload.id + "/", payload, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handlePumpDelete({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_INVENTORY_URL + "/pump/" + payload.id + "/", {"trashed": true}, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        fetchPumpReadings({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_INVENTORY_URL + "/pump_reading/", {
                params: Object.assign({
                    "ordering": payload.ordering, 
                    "page": payload.page, 
                    "search": payload.search,
                    "branch": getters.GET_ACTIVE_BRANCH.id 
                }, payload.filter),
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_READINGS", res.data);
            })
            .catch((err) => {
                // pass
            })
        },

        handlePumpReadingCreate({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_INVENTORY_URL + "/pump_reading/", payload, 
				{
					params: {
                        "branch": getters.GET_ACTIVE_BRANCH.id
                    },
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handlePumpReadingUpdate({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.put(process.env.VUE_APP_INVENTORY_URL + "/pump_reading/" + payload.id + "/", payload, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handlePumpReadingDelete({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.delete(process.env.VUE_APP_INVENTORY_URL + "/pump_reading/" + payload.id + "/", {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        }
    }
}