<template>
    <div>
        <div class="flex justify-between space-x-4 items-center mb-2">
            <div class="w-full md:w-1/2 xl:w-1/4">
                <el-input 
                    v-model="search" 
                    size="mini" 
                    placeholder="Type to search" 
                    @input="searchProductInventory" />
            </div>

            <button 
                class="basic-button" 
                v-if="selectedProductInventory.length > 0">
                <export-excel 
                    :data="selectedProductInventory" 
                    :fields="exportFields" 
                    name="Products Inventory.xls">
                    Export Excel
                </export-excel>
            </button>
        </div>

        <el-table 
            border size="mini" 
            :data="GET_PRODUCT_INVENTORY.results"
            @selection-change="handleSelectedProductInventory"
            @sort-change="handleProductInventorySort" 
            style="width: 100%">
            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column prop="created" label="Date" sortable="custom"></el-table-column>

            <el-table-column prop="product" label="Product" sortable="custom">
                <template slot-scope="scope">{{ scope.row.product.name }}</template>
            </el-table-column>

            <el-table-column prop="quantity" label="Quantity" align="center" sortable="custom"></el-table-column>

            <el-table-column prop="unit_price" label="Price" align="center" sortable="custom">
                <template slot-scope="scope">
                    {{ scope.row.unit_price | number('0,0') }}
                </template>
            </el-table-column>

            <el-table-column prop="total" label="Total" align="center" sortable="custom">
                <template slot-scope="scope">
                    {{ scope.row.total | number('0,0') }}
                </template>
            </el-table-column>

        </el-table>

        <div class="flex justify-end mt-4">
            <el-pagination 
                background layout="prev, pager, next" 
                :total="GET_PRODUCT_INVENTORY.count" 
                :page-size="50"
                @current-change="currentPageChanged">
            </el-pagination>
        </div>
        
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
    name: "ProductInventoryList",

    data() {
        return {
            search: "",
            sortField: "",
            selectedProductInventory: []
        }
    },

    computed: {
        ...mapGetters(["GET_PRODUCT_INVENTORY"]),

        exportFields() {
            return {
                "Product": {
                    field: "product",
                    callback: (value) => {
                        return value.name
                    }
                },
                "Volume(ml)": {
                    field: "product",
                    callback: (value) => {
                        return value.volume
                    }
                },
                "Weight(kg)": {
                    field: "product",
                    callback: (value) => {
                        return value.weight
                    }
                },
                "quantity": "quantity",
                "Price": "unit_price",
                "Total": "total",
            }
        }
    },

    methods: {
        ...mapActions(["fetchProductInventory"]),

        handleSelectedProductInventory(val) {
            this.selectedProductInventory = val;
        },

        searchProductInventory() {
            this.fetchProductInventory({"search": this.search })
        },

        handleProductInventorySort(val) {
            if(val.order == "descending") {
                this.sortField = "-" + val.prop
            } else {
                this.sortField = val.prop
            }

            this.fetchProductInventory({"ordering": this.sortField })
        },

        currentPageChanged(value) {
            this.fetchProductInventory({"page": value})
        }
    }
}
</script>