<template>
    <div class="flex justify-end mb-5">
		<button class="default-button" @click="showReadingModal = true">New Reading</button>

        <el-dialog 
            title="New Reading" 
            :visible.sync="showReadingModal" 
            width="80%">

            <table class="overflow-x-auto w-full bg-white border-0 mb-5">
                <thead class="bg-blue-50 text-gray-600 text-sm">
                    <tr class="divide-x divide-gray-200">
                        <th class="py-2 text-center text-xs font-medium uppercase">Pump</th>
                        <th class="py-2 text-center text-xs font-medium uppercase">Opening</th>
                        <th class="py-2 text-center text-xs font-medium uppercase">Closing</th>
                        <th class="py-2 text-center text-xs font-medium uppercase">Fuel Return</th>
                        <th class="py-2 text-center text-xs font-medium uppercase">Date</th>
                        <th class="py-2 text-center text-xs font-medium uppercase">Action</th>
                    </tr>
                </thead>

                <tbody class="text-xs divide-y divide-gray-200">
                    <tr v-for="(item, index) in reading" 
                        :key="index">

                        <td class="px-4 py-2 truncate">
                            <el-select v-model="item.pump" placeholder="select pump" size="small" class="w-full">
                                <el-option  v-for="item in GET_PUMPS.results" 
                                            :label="item.name" 
                                            :value="item.id" 
                                            :key="item.id">   
                                </el-option>
                            </el-select>
                        </td>

                        <td class="px-4 py-2 truncate">
                            <el-input-number :min="0" v-model="item.opening" size="small" class="w-full"></el-input-number>
                        </td>

                        <td class="px-4 py-2 truncate">
                            <el-input-number :min="0" v-model="item.closing" size="small" class="w-full"></el-input-number>
                        </td>

                        <td class="px-4 py-2 truncate">
                            <el-input-number :min="0" v-model="item.fuel_return" size="small" class="w-full"></el-input-number>
                        </td>

                        <td class="px-4 py-2 truncate">
                            <el-input type="date" v-model="item.posting_date" size="small"></el-input>
                        </td>

                        <td class="flex justify-center px-4 py-2 truncate">
                            <button class="table-button" @click="removePumpReadingItem(index)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <el-row type="flex" justify="space-between">
                <el-button @click="addPumpReadingItem" type="text">New Row</el-button>

                <el-button @click="createReading" :loading="loading"
                    v-if="reading.length > 0" 
                    class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                        Create
                </el-button>
            </el-row>

        </el-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { errorRecursion } from "@/helpers/index.js"

export default {
    name: "PumpReadingCreate",

    data() {
        return {
            showReadingModal: false,
            loading: false,
            reading: [
                { opening: 0, closing: 0, fuel_return: 0, pump: null, posting_date: null }
            ]
        }
    },

    computed: {
        ...mapGetters(["GET_PUMPS"]),
    },

    methods: {
        ...mapActions(["handlePumpReadingCreate", "fetchPumpReadings"]),

        addPumpReadingItem() {
            this.reading.push({ pump: null, opening: 0, closing: 0, fuel_return: 0 });
        },

        removePumpReadingItem(index) {
            this.reading.splice(index, 1);
        },

        createReading() {
            this.handlePumpReadingCreate(this.reading).then(data => {
                this.showReadingModal = false;
                this.loading = false; 

                this.$notify.success({ 
                    title: "Operation Successful", 
                    message: `Reading created` 
                });

                this.fetchPumpReadings();
                this.reading = [];
            }).catch(error => {
                this.loading = false;
                
                let errorList = []
                let message = ""
                errorRecursion(error.data, errorList);
                errorList.forEach((item) => {
                    message += "<li>" + item + "</li>"
                })

                this.$notify.error({ 
                    title: "Error",
                    dangerouslyUseHTMLString: true, 
                    message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                });
            })
        }
    }
}
</script>