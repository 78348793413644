<template>
    <el-dialog
        title="Edit Customer"
        :visible.sync="showCustomerModal"
        width="50%">

        <el-form ref="form" :model="customer">

            <el-row :gutter="10">
                <el-col :sm="12" :md="12" :lg="12" :xl="8">
                    <el-form-item label="Name" prop="name" size="mini" required>
                        <el-input type="text" v-model="customer.name"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="12" :lg="12" :xl="8">
                    <el-form-item label="Email" prop="email" size="mini">
                        <el-input type="email" v-model="customer.email"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="8" :lg="8" :xl="8">
                    <el-form-item label="Phone" prop="phone" size="mini">
                        <el-input type="text" v-model="customer.phone" placeholder="eg +255710202020"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="8" :lg="8" :xl="8">
                    <el-form-item label="LandLine" prop="landline" size="mini">
                        <el-input type="text" v-model="customer.landline" placeholder="eg +255710202020"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="8" :lg="8" :xl="8">
                    <el-form-item label="Region" prop="region" size="mini">
                        <el-input type="text" v-model="customer.region"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="12" :lg="6" :xl="8">
                    <el-form-item label="District" prop="district" size="mini">
                        <el-input type="text" v-model="customer.district"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="12" :lg="12" :xl="8">
                    <el-form-item label="Address" prop="address" size="mini">
                        <el-input type="text" v-model="customer.address"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="12" :lg="6" :xl="8">
                    <el-form-item label="Website" prop="website" size="mini">
                        <el-input type="text" v-model="customer.website"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="12" :lg="8" :xl="8">
                    <el-form-item label="TIN" prop="tin" size="mini">
                        <el-input type="text" v-model="customer.tin"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="10">
                <el-col :span="4">
                    <el-form-item>
                        <el-button 
                            @click="updateCustomer" :loading="loading"
                            class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                            Save Changes
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>
    </el-dialog>
</template>

<script>
import { mapActions } from "vuex"
import { errorRecursion } from "@/helpers/index.js"

export default {
    name: "CustomerUpdate",

    props: {
        updateObject: {
            type: Object,
            required: true
        },

        showCustomerModal: {
            type: Boolean,
            default: false
        }
    },

    data() {
    	return {
    		customer: {},
            loading: false
    	}
    },

    watch: {
        updateObject: function(val) {
            this.customer = JSON.parse(JSON.stringify(val));
        }
    },

    methods: {
        ...mapActions(["handleCustomerUpdate", "fetchCustomers"]),

        updateCustomer() {
            this.loading = true;

            this.handleCustomerUpdate(this.customer).then(data => {
                this.$emit("onSuccess")
                this.loading = false;

                this.$notify.success({ 
                    title: "Operation Successful", 
                    message: `Customer ${data.name} updated` 
                });

                this.fetchCustomers();
                
            }).catch(error => {
                this.$emit("onSuccess");
                this.loading = false;

                let errorList = []
                let message = ""
                errorRecursion(error.data, errorList);
                errorList.forEach((item) => {
                    message += "<li>" + item + "</li>"
                })

                this.$notify.error({ 
                    title: "Error",
                    dangerouslyUseHTMLString: true, 
                    message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                });
            })
        }

    }
}
</script>