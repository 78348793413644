<template>
    <div class="flex justify-end mb-5">
		<button class="default-button" @click="showPumpModal = true">New Pump</button>

        <el-dialog 
            title="New Pump" 
            :visible.sync="showPumpModal" 
            width="30%">

            <el-form ref="form" :model="pump" :rules="rules">

                <el-row :gutter="10">
                    <el-col :sm="24" :md="12">
                        <el-form-item label="Name" prop="name" size="mini" required>
                            <el-input type="text" v-model="pump.name" size="small"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="12">
                        <el-form-item label="Product" prop="product" size="mini" required>
                            <el-select v-model="pump.product" placeholder="select product" size="small" class="w-full">
                                <el-option  v-for="item in GET_PRODUCTS.results" 
                                            :label="item.name" 
                                            :value="item.id" 
                                            :key="item.id"
                                            class="flex justify-between space-x-3">
                                    <span>{{ item.name }}</span>
                                    <span v-if="item.weight > 0">{{ item.weight }} kg</span> 
                                    <span v-if="item.volume > 0">{{ item.volume }} ml</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item>
                    <el-button 
                        @click="createPump('form')" :loading="loading"
                        class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                        Create
                    </el-button>
                </el-form-item>

            </el-form>
        </el-dialog>

    </div>
</template>

<script>
import  { mapActions, mapGetters } from "vuex"
import { errorRecursion } from "@/helpers/index.js"

export default {
    name: "PumpCreate",
    data() {
        return {
            showPumpModal: false,
            loading: false,
            pump: {
                name: "",
                product: "",
                trashed: true
            },
            rules: {
				name: [
					{ required: true, message: "Please input name", trigger: "change" },
				],
				product: [
					{ required: true, message: "Please input product", trigger: "change" },
                ]
			}
        }
    },

    computed: {
        ...mapGetters(["GET_PRODUCTS"])
    },

    methods: {
        ...mapActions(["handlePumpCreate", "fetchPumps", "fetchProducts"]),

        createPump(form) {
            this.$refs[form].validate((valid) => {
				if(valid) {
                   this.handlePumpCreate(this.pump).then(data => {
                        this.showPumpModal = false;
                        this.loading = false; 

                		this.$notify.success({ 
                            title: "Operation Successful", 
                            message: `Pump ${data.name} created` 
                        });

                        this.$refs[form].resetFields();
                        this.fetchPumps();
                   }).catch(error => {
                       this.loading = false;

                        let errorList = []
                        let message = ""
                        errorRecursion(error.data, errorList);
                        errorList.forEach((item) => {
                            message += "<li>" + item + "</li>"
                        })

                        this.$notify.error({ 
                            title: "Error",
                            dangerouslyUseHTMLString: true, 
                            message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                        });
                    })
                } else { return false }
            })
        }
    },

    mounted() {
        this.fetchProducts({
            filter: {
                dipping: true
            }
        })
    }
}
</script>