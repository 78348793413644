import axios from "axios"

export default {
	state: {
        products: {},
        productInventory: {}
    },

	getters: {
        GET_PRODUCTS(state) {
            return state.products
        },

        GET_PRODUCT_CATEGORY(state) {
            return [
                { name: "Gas", label: "GAS" },
                { name: "Lubricants", label: "LUBRICANTS" },
                { name: "GO", label: "GO" },
                { name: "IK", label: "IK" },
                { name: "PMS", label: "PMS" }
            ]
        },

        GET_PRODUCT_INVENTORY(state) {
            return state.productInventory
        }

    },

	mutations: {
        SET_PRODUCTS(state, payload) {
            state.products = payload
        },

        SET_PRODUCT_INVENTORY(state, payload) {
            state.productInventory = payload
        }
    },

    actions: {
        fetchProducts({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_INVENTORY_URL + "/product/", {
                params: Object.assign({
                    "ordering": payload.ordering, 
                    "page": payload.page, 
                    "search": payload.search,
                    "branch": getters.GET_ACTIVE_BRANCH.id 
                }, payload.filter),
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_PRODUCTS", res.data);
            })
            .catch((err) => {
                // pass
            })
        },

        handleProductCreate({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_INVENTORY_URL + "/product/", payload, 
				{
					params: {
                        "branch": getters.GET_ACTIVE_BRANCH.id
                    },
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleProductUpdate({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_INVENTORY_URL + "/product/" + payload.id + "/", payload, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleProductDelete({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_INVENTORY_URL + "/product/" + payload.id + "/", {"trashed": true}, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        fetchProductInventory({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_INVENTORY_URL + "/stock/", {
                params: Object.assign({
                    "ordering": payload.ordering, 
                    "page": payload.page, 
                    "search": payload.search,
                    "branch": getters.GET_ACTIVE_BRANCH.id 
                }, payload.filter),
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_PRODUCT_INVENTORY", res.data);
            })
            .catch((err) => {
                // pass
            })
        },

        handleProductInventoryCreate({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_INVENTORY_URL + "/stock/", payload, 
				{
					params: {
                        "branch": getters.GET_ACTIVE_BRANCH.id
                    },
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },
    }
}