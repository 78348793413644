<template>
    <div class="space-y-5">
        <div class="grid grid-col-1 lg:grid-cols-2 2xl:grid-cols-3 gap-5">
            <div class="border shadow-sm rounded-md px-6 py-2"></div>

            <div class="px-6 py-2">
                <p class="text-sm 2xl:text-base font-medium mb-2">Bank Accounts</p>

                <div class="grid grid-cols-1 lg:grid-cols-2 gap-5" v-if="bankAccounts">

                    <router-link 
                        :to="{ name: 'account-detail', params: { accountId: account.id} }"
                        v-for="account in bankAccounts.results" :key="account.id"
                        class="flex justify-between items-center space-x-4 p-2 border rounded-md text-xs hover:pointer hover:bg-gray-100">

                        <p class="truncate">{{ account.name }}</p>
                        <p>{{ account.balance | number('0,0') }} Tshs</p>
                    </router-link>

                </div>
            </div>
        </div>

        <div>
            <div class="flex justify-end items-center space-x-4 mb-2.5">
                <button class="table-button" v-print="printFuelReportOject">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2"></path>
                        <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4"></path>
                        <rect x="7" y="13" width="10" height="8" rx="2"></rect>
                    </svg>
                </button>
            </div>

            <div class="overflow-x-auto">
                <table class="w-full bg-white border" id="fuel_report">
                    <thead class="border-b bg-gray-50 text-gray-600 text-sm">
                        <tr class="divide-x divide-gray-200">
                            <th class="table-head" rowspan="2">Product</th>
                            <th class="table-head" colspan="7">Daily Sales Summary</th>
                            <th class="table-head" colspan="6">Daily Stock</th>
                        </tr>
                        <tr class="divide-x divide-gray-200">
                            <th class="table-head">Pump</th>
                            <th class="table-head">Opening</th>
                            <th class="table-head">Closing</th>
                            <th class="table-head">Return</th>
                            <th class="table-head">Sold</th>
                            <th class="table-head">Unit Price</th>
                            <th class="table-head">Total Value</th>
                            <th class="table-head">Opening</th>
                            <th class="table-head">Added</th>
                            <th class="table-head">Sold</th>
                            <th class="table-head">Actual</th>
                            <th class="table-head">Dipping</th>
                            <th class="table-head">Variation</th>
                        </tr>
                    </thead>
                    <tbody class="text-xs 2xl:text-sm divide-y divide-gray-200">
                        <tr class="divide-x divide-gray-200" v-for="(item, index) in fuelReport" :key="index">

                            <td class="px-4 py-2 truncate" rowspan="item.pumps.length">{{ item.name }}</td>

                            <td class="truncate divide-y divide-gray-200 text-center">
                                <ul class="px-4 py-2" v-for="pump in item.pumps" :key="pump.name">
                                    <li>{{ pump.name }}</li>
                                </ul>
                            </td>

                            <td class="truncate divide-y divide-gray-200 text-center">
                                <ul class="px-4 py-2" v-for="pump in item.pumps" :key="pump.name">
                                    <li v-if="pump.readings">
                                        {{ pump.readings.closing | number("0,0")  }}
                                    </li>
                                    <li v-else>-</li>
                                </ul>
                            </td>

                            <td class="truncate divide-y divide-gray-200 text-center">
                                <ul class="px-4 py-2" v-for="pump in item.pumps" :key="pump.name">
                                    <li v-if="pump.readings">
                                        {{ pump.readings.opening | number("0,0") }}
                                    </li>
                                    <li v-else>-</li>
                                </ul>
                            </td>

                            <td class="truncate divide-y divide-gray-200 text-center">
                                <ul class="px-4 py-2" v-for="pump in item.pumps" :key="pump.name">
                                    <li v-if="pump.readings">
                                        {{ pump.readings.fuel_return }}
                                    </li>
                                    <li v-else>-</li>
                                </ul>
                            </td>

                            <td class="truncate divide-y divide-gray-200 text-center">
                                <ul class="px-4 py-2" v-for="pump in item.pumps" :key="pump.name">
                                    <li v-if="pump.readings">
                                        {{ pump.readings.sold | number("0,0")  }}
                                    </li>
                                    <li v-else>-</li>
                                </ul>
                            </td>

                            <td class="truncate divide-y divide-gray-200 text-center">
                                <ul class="px-4 py-2" v-for="pump in item.pumps" :key="pump.name">
                                    <li v-if="pump.readings">
                                        {{ pump.readings.unit_price | number("0,0") }}
                                    </li>
                                    <li v-else>-</li>
                                </ul>
                            </td>

                            <td class="truncate divide-y divide-gray-200 text-center">
                                <ul class="px-4 py-2" v-for="pump in item.pumps" :key="pump.name">
                                    <li v-if="pump.readings">
                                        {{ pump.readings.total | number("0,0") }}
                                    </li>
                                    <li v-else>-</li>
                                </ul>
                            </td>

                            <td class="px-4 py-2 truncate text-center" rowspan="item.pumps.length">
                                <span v-if="item.stock">{{ item.stock.opening }}</span>
                                <span v-else>-</span>
                            </td>

                            <td class="px-4 py-2 truncate text-center" rowspan="item.pumps.length">
                                <span v-if="item.stock">{{ item.stock.added }}</span>
                                <span v-else>-</span>
                            </td>

                            <td class="px-4 py-2 truncate text-center" rowspan="item.pumps.length">
                                <span v-if="item.stock">{{ item.stock.sold }}</span>
                                <span v-else>-</span>
                            </td>

                            <td class="px-4 py-2 truncate text-center" rowspan="item.pumps.length">
                                <span v-if="item.stock">{{ item.stock.actual }}</span>
                                <span v-else>-</span>
                            </td>

                            <td class="px-4 py-2 truncate text-center" rowspan="item.pumps.length">
                                <span v-if="item.stock">{{ item.stock.dipping }}</span>
                                <span v-else>-</span>
                            </td>

                            <td class="px-4 py-2 truncate text-center" rowspan="item.pumps.length">
                                <span v-if="item.stock">{{ item.stock.variation }}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- <el-row :gutter="20">
        <el-col :xs="24" :sm="16" :md="16" :lg="18">

            <el-row :gutter="20">
                <el-col :xs="24" :sm="12" :md="12" :lg="12">
                    <el-card shadow="never">
                        <div v-if="line_sale_options.xaxis.categories.length > 0">
                            <apexchart type="line" :options="line_sale_options" :series="saleReport">
                            </apexchart>
                        </div>
                    </el-card>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12" :lg="12" v-if="expenseReport.length > 0">
                    <el-card shadow="never">
                        <div class="flex justify-between">
                            <div class="pb2">Expenses</div> -->

                            <!-- <el-switch v-model="expDuration" active-text="month" inactive-text="year">
                            </el-switch> -->
                        <!-- </div>
                        <div>
                            <apexchart type="donut" :options="donut_expense_options" 
                            :series="expenseReport">
                            </apexchart>
                        </div>
                    </el-card>
                </el-col> -->
           
                <!-- <el-col :xs="24" :sm="12" :md="12" :lg="12">
                    <el-card shadow="hover">
                        <div class="flex justify-between">
                            <div class="pb2">Income</div>
                        </div>
                    </el-card>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12" :lg="12">
                    <el-card shadow="hover">
                        <div class="flex justify-between">
                            <div class="pb2">Profit & Loss</div>

                        </div>
                        <div></div>

                        <div class="flex flex-column">
                            <div class="text-muted">
                                Income <span class="pl2">5,205,000 TZS</span>
                            </div>
                            <el-progress :stroke-width="10" :percentage="70" color="#75cc66">
                            </el-progress>

                            <div class="text-muted">
                                Expense <span class="pl2">2,365,000 TZS</span>
                            </div>
                            <el-progress :stroke-width="10" :percentage="58" color="#d49904">
                            </el-progress>
                        </div>
                    </el-card>
                </el-col>
                 -->
            <!-- </el-row>

        </el-col> -->

        <!-- Bank Accounts -->
        <!-- <el-col :xs="24" :sm="8" :md="8" :lg="6">
            <el-card shadow="hover" v-if="getAccounts">
                <div class="flex justify-between">
                    <div class="pb2">Bank Accounts</div>
                </div>

                <div v-for="account in getAccounts.results" :key="account.code">
                    <div>{{ account.name }} <span class="pl1">{{ account.code }}</span></div>

                    <small class="text-muted">
                        System Balance <span class="pl1">{{ account.balance| number("0,0") }} TZS</span>
                    </small>
                    <el-divider></el-divider>
                </div>
            </el-card>
        </el-col> -->

        <!-- Fuel Sales -->
        <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24">
            <el-row type="flex" justify="end">
                <el-col :xs="12" :sm="6" :md="5" :lg="4">
                    <el-button type="primary" size="mini" v-print="'#fuel_report'" plain>
                        Export Sales Summary
                    </el-button>
                </el-col>
            </el-row> -->

        <!-- </el-col> -->
    </div>
</template>

<script>
let moment = require("moment")
import  { mapActions, mapGetters } from "vuex"

export default {
    name: "Dashboard",
    data() {
        return {
            bankAccounts: {},
            expDuration: "",
            pfDuration: "",
            dataDate: new Date(),
            fuelReport: [],
            expenseReport: [],
            saleReport: [{
                name: "Sales",
                data: []
            }],
            printFuelReportOject: {
                id: "fuel_report",
                popTitle: "Fuel Sale Summary Report"
            },
            donut_expense_options: {
                labels: [],
            },
            line_sale_options: {
                title: {
                    text: "SALES",
                    style: {
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#9093A6"
                    },
                },
                chart: {
                    dropShadow: {
                        enabled: true,
                        color: "#000",
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    show: false
                },
                markers: {
                    size: 4
                },
                colors: ["#9093A6"],
                stroke: {
                    curve: "smooth",
                    width: 3,
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    show: false
                }
            }
        }
    },

    computed: {
        ...mapGetters(["GET_ACTIVE_BRANCH", "GET_TOKEN"]),
    },

    watch: {
        "GET_ACTIVE_BRANCH": function(newValue, oldValue) {
            if(newValue !== null || newValue !== undefined) {
                this.fetchBankAccounts();
                this.fetchFuelReport();
            }
        }
    },

    methods: {
        // ...mapActions([
        //     "fetchExpenseReport",
        //     "fetchSaleReport",
        //     "fetchFuelReport"
        // ]),

        fetchBankAccounts() {
            this.$http.get(process.env.VUE_APP_FINANCE_URL + "/accounts/", {
                params: {
                    "purpose": "BANK",
                    "branch": this.GET_ACTIVE_BRANCH.id
                },
                headers: {
                    "Authorization": "Bearer " + this.GET_TOKEN.access
                }
            }).then(res => {
                this.bankAccounts = res.data
            })
        },

        fetchFuelReport() {
            this.$http.get(process.env.VUE_APP_REPORT_URL + "fuel/", {
                params: {
                    "branch": this.GET_ACTIVE_BRANCH.id
                },
                headers: {
                    "Authorization": "Bearer " + this.GET_TOKEN.access
                }
            }).then(res => {
                this.fuelReport = res.data
            })
        },
    },

    mounted() {
        // this.fetchExpenseReport().then(data => {
        //     data.forEach((item) => {
        //         this.donut_expense_options.labels.push(item.category.replace("_", ""))
        //         this.expenseReport.push(item.sum)
        //     })
        // })

        // this.fetchSaleReport().then(data => {
        //     data.forEach((item) => {
        //         var month = moment(item.month).format("MMM")
        //         this.line_sale_options.xaxis.categories.push(month)
        //         this.saleReport[0].data.push(item.sum)
        //     })
        // })


        // this.fetchFuelReport().then(data => {
        //     this.fuelReport = data
        // })
    }
}
</script>