<template>
	<div>
		<GroupCreate v-if="addPermission" />

		<GroupUpdate 
			:showGroupModal="updateModal" 
			:updateObject="updateObject" 
			@onSuccess="updateModal = false" />

		<el-table 
			border size="mini" 
			:data="GET_GROUPS.results" 
			style="width: 100%">
			
			<el-table-column prop="name" label="Name" width="150"></el-table-column>

			<el-table-column prop="permissions"	label="Permissions">
				<template slot-scope="scope">
					<div class="flex flex-wrap">
						<span 
							class="custom-label" 
							v-for="item in scope.row.permissions" :key="item.id">
							{{ item.name }}
						</span>
					</div>
				</template>
			</el-table-column>

			<el-table-column label="Action" align="center" width="150">
				<template slot-scope="scope">
					
					<button class="table-button" @click="updateObject = scope.row; updateModal = true" v-if="changePermission">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
						</svg>
					</button>

					<el-popconfirm
						title="Are you sure to delete this?"
						cancel-button-text="No, Thanks"
						confirm-button-text="OK"
						@confirm="deleteGroup(scope.row)" 
						v-if="deletePermission">

						<button class="table-button" slot="reference">
							<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
							</svg>
						</button>
					</el-popconfirm>
					
				</template>
			</el-table-column>
		</el-table>
		
		<div class="flex justify-end mt-4">
            <el-pagination 
                background layout="prev, pager, next" 
                :total="GET_GROUPS.count" :page-size="50"
                @current-change="currentPageChanged">
            </el-pagination>
        </div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GroupCreate from "@/components/groups/GroupCreate"
import GroupUpdate from "@/components/groups/GroupUpdate"

export default {
    name: "GroupList",

	components: {
		GroupCreate,
		GroupUpdate
	},

    data() {
    	return {
			updateObject: {},
			updateModal: false
    	}
    },

    computed: {
    	...mapGetters(["GET_GROUPS", "GET_USER"]),

		addPermission() {
            return this.GET_USER.permissions.some(item => item === "auth.add_group")
        },

        changePermission() {
            return this.GET_USER.permissions.some(item => item === "auth.change_group")
        },

        deletePermission() {
            return this.GET_USER.permissions.some(item => item === "auth.delete_group")
        }
    },

	methods: {
		...mapActions(["handleGroupDelete", "fetchGroups"]), 

		deleteGroup(item) {
			this.handleGroupDelete(item).then(data => {
				this.fetchGroups();

				this.$notify.success({ 
					title: "Operation Successful", 
					message: "Group deleted" 
				});
			})
		},

		currentPageChanged(value) {
            this.fetchGroups({
                page: value
            })
        }
	}
}
</script>