import Vue from "vue"
import VueRouter from "vue-router"

import Login from "@/views/auth/Login"

import DefaultLayout from "@/layouts/DefaultLayout"
import PageLayout from "@/layouts/PageLayout"
import Portal from "@/views/Portal.vue"

import Accounts from "@/views/accounts/Accounts"
import AccountDetail from "@/views/accounts/AccountDetail"

import CustomerList from "@/views/customers/CustomerList"
import CustomerDetail from "@/views/customers/CustomerDetail"

import DeliveryList from "@/views/delivery/DeliveryList"
import DeliveryDetail from "@/views/delivery/DeliveryDetail"

import EntryList from "@/views/entry/EntryList"
import EntryDetail from "@/views/entry/EntryDetail"

import InvoiceList from "@/views/invoice/InvoiceList"
import InvoiceDetail from "@/views/invoice/InvoiceDetail"

import MemberList from "@/views/members/MemberList"
import MemberDetail from "@/views/members/MemberDetail"

import Products from "@/views/products/Products"

import Pumps from "@/views/pumps/Pumps"

import Receipts from "@/views/receipts/ReceiptList"
import ReceiptDetails from "@/views/receipts/ReceiptDetails"

import SupplierList from "@/views/suppliers/SupplierList"
import SupplierDetail from "@/views/suppliers/SupplierDetail"

import GroupList from "@/views/settings/GroupList"
import PermissionList from "@/views/settings/PermissionList"
import Branch from "@/views/settings/Branch"

import Dashboard from "@/views/landing/Dashboard"
import PurchaseList from "@/views/entry/PurchaseList"
import SaleList from "@/views/entry/SaleList"
import Reports from "@/views/reports/Reports"

Vue.use(VueRouter)

const routes = [
    { path: "/login", name: "login", component: Login },

    { path: "/portal", name: "portal", component: Portal, meta: { requiresAuth: true }},

    { 
        path: "/", component: DefaultLayout, meta: { requiresAuth: true },

        children: [
            { path: "", name: "dashboard", component: Dashboard , meta: { requiresAuth: true } },

            {
                path: "/accounts",
                component: PageLayout,
                meta: { requiresAuth: true },
                children: [
                    { path: "", name: "accounts", component: Accounts, meta: { requiresAuth: true } },

                    { path: ":accountId", name: "account-detail", component: AccountDetail, props: true, meta: { requiresAuth: true }, }
                ]
            },

            {
                path: "/customers",
                component: PageLayout,
                meta: { requiresAuth: true },
                children: [
                    { path: "", name: "customers", component: CustomerList, meta: { requiresAuth: true } },

                    { path: ":customerId", name: "customer-detail", component: CustomerDetail, props: true, meta: { requiresAuth: true }, }
                ]
            },

            {
                path: "/delivery",
                component: PageLayout,
                meta: { requiresAuth: true },
                children: [
                    { path: "", name: "delivery", component: DeliveryList, meta: { requiresAuth: true } },

                    { path: ":deliveryId", name: "delivery-detail", component: DeliveryDetail, props: true, meta: { requiresAuth: true }, }
                ]
            },

            {
                path: "/entry",
                component: PageLayout,
                meta: { requiresAuth: true },
                children: [
                    { path: "", name: "entry", component: EntryList, meta: { requiresAuth: true } },

                    { path: ":entryId", name: "entry-detail", component: EntryDetail, props: true, meta: { requiresAuth: true }, }
                ]
            },

            {
                path: "/invoice",
                component: PageLayout,
                meta: { requiresAuth: true },
                children: [
                    { path: "", name: "invoice", component: InvoiceList, meta: { requiresAuth: true } },

                    { path: ":invoiceId", name: "invoice-detail", component: InvoiceDetail, props: true, meta: { requiresAuth: true } }
                ]
            },

            {
                path: "/members",
                component: PageLayout,
                meta: { requiresAuth: true },
                children: [
                    { path: "", name: "members", component: MemberList, meta: { requiresAuth: true } },

                    { path: ":userId", name: "member-detail", component: MemberDetail, props: true, meta: { requiresAuth: true }, }
                ]
            },

            {
                path: "/products",
                component: PageLayout,
                meta: { requiresAuth: true },
                children: [
                    { path: "", name: "products", component: Products, meta: { requiresAuth: true } }
                ]
            },

            { path: "/pumps", name: "pumps", component: Pumps, meta: { requiresAuth: true } },

            { path: "/purchases", name: "purchases", component: PurchaseList, meta: { requiresAuth: true } },
            {
                path: "/receipts",
                component: PageLayout,
                meta: { requiresAuth: true },
                children: [
                    { path: "", name: "receipts", component: Receipts, meta: { requiresAuth: true }},
                    { path: ":receiptId", name: "receipt-detail", component: ReceiptDetails, meta: { requiresAuth: true }}
                ]
            },

            { path: "/reports", name: "reports", component: Reports, meta: {requiresAuth: true} },

            { path: "/sales", name: "sales", component: SaleList , meta: { requiresAuth: true } },

            {
                path: "/suppliers",
                component: PageLayout,
                meta: { requiresAuth: true },
                children: [
                    { path: "", name: "suppliers", component: SupplierList, meta: { requiresAuth: true } },

                    { path: ":supplierId", name: "supplier-detail", component: SupplierDetail, props: true, meta: { requiresAuth: true }, }
                ]
            },

            {
                path: "",
                component: PageLayout,
                meta: { requiresAuth: true },
                children: [
                    { path: "", name: "settings", component: Branch, meta: { requiresAuth: true } },

                    { path: "groups", name: "groups", component: GroupList, meta: { requiresAuth: true } },

                    { path: "permissions", name: "permissions", component: PermissionList, meta: { requiresAuth: true } },
                ]
            },
        ]
    },
    {
        path: "*",
        redirect: "/"
    }
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})

export default router
