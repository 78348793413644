<template>
    <el-dialog
        title="Edit Account"
        :visible.sync="showAccountModal"
        width="50%">

        <el-form ref="form" :model="account">
            <el-row :gutter="10">
                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="Code" prop="code" size="mini" required>
                        <el-input type="number" v-model="account.code" size="small" clearable>
                        </el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="Name" prop="name" size="mini" required>
                        <el-input type="text" v-model="account.name" size="small" clearable>
                        </el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="Category" prop="category" size="mini" required>
                        <el-select  placeholder="select category" size="small" v-model="account.category" class="w-full">
                            <el-option
                                v-for="item in GET_ACCOUNT_CATEGORIES.results" 
                                :label="item.text"
                                :value="item.db" 
                                :key="item.db">   
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="Purpose" prop="purpose" size="mini" required>
                        <el-select  placeholder="select purpose" size="small" v-model="account.purpose" class="w-full">
                            <el-option
                                v-for="item in ['GENERAL', 'BANK']" 
                                :label="item"
                                :value="item" 
                                :key="item">   
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="12" :lg="8" :xl="6">
                    <el-form-item label="Open Balance" prop="initial_balance" size="mini">
                        <el-input-number size="small" v-model="account.initial_balance" class="w-full"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :sm="24" :md="24" :lg="16" :xl="24">
                    <el-form-item label="Account Description" prop="description" size="mini">
                        <el-input v-model="account.description" size="small" clearable></el-input>
                    </el-form-item>
                </el-col>

            </el-row>

            <el-row :gutter="10">
                <el-col :span="4">
                    <el-form-item>
                        <el-button 
                            @click="updateAccount" :loading="loading"
                            class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                            Save Changes
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>

    </el-dialog>      
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { errorRecursion } from "@/helpers/index.js"

export default {
    name: "AccountUpdate",

    props: {
        updateObject: {
            type: Object,
            required: true
        },

        showAccountModal: {
            type: Boolean,
            default: false
        }
    },

    data() {
    	return {
    		account: {},
            loading: false
    	}
    },

    watch: {
        updateObject: function(val) {
            this.account = JSON.parse(JSON.stringify(val));
        }
    },

    computed: {
    	...mapGetters(["GET_ACCOUNT_CATEGORIES"])
    },

    methods: {
    	...mapActions(["handleAccountUpdate", "fetchAccounts"]),

    	updateAccount() {
            this.loading = true;
            this.handleAccountUpdate(this.account).then(data => {
                this.$emit("onSuccess");
                this.loading = false;

                this.$notify.success({ 
                    title: "Operation Successful", 
                    message: `Account with code ${data.code} updated` 
                });

                this.fetchAccounts();
            }).catch(error => {
                this.$emit("onSuccess");
                this.loading = false;

                let errorList = []
                let message = ""
                errorRecursion(error.data, errorList);
                errorList.forEach((item) => {
                    message += "<li>" + item + "</li>"
                })

                this.$notify.error({ 
                    title: "Error",
                    dangerouslyUseHTMLString: true, 
                    message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                });
            })
    	}
    }
}
</script>