export function errorRecursion(errorObject, errorList=[]) {
	Object.entries(errorObject).forEach(function([key, value]) {
		if(value instanceof Array) {
			value.forEach(function(subItem) {
				return typeof subItem === 'string' ? errorList.push(subItem) : errorRecursion(subItem, errorList);
			})
		} else if(typeof value === 'string') {
			errorList.push(value)
		} else {
			errorRecursion(value, errorList)
		}
	})
}