<template>
    <el-dialog
        title="Edit Group"
        :visible.sync="showGroupModal"
        width="30%">

        <el-form ref="form" :model="group">
            <el-form-item label="Name" prop="name">
                <el-input type="text" v-model="group.name" clearable>
                </el-input>
            </el-form-item>

            <el-form-item label="Permissions" prop="permissions">
                <el-select v-model="group.permissions" class="w-full" multiple collapse-tags>
                    <el-option 
                        :label="option.name" :value="option.id" 
                        v-for="option in GET_PERMISSIONS.results" 
                        :key="option.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button 
                    @click="updateGroup('form')" :loading="loading"
                    class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                    Save Changes
                </el-button>
            </el-form-item>
        </el-form>
        
    </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "GroupUpdate",

    props: {
        updateObject: {
            type: Object,
            required: true
        },
        showGroupModal: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            loading: false,
            group: {}
        }
    },

    watch: {
        updateObject: function(val) {
            this.group = val;
        },

        group: function(val) {
            val.permissions = val.permissions.map(j => j.id);
        }
    },

    computed: {
    	...mapGetters(["GET_PERMISSIONS"])
    },

    methods: {
    	...mapActions(["handleGroupUpdate", "fetchGroups"]),

    	updateGroup(form) {
    		this.$refs[form].validate((valid) => {
    			if(valid) {
                	this.handleGroupUpdate(this.group).then(data => {
                        this.loading = false;
                		
                		this.$notify.success({ 
                            title: "Operation Successful", 
                            message: `Group ${data.name} updated`
                        });

                        this.fetchGroups();
                		this.$emit("onSuccess")
                	})
                } else {
                	return false
                }
    		})
    	}
    }
}
</script>