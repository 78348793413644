<template>
    <div>Entry Detail</div>
</template>

<script>
export default {
    name: "EntryDetail",

    props: {
        entryId: {
            type: String,
            required: true
        }
    },
}
</script>