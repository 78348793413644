import axios from "axios"

export default {
	state: {
        dataDate: new Date()
    },

	getters: {},

	mutations: {},

	actions: {
		fetchExpenseReport({getters, state}) {
			return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_REPORT_URL + "expense/general", {
                    params: { 
                        "station": getters.GET_ACTIVE_BRANCH.id, 
                        "report_date": state.dataDate.toISOString().split("T")[0] 
                    },
                    headers: { 
                        "Authorization" : "Token "  + getters.GET_TOKEN 
                    }
                })
                .then(res => { resolve(res.data) })
                .catch(err => { reject(err.response) })
            })
		},

		fetchSaleReport({getters, state}) {
			return new Promise((resolve, reject) => {
				axios.get(process.env.VUE_APP_REPORT_URL + "sale/", {
					params: { 
						"station": getters.GET_ACTIVE_BRANCH.id, 
						"report_date": state.dataDate.toISOString().split("T")[0] 
					},
					headers: { 
						"Authorization" : "Token " + getters.GET_TOKEN 
					}
				})
				.then(res => { resolve(res.data) })
				.catch(err => { reject(err.response) })	
			})
		},

		fetchFuelReport({getters, state}) {
			return new Promise((resolve, reject) => {
				axios.get(process.env.VUE_APP_REPORT_URL + "fuel/", {
					params: { 
						"station": getters.GET_ACTIVE_BRANCH.id, 
						"report_date": state.dataDate.toISOString().split("T")[0] 
					},
					headers: { 
						"Authorization" : "Token " + getters.GET_TOKEN 
					}
				})
				.then(res => { resolve(res.data) })
				.catch(err => { reject(err.response) })
			})
		}
	}
}