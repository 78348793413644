<template>
    <div>
        <div class="flex justify-end mb-8">
            <el-select 
                v-model="currentGroup" 
                placeholder="Select group" 
                size="small">
                <el-option
                v-for="group in GET_GROUPS.results"
                :key="group.name"
                :label="group.name"
                :value="group">
                </el-option>
            </el-select>
        </div>
        <ul class="divide-y">
            <li 
                v-for="content in GET_CONTENT_MODEL.results"
                :key="content.id"
                class="flex items-start space-x-2 w-full py-2">

                <article class="w-5/12 lg:w-1/5 capitalize">
                    <h4 class="font-medium mb-0.5">
                        {{content.name}}
                    </h4>
                    <p class="text-xs text-gray-400 divide-x-2">
                        <span class="pr-3">{{content.model}}</span>
                        <span class="pl-3">{{content.app_label}}</span>
                    </p>
                </article>

                <div class="w-7/5 lg:w-4/5 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
                    <div 
                        v-for="permission in content.permission_set" 
                        :key="permission.id" 
                        class="overflow-x-hidden">
                        <el-checkbox 
                            class="w-full"
                            :value="compareGroupPermissions(permission.codename)"
                            @change="onCheckboxChange(permission)" border>
                            <span class="text-xs capitalize">
                                {{permission.name.split(" ").slice(1).join(" ")}}
                            </span>
                        </el-checkbox>
                    </div>
                </div>
            </li>
        </ul>
        
        <div class="flex mt-4">
            <el-pagination 
                background layout="prev, pager, next" 
                :total="GET_CONTENT_MODEL.count" :page-size="50"
                @current-change="currentPageChanged">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "ContentModel",

    data() {
        return {
            currentGroup: null,
        }
    },

    watch: {
        currentGroup: function(newValue, oldValue) {
            if (newValue !== null || newValue !== undefined) {

            }
        }
    },

    computed: {
    	...mapGetters(["GET_CONTENT_MODEL", "GET_GROUPS"])
    },

    methods: {
        ...mapActions(["fetchContentModel", "fetchGroups", "handleGroupUpdate"]),

        compareGroupPermissions(permission) {
            if(this.currentGroup) {
                return this.currentGroup.permissions.some(item => item.codename === permission)
            }
            return false
        },

        onCheckboxChange(context) {
            if(this.currentGroup) {
                const exists = this.currentGroup.permissions.some(
                    item => item.codename === context.codename
                )
                if(exists) {
                    // remove from list
                    const index = this.currentGroup.permissions.findIndex(
                        item => item.id === context.id
                    )
                    this.currentGroup.permissions.splice(index, 1);
                } else {
                    // add permission to list
                    this.currentGroup.permissions.push(context)
                }

                const update = {
                    id: this.currentGroup.id,
                    name: this.currentGroup.name,
                    permissions: this.currentGroup.permissions.map(i => i.id)
                }
                this.handleGroupUpdate(update).then(data => {
                    this.$notify.success({ 
                        title: "Operation Successful", 
                        message: `Group ${data.name} updated`
                    });

                    this.fetchGroups();
                })
            }
        },

        currentPageChanged(value) {
            this.fetchContentModel({
                page: value
            })
        }
    }
}
</script>