<template>
    <div>
        <el-tabs v-model="activeTab">

            <el-tab-pane label="Invoice" name="invoice">
                <div class="flex justify-end space-x-4">
                    <button class="table-button" v-print="printOject">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2"></path>
                            <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4"></path>
                            <rect x="7" y="13" width="10" height="8" rx="2"></rect>
                        </svg>
                    </button>
                </div>

                <div id="perfoma-invoice" v-if="invoice">
                    <div class="flex justify-between items-end border-b space-x-4 py-4">
                        <img src="@/assets/aula-logo.png" class="w-20 h-auto" alt="logo">

                        <p class="text-3xl font-medium">Invoice</p>
                    </div>

                    <div class="flex justify-between items-center border-b space-x-4 py-4">
                        <div class="space-x-2">
                            <span class="font-medium">Date:</span>
                            <span>{{ invoice.invoice_date | africanDate }}</span>
                            <span>To</span>
                            <span>{{ invoice.due_date | africanDate }}</span>
                        </div>

                        <div class="space-x-2">
                            <span class="font-medium">Invoice No:</span>
                            <span>{{ invoice.code }}</span>
                        </div>
                    </div>

                    <div class="flex justify-between items-start space-x-4 py-4" v-if="invoice.customer">
                        <div class="text-sm">
                            <p class="font-medium text-base">Invoiced To:</p>
                            <p>{{ invoice.customer.name }}</p>
                            <p>{{ invoice.customer.address }}</p>
                            <p>{{ invoice.customer.district }} {{ invoice.customer.region }}</p>
                            <p class="space-x-2">
                                <span>{{ invoice.customer.phone }}</span>
                                <span v-if="invoice.customer.landline">/</span>
                                <span v-if="invoice.customer.landline">{{ invoice.customer.landline }}</span>
                            </p>
                            <p>{{ invoice.customer.email }}</p>
                        </div>

                        <div class="text-right text-sm">
                            <p class="font-medium text-base">Pay To:</p>
                            <p>{{ organization.name }}</p>
                            <p class="space-x-2">
                                <span>TIN: {{ organization.tin }}</span>
                                <span>|</span>
                                <span>VRN: {{ organization.vrn }}</span>
                            </p>
                            <p>{{ GET_ACTIVE_BRANCH.name }}</p>
                            <p>{{ GET_ACTIVE_BRANCH.address }}</p>
                            <p>P.O Box {{ organization.postal_code }} {{ GET_ACTIVE_BRANCH.district }} {{ GET_ACTIVE_BRANCH.region }}</p>
                            <p>{{ organization.phone }}</p>
                            <p>{{ organization.email }}</p>
                        </div>
                    </div>

                    <table class="overflow-x-auto w-full bg-white border-t border-blue-600 mt-2 mb-5">
                        <thead class="border-b bg-blue-600 text-white text-sm">
                            <tr class="divide-x divide-blue-100">
                                <th class="px-3 py-2 text-center text-xs font-medium uppercase">S/N</th>
                                <th class="px-3 py-2 text-center text-xs font-medium uppercase">Date</th>
                                <th class="px-3 py-2 text-center text-xs font-medium uppercase">Product/Service</th>
                                <th class="px-3 py-2 text-center text-xs font-medium uppercase">Description</th>
                                <th class="px-3 py-2 text-center text-xs font-medium uppercase">Quantity</th>
                                <th class="px-3 py-2 text-center text-xs font-medium uppercase">Unit Price</th>
                                <th class="px-3 py-2 text-center text-xs font-medium uppercase">Total</th>
                            </tr>
                        </thead>

                        <tbody class="border-b border-blue-100 text-xs divide-y divide-blue-100">
                            <tr class="divide-x divide-blue-100" 
                                v-for="(particular, index) in GET_PARTICULARS.results" 
                                :key="particular.id">
                                <td class="px-4 py-2 truncate text-center">{{ index + 1 }}</td>
                                <td class="px-4 py-2 truncate text-center">{{ particular.posting_date | africanDate }}</td>
                                <td class="px-4 py-2 truncate space-x-4">
                                    <span>{{ particular.item.name }}</span>
                                    <span v-if="particular.item.weight > 0">{{ particular.item.weight }} kg</span> 
                                    <span v-if="particular.item.volume > 0">{{ particular.item.volume }} ml</span>
                                </td>
                                <td class="px-4 py-2 truncate text-center">{{ particular.description }}</td>
                                <td class="px-4 py-2 truncate text-center">{{ particular.quantity }}</td>
                                <td class="px-4 py-2 truncate text-center">{{ particular.cost_per_unit | number('0,0') }}</td>
                                <td class="px-4 py-2 truncate text-center">{{ particular.amount | number('0,0') }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="flex justify-between space-x-4">
                        <div>
                            <p>Bank Details</p>
                            <p>NMB Bank</p>
                            <p class="space-x-2">
                                <span class="font-medium">Account Name:</span>
                                <span>AULA INVESTMENT CO LTD</span>
                            </p>
                            <p class="space-x-2">
                                <span class="font-medium">Account #:</span>
                                <span>43110006400</span>
                            </p>
                            <p class="space-x-2">
                                <span class="font-medium">Note:</span>
                                <span>Upon payment email us the transfer slip</span>
                            </p>
                        </div>

                        <div>
                            <p class="space-x-2">
                                <span class="font-medium">Sub-Total:</span>
                                <span v-if="invoice.amount !== null">{{ invoice.amount | number('0,0') }} TZS</span>
                                <span v-else>0</span>
                            </p>
                            <p class="space-x-2">
                                <span class="font-medium">C/F:</span>
                                <span v-if="invoice.carry_forward !== null">{{ invoice.carry_forward | number('0,0') }} TZS</span>
                                <span v-else>0</span>
                            </p>
                            <p class="space-x-2">
                                <span class="font-medium">Total:</span>
                                <span v-if="invoice.amount !== null">{{ invoice.amount | number('0,0') }} TZS</span>
                                <span v-else>0</span>
                            </p>
                        </div>
                    </div>

                </div>
            </el-tab-pane>
            
            <el-tab-pane label="Particulars" name="particulars">
                <div class="flex justify-end mb-5">
		            <button class="default-button" @click="showParticularDialog = true" v-if="addPermission">
                        New Particular
                    </button>
                </div>

                <el-dialog 
                    title="New Particular" 
                    :visible.sync="showParticularDialog" 
                    width="50%">

                    <el-form ref="form" :model="particular">
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                                <el-form-item label="Product" prop="product" size="mini" required>
                                    <el-select	v-model="particular.product" 
                                                filterable remote
                                                :remote-method="remoteFetchProduct" 
                                                placeholder="select product" 
                                                size="small" class="w-full">

                                        <el-option  v-for="item in GET_PRODUCTS.results" 
                                                    :label="item.name" 
                                                    :value="item.id" 
                                                    :key="item.id"
                                                    class="flex justify-between space-x-3">
                                            <span>{{ item.name }}</span>
                                            <span v-if="item.weight > 0">{{ item.weight }} kg</span> 
                                            <span v-if="item.volume > 0">{{ item.volume }} ml</span>
                                        </el-option>

                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :xs="24" :sm="12" :md="12" :lg="16" :xl="14">
                                <el-form-item label="Description" prop="description" size="mini">
                                    <el-input type="text" v-model="particular.description" size="small" clearable>
                                    </el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="4">
                                <el-form-item label="Quantity" prop="quantity" size="mini" required>
                                    <el-input-number :min="0" v-model="particular.quantity" size="small" class="w-full">
                                    </el-input-number>
                                </el-form-item>
                            </el-col>

                            <el-col :xs="24" :sm="9" :md="12" :lg="9" :xl="6">
                                <el-form-item label="Posting Date" prop="posting_date" size="mini" required>
                                    <el-date-picker v-model="particular.posting_date"
                                                    type="date" format="yyyy-MM-dd" 
                                                    placeholder="Pick a day" size="small">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>

                        </el-row>

                        <el-row :gutter="10">
                            <el-col :span="4">
                                <el-form-item>
                                    <el-button 
                                        @click="createInvoiceParticular('form')" :loading="loading"
                                        class="text-xs text-white hover:text-white bg-blue-500 hover:bg-blue-600 border border-blue-500 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                                        Create
                                    </el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>

                    </el-form>

                </el-dialog>

                <!-- Invoice Particulars Table -->
                <el-table
                    border size="mini" 
                    :data="GET_PARTICULARS.results" 
                    style="width: 100%">
                    <el-table-column type="selection" width="55"></el-table-column>

                    <el-table-column prop="posting_date" label="Date">
                        <template slot-scope="scope">{{ scope.row.posting_date | africanDate }}</template>
                    </el-table-column>

                    <el-table-column prop="item" label="Product">
                        <template slot-scope="scope">{{ scope.row.item.name }}</template>
                    </el-table-column>

                    <el-table-column prop="description" label="Description"></el-table-column>

                    <el-table-column prop="quantity" label="Quantity" align="center"></el-table-column>

                    <el-table-column prop="cost_per_unit" label="Unit Price" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.cost_per_unit | number('0,0') }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="amount" label="Total" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.amount | number('0,0') }}
                        </template>
                    </el-table-column>

                    <el-table-column label="Action" align="center" width="150">
                        <template slot-scope="scope">
                            <el-popconfirm
                                title="Are you sure to delete this?"
                                cancel-button-text="No, Thanks"
                                confirm-button-text="OK"
                                @confirm="deleteInvoiceParicular(scope.row)"
                                v-if="deletePermission">

                                <button class="table-button" slot="reference">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>

                </el-table>

            </el-tab-pane>
        </el-tabs>
        
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { errorRecursion } from "@/helpers/index.js"

export default {
    name: "InvoiceDetail",

    props: {
        invoiceId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            invoice: {},
            organization: {},
            printOject: {
                id: "perfoma-invoice",
                preview: false,
                popTitle: "Invoice"
            },
            activeTab: "invoice",
            showParticularDialog: false,
            loading: false,
            particular: {
                invoice: this.invoiceId,
                product: null,
                description: null,
                posting_date: null,
                quantity: 0,
            }
        }
    },

    computed: {
        ...mapGetters(["GET_ACTIVE_BRANCH", "GET_TOKEN", "GET_USER", "GET_PRODUCTS", "GET_PARTICULARS"]),

        addPermission() {
            return this.GET_USER?.permissions?.some(item => item === "finance.add_invoiceparticulars")
        },

        changePermission() {
            return this.GET_USER?.permissions?.some(item => item === "finance.change_invoiceparticulars")
        },

        deletePermission() {
            return this.GET_USER?.permissions?.some(item => item === "finance.delete_invoiceparticulars")
        },
    },

    methods: {
        ...mapActions([
            "handleInvoiceParticularCreate", 
            "handleInvoiceParticularDelete", 
            "fetchOrganization", 
            "fetchProducts", 
            "fetchInvoiceParticulars"
        ]),

        retrieveOrganizationDetail() {
            this.fetchOrganization(this.GET_ACTIVE_BRANCH.organization)
            .then(data => {
                this.organization = data
            })
        },

        fetchInvoiceDetail() {
            this.$http.get(process.env.VUE_APP_FINANCE_URL + "/invoice/" + this.invoiceId + "/", {
                headers: {
                    "Authorization": "Bearer " + this.GET_TOKEN.access
                }
            }).then(res => {
                this.invoice = res.data
            })
        },

        remoteFetchProduct(query) {
            if(query != "") {
                this.fetchProducts({"search": query})
                query = "";
            }
        },

        createInvoiceParticular(form) {
            this.particular.posting_date = new Date(this.particular.posting_date).toISOString().slice(0, 10)

            this.$refs[form].validate((valid) => {
                if(valid) {
                    this.loading = true;
                    this.handleInvoiceParticularCreate(this.particular).then(data => {
                        this.showParticularDialog = false
                        this.loading = false; 

                		this.$notify.success({ 
                            title: "Operation Successful", 
                            message: "Particular created" 
                        });

                        this.$refs[form].resetFields();
                        this.fetchInvoiceDetail()
                        this.fetchInvoiceParticulars({
                            filter: {
                                invoice: this.invoiceId
                            }
                        });
                    }).catch(error => {
                        this.loading = false;

                        let errorList = []
                        let message = ""
                        errorRecursion(error.data, errorList);
                        errorList.forEach((item) => {
                            message += "<li>" + item + "</li>"
                        })

                        this.$notify.error({ 
                            title: "Error",
                            dangerouslyUseHTMLString: true, 
                            message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                        });
                    }) 
                } else {
                    return false
                }
            })
        },

        deleteInvoiceParicular(item) {
            this.handleInvoiceParticularDelete(item).then(data => {
                this.fetchInvoiceDetail()
                this.fetchInvoiceParticulars({
                    filter: {
                        invoice: this.invoiceId
                    }
                });

				this.$notify.success({ 
					title: "Operation Successful", 
					message: "Particular deleted" 
				});
            }).catch(error => {
                let errorList = []
                let message = ""
                errorRecursion(error.data, errorList);
                errorList.forEach((item) => {
                    message += "<li>" + item + "</li>"
                })

                this.$notify.error({ 
                    title: "Error",
                    dangerouslyUseHTMLString: true, 
                    message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                });
            })
        }
    },

    mounted() {
        this.retrieveOrganizationDetail()
        this.fetchInvoiceDetail()
        this.fetchInvoiceParticulars({filter: { invoice: this.invoiceId} })
    }
}
</script>