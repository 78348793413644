<template>
    <div class="flex justify-end mb-5">
		<button class="default-button" @click="showMemberModal = true">New Member</button>

        <el-dialog
			title="Create Member"
			:visible.sync="showMemberModal"
			width="50%">

            <el-form ref="form" :model="member" :rules="rules">
                <el-row :gutter="10">
            
                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="First Name" prop="first_name" size="mini" required>
                            <el-input type="text" v-model="member.first_name" size="small" clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="Middle Name" prop="middle_name" size="mini" required>
                            <el-input type="text" v-model="member.middle_name" size="small" clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="Surname" prop="last_name" size="mini" required>
                            <el-input type="text" v-model="member.last_name" size="small" clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="Date of Birth" prop="dob" size="mini" required>
                            <el-input type="date" v-model="member.dob" size="small" clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="Gender" prop="gender" size="mini" required>
                            <el-select v-model="member.gender" size="small" class="w-full">
                                <el-option value="M" label="Male"></el-option>
                                <el-option value="F" label="Female"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="Martial Status" prop="martial_status" size="mini" required>
                            <el-select v-model="member.martial_status" size="small" class="w-full">
                                <el-option value="SINGLE" label="Single"></el-option>
                                <el-option value="MARRIED" label="Married"></el-option>
                                <el-option value="DIVORCED" label="Divorced"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="Phone" prop="phone" size="mini" required>
                            <el-input type="text" v-model="member.phone" placeholder="eg +255710202020" size="small" clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="Role" prop="groups" size="mini" required>
                            <el-select v-model="member.groups" size="small" class="w-full" multiple collapse-tags>
                                <el-option 
                                    :label="option.name" :value="option.id" 
                                    v-for="option in GET_GROUPS.results" 
                                    :key="option.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="Email" size="mini" required>
                            <el-input type="email" v-model="member.email" prop="email" size="small" clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="Password" size="mini" required>
                            <el-input type="password" v-model="member.password" prop="password" size="small" :show-password="true">
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <!-- <el-col :span="12">
                        <el-form-item label="Photo" size="mini">
                            <FileInput label="document-create" :files="member.photo" @upload-single="onImageUpload">
                                <template v-slot:placeholder>
                                    <span class="truncate text-xs" v-if="member.photo">
                                        {{ member.photo.name }}
                                    </span>
                                </template>
                            </FileInput>
                        </el-form-item>
                    </el-col> -->
                    
                </el-row>

                <el-row :gutter="10">
                    <el-col :span="4">
                        <el-form-item>
                            <el-button 
                                @click="createMember('form')" :loading="loading"
                                class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                                Create
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import FileInput from "@/components/common/FileInput"
import { mapActions, mapGetters } from "vuex";

export default {
    name: "MemberCreate",

    components: {
        FileInput
    },

    data() {
        return {
            showMemberModal: false,
            loading: false,
            member: {
                email: "",
                password: "",
                first_name: "",
                middle_name: "",
                last_name: "",
                dob: "",
                gender: "",
                martial_status: "",
                phone: "",
                groups: null,
                photo: null,
                is_active: true
            },
            rules: {
            	email: [
                    { required: true, message: "Please input email", trigger: "change" },
                ],
                password: [
                    { required: true, message: "Please input password", trigger: "change" },
                ],
                first_name: [
                    { required: true, message: "Please input first name", trigger: "change" },
                ],
                last_name: [
                    { required: true, message: "Please input last name", trigger: "change" },
                ],
                middle_name: [
                    { required: true, message: "Please input middle name", trigger: "change" },
                ],
                gender: [
                    { required: true, message: "Please input gender", trigger: "change" },
                ],
                dob: [
                    { required: true, message: "Please input dob", trigger: "change" },
                ],
                martial_status: [
                    { required: true, message: "Please input status", trigger: "change" },
                ],
                groups: [
                    { required: true, message: "Please input groups", trigger: "change" },
                ]
            }
        }
    },

    computed: {
        ...mapGetters(["GET_GROUPS"])
    },

    methods: {
        ...mapActions(["handleMemberCreate", "fetchMembers"]),

        onImageUpload(value) {
            this.member.photo = value
        },

        createMember(form) {
            if(this.member["photo"] == null || typeof this.member["photo"] == "string") {
                delete this.member["photo"]
            }

            if(this.member["signature"] == null || typeof this.member["signature"] == "string") {
                delete this.member["signature"]
            }

            const formData = new FormData();
            Object.keys(this.member).forEach(k => {
                if(Array.isArray(this.member[k])) {

                    this.member[k].forEach(subItem => formData.append(k, subItem));

                } else {
                    formData.append(k, this.member[k]);
                }
            })

            this.$refs[form].validate((valid) => {
    			if(valid) {
                    this.loading = true;
                    this.handleMemberCreate(formData).then(data => {
                        this.showMemberModal = false;
                        this.loading = false;
                        
                        this.$notify.success({ 
                            title: "Operation Successful", 
                            message: `Member ${data.email} created` 
                        });

                        this.$refs[form].resetFields()
                        this.fetchMembers();
                    }).catch(err => {
                        this.loading = false;
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>