<template>
    <div>
        <div class="flex justify-between items-center mb-5">
            <p class="font-medium">{{ supplier.name }}</p>

            <el-button icon="el-icon-message" circle></el-button>
        </div>

        <el-tabs v-model="tabName">
            <el-tab-pane label="General" name="general">
                <div>
                    <h2 class="text-sm font-medium my-5">Supplier Details</h2>

                    <div class="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5 text-xs">
                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 pb-2">
                            <p>Name</p>
                            <p>{{supplier.name}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 pb-2">
                            <p>Phone Number</p>
                            <p>{{supplier.phone}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 pb-2">
                            <p>Landline</p>
                            <p>{{supplier.landline}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 pb-2">
                            <p>E-mail</p>
                            <p>{{supplier.email}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 pb-2">
                            <p>Website</p>
                            <p>{{supplier.website}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 pb-2">
                            <p>Region</p>
                            <p>{{supplier.region}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 pb-2">
                            <p>District</p>
                            <p>{{supplier.district}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 pb-2">
                            <p>Ward</p>
                            <p>{{supplier.ward}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 pb-2">
                            <p>Address</p>
                            <p>{{supplier.address}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 pb-2">
                            <p>TIN</p>
                            <p>{{supplier.tin}}</p>
                        </div>

                        <div class="grid grid-cols-2 gap-5 border-b border-gray-100 pb-2">
                            <p>VRN</p>
                            <p>{{supplier.vrn}}</p>
                        </div>
                    </div>

                </div>
            </el-tab-pane>

            <el-tab-pane label="Transactions" name="transactions"></el-tab-pane>
        </el-tabs>

    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    name: "SupplierDetail",

    props: {
        supplierId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            supplier: {},
            tabName: "general"
        }
    },

    watch: {
        supplier: function(val) {
            // pass
        }
    },

    computed: {
        ...mapGetters(["GET_TOKEN", "GET_ENTRIES"])
    },

    methods: {
        fetchSupplierDetail() {
            this.$http.get(process.env.VUE_APP_SUPPLIER_URL + this.supplierId + "/", {
                headers: {
                    "Authorization": "Bearer " + this.GET_TOKEN.access
                }
            }).then(res => {
                this.supplier = res.data
            }).catch(err => {
                console.log(err)
            })
        },
    },

    mounted() {
        this.fetchSupplierDetail()
    }
}
</script>