<template>
    <div>
        <div class="flex justify-between space-x-4 items-center mb-2">
            <div class="w-full md:w-1/2 xl:w-1/4">
                <el-input v-model="search" size="mini" placeholder="Type to search" @input="searchSale" />
            </div>

            <button class="basic-button" v-if="selectedSales.length > 0" plain>
                <export-excel :data="selectedSales" :fields="exportFields" name="Sales.xls">
                    Export Excel
                </export-excel>
            </button>
        </div>

        <el-table   
            border size="mini" :data="GET_SALES.results" 
            @selection-change="handleSelectedSale" 
            @sort-change="OrderSaleData" 
            style="width: 100%">
                    
            <el-table-column type="expand">
                <template slot-scope="props">
                    <div class="grid grid-cols-6 font-medium border-b">
                        <span class="p-2 truncate">Product</span>
                        <span class="p-2 truncate text-center">Quantity</span>
                        <span class="p-2 truncate text-center">Price</span>
                        <span class="p-2 truncate text-center">Total</span>
                    </div>
                    <div class="grid grid-cols-6 hover:bg-gray-50" v-for="content in props.row.sales" :key="content.id">
                        <div class="p-2 truncate">
                            {{ content.item.name }}

                            <span v-if="content.item.weight != 0.0">
                                {{ content.item.weight }} kg
                            </span>
                            <span v-else-if="content.item.volume != 0.0">
                                {{ content.item.volume }} ml
                            </span>
                            <span v-else></span>
                        </div>
                        <div class="p-2 truncate text-center">{{ content.quantity }}</div>
                        <div class="p-2 truncate text-center">{{ content.sell_price }}</div>
                        <div class="p-2 truncate text-center">{{ content.total| number('0,0') }}</div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column prop="posting_date" label="Date" sortable="custom"></el-table-column>

            <el-table-column prop="invoice" label="Invoice" align="center" sortable="custom">
                <template slot-scope="scope" v-if="scope.row.invoice">
                    <router-link :to="{name: 'invoice-detail', params: { invoiceId: scope.row.invoice.id} }" class="text-blue-600">
                        {{ scope.row.invoice.code }}
                    </router-link>
                </template>
            </el-table-column>

            <el-table-column prop="description" label="Description" sortable="custom"></el-table-column>

            <el-table-column prop="amount" label="Amount (TZS)" align="center" sortable="custom">
                <template slot-scope="scope">
                    {{ scope.row.amount| number('0,0') }}
                </template>
            </el-table-column>

        </el-table>

        <div class="flex justify-end mt-4">
            <el-pagination 
                background layout="prev, pager, next" 
                :total="GET_SALES.count" :page-size="50"
                @current-change="currentPageChanged">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
    name: "SaleList",

    data() {
        return {
            search: "",
            sortField: "",
            selectedSales: []
        }
    },

    computed: {
        ...mapGetters(["GET_SALES"]),

        exportFields() {
            return {
                "Posting Date": "posting_date",
                "Reference": "reference",
                "Invoice":  {
                    field: "invoice",
                    callback: (value) => {
                        return value.code;
                    }
                },
                "Description": "description",
                "Amount": "amount",
                "Sales": {
                    field: "sales",
                    callback: (value) => {
                        let sales = [];
                        value.forEach(element => {
                            purchases.push({
                                "Quantity": element.quantity,
                                "Price": element.sell_price,
                                "Total": element.total
                            })
                        });
                        return {
                            sales
                        }
                         
                    }
                }

            }
        }
    },

    methods: {
        ...mapActions(["fetchSales"]),

        handleSelectedSale(val) {
            this.selectedSales = val;
        },

        OrderSaleData(val) {
            if(val.order == "descending") {
                this.sortField = "-" + val.prop
            } else {
                this.sortField = val.prop
            }

            this.fetchSales({"ordering": this.sortField})
        },

        searchSale() {
            this.fetchSales({"search": this.search})
        },

        currentPageChanged(value) {
            this.fetchSales({"page": value})
        }
    },

    mounted() {
        this.fetchSales()
    }
}
</script>