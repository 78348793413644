<template>
    <el-dialog 
        title="Edit Dipping Entry" 
        :visible.sync="showDippingStockModal" 
        width="50%">

        <el-form ref="form" :model="dippingStock">

            <el-row :gutter="10">
                <el-col :xs="24" :sm="12" :md="8">
                    <el-form-item label="Product" prop="product" size="mini" required>
                        <el-select v-model="dippingStock.product" placeholder="select product" size="small" class="w-full">
                            <el-option 
                                v-for="item in GET_PRODUCTS.results" 
                                :label="item.name" 
                                :value="item.id" 
                                :key="item.id">   
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="8">
                    <el-form-item label="Opening" prop="opening" size="mini" required>
                        <el-input-number :min="0" v-model="dippingStock.opening" size="small" class="w-full"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="8">
                    <el-form-item label="Fuel Added" prop="added" size="mini">
                        <el-input-number :min="0" v-model="dippingStock.added" size="small" class="w-full"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="8">
                    <el-form-item label="Dipping Readings" prop="dipping" size="mini" required>
                        <el-input-number :min="0" v-model="dippingStock.dipping" size="small" class="w-full"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="8">
                    <el-form-item label="Date" prop="posting_date" size="mini" required>
                        <el-input type="date" v-model="dippingStock.posting_date" size="small"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>

            <el-row :gutter="10">
                <el-col :span="4">
                    <el-form-item>
                        <el-button 
                            @click="updateDippingsStock" :loading="loading"
                            class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                            Save Changes
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
            
        </el-form>

    </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
    name: "DippingStockUpdate",

    props: {
        updateObject: {
            type: Object,
            required: true
        },

        showDippingStockModal: {
            type: Boolean,
            default: false
        }
    },

    data() {
    	return {
    		dippingStock: {},
            loading: false
    	}
    },

    watch: {
        updateObject: function(val) {
            this.dippingStock = val;
        },

        dippingStock: function(val) {
            val.product = val.product.id
        }
    },

    computed: {
    	...mapGetters(["GET_PRODUCTS"])
    },

    methods: {
        ...mapActions(["handleDippingStockUpdate", "fetchDippingStock", "fetchProducts"]),

        updateDippingsStock() {
            this.loading = true;
            this.handleDippingStockUpdate(this.dippingStock).then(data => {
                this.$emit("onSuccess");
                this.loading = false;

                this.$notify.success({ 
                    title: "Operation Successful", 
                    message: `Dipping Entry` 
                });

                this.fetchDippingStock();
            }).catch(err => {
                this.$emit("onSuccess")
                this.$notify.error({ 
                    title: "Operation Failed", 
                    message: `Fill correct important fields` 
                });
                this.loading = false;
            })
    	}
    },

    mounted() {
        this.fetchProducts({
            filter: {
                dipping: true
            }
        })
    }
}
</script>