<template>
    <div>
        <SupplierCreate v-if="addPermission" />

        <SupplierUpdate :showSupplierModal="updateModal" :updateObject="updateObject" @onSuccess="updateModal = false" />

        <div class="flex justify-between space-x-4 items-center mb-2">
            <div class="w-full md:w-1/2 xl:w-1/4">
                <el-input v-model="search" size="mini" placeholder="Type to search" @input="searchSupplier" />
            </div>

            <button class="basic-button" v-if="selectedSuppliers.length > 0">
                <export-excel :data="selectedSuppliers" :fields="exportFields" name="Suppliers.xls">
                    Export Excel
                </export-excel>
            </button>
        </div>

        <el-table 
            border size="mini" 
            :data="GET_SUPPLIERS.results" 
            @selection-change="handleSelectedSuppliers" 
            @sort-change="OrderSupplierData" 
            style="width: 100%">

            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column prop="name" label="Name" sortable="custom"></el-table-column>

            <el-table-column prop="phone" label="Phone" align="center" sortable="custom"></el-table-column>

            <el-table-column prop="email" label="Email" sortable="custom"></el-table-column>

            <el-table-column prop="address" label="Address" sortable="custom"></el-table-column>

            <el-table-column prop="tin" label="TIN #" align="center" sortable="custom"></el-table-column>

            <el-table-column label="Action" align="center" width="150">
				<template slot-scope="scope">
                    
                    <button 
                        class="table-button" @click="$router.push({name: 'supplier-detail', params: { supplierId: scope.row.id} })">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                    </button>

                    <button class="table-button" @click="updateObject = scope.row; updateModal = true" v-if="changePermission">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                    </button>

                    <el-popconfirm
                        title="Are you sure to delete this?"
                        cancel-button-text="No, Thanks"
                        confirm-button-text="OK"
                        @confirm="deleteSupplier(scope.row)"
                        v-if="deletePermission">

                        <button class="table-button" slot="reference">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </el-popconfirm>

				</template>
			</el-table-column>
        </el-table>

        <div class="flex justify-end mt-4">
            <el-pagination 
                background layout="prev, pager, next" 
                :total="GET_SUPPLIERS.count" :page-size="50"
                @current-change="currentPageChanged">
            </el-pagination>
        </div>
    </div>

</template>

<script>
import SupplierCreate from "@/components/suppliers/SupplierCreate"
import SupplierUpdate from "@/components/suppliers/SupplierUpdate"
import { mapActions, mapGetters } from "vuex"

export default {
    name: "SupplierList",

    components: {
        SupplierCreate,
        SupplierUpdate
    },

    data() {
        return {
            updateObject: {},
			updateModal: false,
            search: "",
            selectedSuppliers: [],
            sortField: ""
        }
    },

    computed: {
        ...mapGetters(["GET_SUPPLIERS", "GET_USER"]),

        addPermission() {
            return this.GET_USER.permissions.some(item => item === "suppliers.add_supplier")
        },

        changePermission() {
            return this.GET_USER.permissions.some(item => item === "suppliers.change_supplier")
        },

        deletePermission() {
            return this.GET_USER.permissions.some(item => item === "suppliers.delete_supplier")
        },

        export_fields() {
            return {
                "Name": "name",
                "Region": "region",
                "District": "district",
                "Address": "address",
                "Email": "email",
                "Phone": "phone",
                "Landline": "landline",
                "Website": "website",
                "TIN": "tin",
                "VRN": "vrn"
            }
        }
    },

    methods: {
        ...mapActions(["handleSupplierDelete", "fetchSuppliers"]),

        deleteSupplier(item) {
            this.handleSupplierDelete(item).then(data => {
				this.fetchSuppliers();

				this.$notify.success({ 
					title: "Operation Successful", 
					message: "Supplier deleted" 
				});
			})
        },

        handleSelectedSuppliers(val) {
            this.selectedSuppliers = val;
        },

        OrderSupplierData(val) {
            if(val.order == "descending") {
                this.sortField = "-" + val.prop
            } else {
                this.sortField = val.prop
            }

            this.fetchSuppliers({
                "ordering" : this.sortField
            })
        },

        searchSupplier() {
            this.fetchSuppliers({"search": this.search})
        },

        currentPageChanged(value) {
            this.fetchSuppliers({"page": value})
        }
    },

    mounted() {
        this.fetchSuppliers()
    }
}
</script>