<template>
    <div class="w-full flex">
        <div class="flex flex-col min-h-screen bg-blue-800 text-gray-200 py-4 px-4 lg:w-1/6 2xl:w-56">

            <img src="@/assets/aula-logo.png" class="w-26 h-auto self-center mb-4">

            <ul class="flex flex-col space-y-2 text-sm">
                <li 
                    v-for="(item, i) in menuItems" :key="i"
                    v-show="hasPermissions(GET_USER.permissions, item.permissions)">
                    <router-link 
                        :to="{name: item.hasSubMenu ? '' : item.router }" 
                        class="flex items-center space-x-4 p-1.5 rounded-md hover:bg-blue-600 hover:text-white"
                        :class="[$route.name == item.router && !item.hasSubMenu ? 'bg-blue-600 text-white' : '']"
                        @click.native="item.hasSubMenu ? routeDropdown = item.title : routeDropdown = item.router">
                        
                        <span v-html="item.icon"></span>
                        <span>{{ item.title }}</span>
                    </router-link>

                    <transition
                        enter-active-class="transform transition-all duration-300 ease-in-out"
                        leave-active-class="transform transition-all duration-300 ease-in-out"
                        enter-class="-translate-y-1/2 scale-y-0 opacity-0"
                        enter-to-class="translate-y-0 scale-y-100 opacity-100"
                        leave-class="translate-y-0 scale-y-100 opacity-100"
                        leave-to-class="-translate-y-1/2 scale-y-0 opacity-0">

                        <ul class="space-y-0.5 text-xs mt-1.5 ml-3 text-gray-200" v-show="item.hasSubMenu && routeDropdown == item.title">
                            <li 
                                v-for="(menu, j) in item.subMenu" :key="j"
                                v-show="hasPermissions(GET_USER.permissions, menu.permissions)">
                                <router-link 
                                    :to="{name: menu.router}"
                                    class="flex px-6 py-1.5 rounded-md hover:bg-blue-600 hover:text-white"
                                    :class="[$route.name == menu.router ? 'bg-blue-600 text-white' : '']">
                                    <span>{{ menu.title }}</span>
                                </router-link> 
                            </li>  
                        </ul>

                    </transition>

                </li>
            </ul>
        </div>

        <!-- Content Section -->
        <div class="flex flex-col flex-grow min-h-full lg:w-5/6 2xl:w-auto pb-4 text-gray-700">

            <div class="flex items-center justify-between border-b border-gray-100 py-2 px-8 mb-4">
                <div class="text-xl">
                    Welcome, <small>{{ GET_USER.first_name }} {{ GET_USER.last_name }}</small>
                </div>

                <div class="flex items-center space-x-4">
                    <router-link 
                        :to="{name: 'members'}" 
                        class="cursor-pointer p-1.5 border-2 hover:border-persian-600 rounded-full">

                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 hover:text-gray-600" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                           <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                           <circle cx="12" cy="7" r="4"></circle>
                           <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                        </svg>
                    </router-link>

                    <span
                        @click="signOut" 
                        class="cursor-pointer p-1.5 border-2 hover:border-persian-600 rounded-full">

                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 hover:text-gray-700" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                           <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                           <path d="M7 6a7.75 7.75 0 1 0 10 0"></path>
                           <line x1="12" y1="4" x2="12" y2="12"></line>
                        </svg>
                    </span>
                </div>
            </div>

            <router-view class="px-8"></router-view>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { navigation } from "@/router/navigations.js"

export default {
    name: "DefaultLayout",

    data() {
        return {
            routeDropdown: ""
        }
    },

    watch: {
        "GET_ACTIVE_BRANCH": function(newValue, oldValue) {
            if(newValue !== null || newValue !== undefined) {
                this.fetchContentModel();
                this.fetchAccounts();
                this.fetchCustomers();
                this.fetchDippingStock();
                this.fetchDeliveries();
                this.fetchEntries();
                this.fetchGroups();
                this.fetchInvoices();
                this.fetchMembers();
                this.fetchPermissions();
                this.fetchProducts();
                this.fetchProductInventory();
                this.fetchPumps();
                this.fetchPumpReadings();
                this.fetchSuppliers();
                this.fetchReceipts();
            } else {
                this.fetchUser();
            }
            
        }
    },

    computed: {
        ...mapGetters(["GET_USER", "GET_ACTIVE_BRANCH"]), 

        menuItems() {
            return navigation
        }
    },

    methods: {
        ...mapActions([
            "fetchUser",
            "fetchAccounts",
            "fetchCustomers",
            "fetchContentModel",
            "fetchDippingStock",
            "fetchDeliveries",
            "fetchEntries",
            "fetchGroups",
            "fetchInvoices",
            "fetchMembers",
            "fetchPermissions",
            "fetchProducts",
            "fetchProductInventory",
            "fetchPumps",
            "fetchPumpReadings",
			"fetchSuppliers",
            "fetchReceipts",
            "signOut"
        ]),

        hasPermissions(user_permissions=[], permissions=[]) {
            if(permissions.length == 0) {
                return true
            }
            
            return user_permissions.some(item => permissions.includes(item) )
        }
    },

    mounted() {
        this.fetchUser();
    }
}
</script>