import axios from "axios"

export default {
	state: {
        invoices: {},
        particulars: {}
    },

	getters: {
        GET_INVOICES(state) {
            return state.invoices
        },

        GET_PARTICULARS(state) {
            return state.particulars
        }
    },

	mutations: {
        SET_INVOICES(state, payload) {
            state.invoices = payload
        },

        SET_PARTICULARS(state, payload) {
            state.particulars = payload
        }
    },

    actions: {
        fetchInvoices({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_FINANCE_URL + "/invoice/", {
                params: Object.assign({
                    "ordering": payload.ordering, 
                    "page": payload.page, 
                    "search": payload.search,
                    "branch": getters.GET_ACTIVE_BRANCH.id 
                }, payload.filter),
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_INVOICES", res.data);
            })
            .catch((err) => {
                // pass
            })
        },

        handleInvoiceCreate({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_FINANCE_URL + "/invoice/", payload, 
                {
                    params: {
                        "branch": getters.GET_ACTIVE_BRANCH.id
                    },
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleInvoiceUpdate({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_FINANCE_URL + "/invoice/" + payload.id + "/", payload, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleInvoiceDelete({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_FINANCE_URL + "/invoice/" + payload.id + "/", {"trashed": true}, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        fetchInvoiceParticulars({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_FINANCE_URL + "/invoice-items/", {
                params: Object.assign({
                    "ordering": payload.ordering, 
                    "page": payload.page, 
                    "search": payload.search,
                    "branch": getters.GET_ACTIVE_BRANCH.id 
                }, payload.filter),
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_PARTICULARS", res.data);
            })
            .catch((err) => {
                reject(err.response);
            })
        },

        handleInvoiceParticularCreate({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_FINANCE_URL + "/invoice-items/", payload, 
                {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleInvoiceParticularDelete({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.delete(process.env.VUE_APP_FINANCE_URL + "/invoice-items/" + payload.id + "/", 
                {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },
    }
}