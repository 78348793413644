<template>
    <div class="flex justify-end mb-5">
		<button 
            class="default-button" 
            @click="showProductInventoryModal = true">
            New Inventory
        </button>

        <el-dialog 
            title="New Inventory" 
            :visible.sync="showProductInventoryModal" 
            width="50%">

            <table class="overflow-x-auto w-full bg-white border-0 mb-5">
                <thead class="bg-blue-50 text-gray-600 text-sm">
                    <tr class="divide-x divide-gray-200">
                        <th class="px-3 py-2 text-center text-xs font-medium uppercase">Product</th>
                        <th class="px-3 py-2 text-center text-xs font-medium uppercase">Quantity</th>
                        <th class="px-3 py-2 text-center text-xs font-medium uppercase">Action</th>
                    </tr>
                </thead>

                <tbody class="text-xs divide-y divide-gray-200">
                    <tr v-for="(item, index) in initialStock" 
                        :key="index">

                        <td class="px-4 py-2 truncate">
                            <el-select v-model="item.product" placeholder="select product" size="small" class="w-full">
                                <el-option 
                                    v-for="product in noInventoryProducts" 
                                    :label="product.name" 
                                    :value="product.id" 
                                    :key="product.id"
                                    class="flex justify-between space-x-3">
                                    <span>{{ product.name }}</span>
                                    <span v-if="product.weight > 0">{{ product.weight }} kg</span> 
                                    <span v-if="product.volume > 0">{{ product.volume }} ml</span>  
                                </el-option>
                            </el-select>
                        </td>

                        <td class="px-4 py-2 truncate">
                            <el-input-number 
                                :min="0" 
                                v-model="item.quantity" 
                                size="small" 
                                class="w-full">
                            </el-input-number>
                        </td>

                        <td class="flex justify-center px-4 py-2 truncate">
                            <button 
                                class="table-button" 
                                @click="removeInventoryItem(index)">
                                <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <el-row type="flex" justify="space-between">
                <el-button 
                    @click="addInventoryItem" 
                    size="mini" plain>
                    New Row
                </el-button>

                <el-button 
                    type="primary" 
                    @click="createProductInventory" 
                    size="mini" 
                    v-if="initialStock.length > 0">
                    Create
                </el-button>
            </el-row> 

        </el-dialog>

    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
    name: "ProductInventoryCreate",

    data() {
        return {
            loading: false,
            showProductInventoryModal: false,
            initialStock: [
                { product: null, quantity: 0 }
            ],
            noInventoryProducts: "",
        }
    },

    computed: {
        ...mapGetters(["GET_ACTIVE_BRANCH", "GET_TOKEN"])
    },

    methods: {
        ...mapActions(["handleProductInventoryCreate", "fetchProductInventory"]),

        addInventoryItem() {
            this.initialStock.push({ product: null, quantity: 0 });
        },

        removeInventoryItem(index) {
            this.initialStock.splice(index, 1);
        },

        createProductInventory() {
            this.handleProductInventoryCreate(this.initialStock).then(data => {
                this.showProductInventoryModal = false;
                this.loading = false;

                this.$notify.success({ 
                    title: "Operation Successful", 
                    message: `Inventory items created` 
                });

                this.fetchProductInventory();
                this.fetchNoInventoryItem();
            }).catch(err => {
                this.showProductInventoryModal = false;
                this.loading = false;

                this.$notify.error({
                    title: "Operation Failed", 
                    message: `Fill correct important fields` 
                });
            })
        },

        fetchNoInventoryItem() {
            this.$http.get(process.env.VUE_APP_INVENTORY_URL + "/product/empty/", {
                params: {
                    "branch": this.GET_ACTIVE_BRANCH.id
                },
                headers: {
                    "Authorization": "Bearer " + this.GET_TOKEN.access
                }
            })
            .then(res => {
                this.noInventoryProducts = res.data;
            })
            .catch(err => {
                this.$notify.error({
                    title: "Operation Failed", 
                    message: `Fill correct important fields` 
                });
            })
        }
    },

    mounted() {
        setInterval(() => this.fetchNoInventoryItem(), 10000);
    }
}
</script>