<template>
    <div>
        <div class="flex justify-between space-x-4 items-center my-5">
            <h2>Trial Balance Report</h2>

            <div class="flex space-x-2">
                <el-button v-if="selectedTrialBalance.length > 0" size="mini" plain>
                    <export-excel 
                        :data="exportData" 
                        :fields="exportFields" 
                        :name="`Trial Balance `+ currentDate + `.xls`">
                        Export Excel
                    </export-excel>
                </el-button>

                <el-date-picker
                    v-model="trialBalanceDate"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="Start Date"
                    end-placeholder="End date"
                    size="mini">
                </el-date-picker>
            </div>
        </div>

        <el-table	
            border size="mini" 
            :data="trialBalance"
            style="width: 100%"
            @selection-change="handleSelectedTrialBalance">

            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column prop="code" label="Account Number"></el-table-column>

            <el-table-column prop="name" label="Account Name"></el-table-column>

            <el-table-column prop="category" label="Debit (TZS)" align="center">
                <template slot-scope="scope">
                    <span 
                        v-if="scope.row.category == 'CURRENT-ASSETS' || 
                        scope.row.category == 'FIXED-ASSETS' ||
                        scope.row.category == 'EXPENDITURE' ">
                        {{ scope.row.balance | number('0,0') }}
                    </span>
                </template>
            </el-table-column>

            <el-table-column prop="category" label="Credit (TZS)" align="center">
                <template slot-scope="scope">
                    <span 
                        v-if="scope.row.category == 'CURRENT-LIABILITY' || 
                        scope.row.category == 'LONG-TERM-LIABILITY' ||
                        scope.row.category == 'REVENUE' || 
                        scope.row.category == 'EQUITY' ">
                        {{ scope.row.balance | number('0,0') }}
                    </span>
                </template>
            </el-table-column>

            <template slot="append">
                <div class="flex space-x-6 md:space-x-10 p-4 w-full bg-gray-50">
                    <div class="w-1/2 text-center font-medium">Total</div>

                    <div class="w-1/2 grid grid-cols-2 text-center font-medium">
                        <span>{{ debitTotal | number('0,0') }}</span>

                        <span>{{ creditTotal | number('0,0') }}</span>
                    </div>
                </div>
            </template>
        </el-table>

    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { DateTime } from "luxon"

export default {
    name: "TrialBalance",

    data() {
        return {
            currentDate: DateTime.now().toFormat("LLLL - yyyy"),
            trialBalanceDate: null,
            trialBalance: [],
            selectedTrialBalance: [],
            debitTotal: 0,
            creditTotal: 0,
            exportData: []
        }
    },

    watch: {
        trialBalanceDate: function(val) {
            if (val) {
                let min_date = DateTime.fromFormat(val[0].toDateString(), "EEE MMM dd yyyy").toISODate();

                let max_date = DateTime.fromFormat(val[1].toDateString(), "EEE MMM dd yyyy").toISODate();

                this.fetchTrialBalance(min_date, max_date);
            }
        },

        trialBalance: function(val) {
            // Calculate Debit Total and Credit Total
            this.debitTotal = this.creditTotal = 0;
            
            val.forEach(element => {
                element.category == "CURRENT-ASSETS" ||
                element.category == "FIXED-ASSETS" || 
                element.category == "EXPENDITURE" ? 
                this.debitTotal += element.balance : this.creditTotal += element.balance
            });
        },

        selectedTrialBalance: function(val) {
            this.exportData = [];
            
            val.forEach(element => {
                if(
                    element.category == "EXPENDITURE" || 
                    element.category == "CURRENT-ASSETS" ||
                    element.category == "FIXED-ASSETS") {

                    this.exportData.push({
                        "code": element.code, 
                        "name": element.name, 
                        "debit": element.balance, 
                        "credit": ""
                    });
                } else {
                    this.exportData.push({
                        "code": element.code, 
                        "name": element.name, 
                        "debit": "", 
                        "credit": element.balance
                    });
                }
            })

            return this.exportData
        }
    },

    computed: {
        ...mapGetters(["GET_ACTIVE_BRANCH", "GET_TOKEN"]),

        exportFields() {
            return {
                "Account Number": "code",
                "Account Name": "name",
                "Debit": "debit",
                "Credit": "credit"
            }
        }
    },

    methods: {
        fetchTrialBalance(min=null, max=null) {
            if(min !== null && max !== null) {
                this.currentDate = DateTime.fromISO(min).toFormat("dd-LLLL-yyyy") + ' to ' + DateTime.fromISO(max).toFormat("dd-LLLL-yyyy");
            }

            this.$http.get(process.env.VUE_APP_REPORT_URL + "trial-balance/", {
                params: Object.assign({
                        "branch": this.GET_ACTIVE_BRANCH.id
                        }, this.trialBalanceDate !== null ? { "min_date": min, "max_date": max } : {}),
                headers: {
                    "Authorization": "Bearer " + this.GET_TOKEN.access
                }
            }).then(res => {
                this.trialBalance = res.data;
            })
        },

        handleSelectedTrialBalance(val) {
            this.selectedTrialBalance = val;
        },
    },

    mounted() {
        this.fetchTrialBalance()
    }
}
</script>