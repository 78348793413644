<template>
    <div>
        <EntryCreate v-if="addPermission" />

        <div class="flex justify-between space-x-4 items-center mb-2">
            <div class="w-full md:w-1/2 xl:w-1/4">
                <el-input v-model="search" size="mini" placeholder="Type to search" @input="searchEntry" />
            </div>

            <button class="basic-button" v-if="selectedEntries.length > 0" plain>
                <export-excel :data="selectedEntries" :fields="exportFields" name="Entries.xls">
                    Export Excel
                </export-excel>
            </button>
        </div>

        <el-table 
            border size="mini" 
            :data="GET_ENTRIES.results" 
            @selection-change="handleSelectedEntry" 
            @sort-change="orderEntryData" 
            style="width: 100%">
            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column prop="posting_date" label="Date" sortable="custom"></el-table-column>

            <el-table-column prop="category" label="Category" align="center" sortable="custom"></el-table-column>

            <el-table-column prop="nodes[0].account" label="Account(DR)" align="center">
                <template slot-scope="scope">
                    <router-link 
                        :to="{
                            name: 'account-detail', 
                            params: { accountId: scope.row.nodes[0].account.id} 
                        }" 
                        class="text-blue-600">
                        {{ scope.row.nodes[0].account.code }}
                    </router-link>
                </template>
            </el-table-column>

            <el-table-column prop="description" label="Description"></el-table-column>

            <el-table-column prop="nodes[1].account" label="Account(CR)" align="center">
                <template slot-scope="scope">
                    <router-link 
                    :to="{ 
                        name: 'account-detail', 
                        params: { accountId: scope.row.nodes[1].account.id} 
                        }" 
                        class="text-blue-600">
                        {{ scope.row.nodes[1].account.code }}
                    </router-link>
                </template>
            </el-table-column>

            <el-table-column prop="amount" label="Amount" align="center">
                <template slot-scope="scope">
                    {{ scope.row.amount | number('0,0') }}
                </template>
            </el-table-column>

            <el-table-column label="Action" align="center" width="150">
				<template slot-scope="scope">

                    <el-popconfirm
                        title="Are you sure to delete this?"
                        cancel-button-text="No, Thanks"
                        confirm-button-text="OK"
                        @confirm="deleteEntry(scope.row.id)"
                        v-if="deletePermission">

                        <button class="table-button" slot="reference">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </el-popconfirm>

				</template>
			</el-table-column>
        </el-table>

        <div class="flex justify-end mt-4">
            <el-pagination 
                background layout="prev, pager, next" 
                :total="GET_ENTRIES.count" :page-size="50"
                @current-change="currentPageChanged">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import EntryCreate from "@/components/entry/EntryCreate"
import { mapActions, mapGetters } from "vuex"
import { errorRecursion } from "@/helpers/index.js"

export default {
    name: "EntryList",

    components: {
        EntryCreate
    },

    data() {
        return {
            updateObject: {},
			updateModal: false,
            search: "",
            selectedEntries: [],
            sortField: "",
        }
    },

    computed: {
        ...mapGetters(["GET_ENTRIES", "GET_USER"]),

        addPermission() {
            return this.GET_USER.permissions.some(item => item === "finance.add_entry")
        },

        changePermission() {
            return this.GET_USER.permissions.some(item => item === "finance.change_entry")
        },

        deletePermission() {
            return this.GET_USER.permissions.some(item => item === "finance.delete_entry")
        },

        exportFields() {
            return {
                "Date": "posting_date",
                "Category": "category",
                "Reference": "reference",
                "Description": "description",
                "Amount": "amount", 
            }
        }
    },

    methods: {
        ...mapActions(["handleEntryDelete", "fetchEntries"]),

        deleteEntry(item) {
            this.handleEntryDelete(item).then(data => {
				this.fetchEntries();

				this.$notify.success({ 
					title: "Operation Successful", 
					message: "Entry deleted" 
				});
			}).catch(error => {
                let errorList = []
                let message = ""
                errorRecursion(error.data, errorList);
                errorList.forEach((item) => {
                    message += "<li>" + item + "</li>"
                })

                this.$notify.error({ 
                    title: "Error",
                    dangerouslyUseHTMLString: true, 
                    message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                });
            })
        },

        handleSelectedEntry(val) {
            this.selectedEntries = val;
        },

        orderEntryData(val) {
            if(val.order == "descending") {
                this.sortField = "-" + val.prop
            } else {
                this.sortField = val.prop
            }

            this.fetchEntries({
                ordering: this.sortField
            })
        },

        searchEntry() {
            this.fetchEntries({
                search: this.search
            })
        },

        currentPageChanged(value) {
            this.fetchEntries({
                page: value
            })
        }
    },

    mounted() {
        this.fetchEntries()
    }
}
</script>