
export const navigation = [
    {
        title: "Dashboard",
        router: "dashboard",
        icon: `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <rect x="4" y="4" width="6" height="6" rx="1"></rect>
                <rect x="14" y="4" width="6" height="6" rx="1"></rect>
                <rect x="4" y="14" width="6" height="6" rx="1"></rect>
                <rect x="14" y="14" width="6" height="6" rx="1"></rect>
            </svg>
        `,
        permissions: [],
        hasSubMenu: false,
        subMenu: []
    },
    {
        title: "Products",
        router: "products",
        icon: `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"></polyline>
                <line x1="12" y1="12" x2="20" y2="7.5"></line>
                <line x1="12" y1="12" x2="12" y2="21"></line>
                <line x1="12" y1="12" x2="4" y2="7.5"></line>
                <line x1="16" y1="5.25" x2="8" y2="9.75"></line>
            </svg>
        `,
        permissions: ["inventory.view_product", "inventory.view_productinventory"],
        hasSubMenu: false,
        subMenu: []
    },
    {
        title: "Pump",
        router: "pumps",
        icon: `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M14 11h1a2 2 0 0 1 2 2v3a1.5 1.5 0 0 0 3 0v-7l-3 -3"></path>
                <path d="M4 20v-14a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v14"></path>
                <line x1="3" y1="20" x2="15" y2="20"></line>
                <path d="M18 7v1a1 1 0 0 0 1 1h1"></path>
                <line x1="4" y1="11" x2="14" y2="11"></line>
            </svg>
        `,
        permissions: ["inventory.view_pump", "inventory.view_pumpreading", "inventory.view_dippingstock"],
        hasSubMenu: false,
        subMenu: []
    },
    {
        title: "Customers Account",
        router: "customers",
        icon: `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
            </svg>
        `,
        permissions: ["customers.view_customer"],
        hasSubMenu: false,
        subMenu: []
    },
    {
        title: "Invoice",
        router: "invoice",
        icon: `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
                <line x1="9" y1="7" x2="10" y2="7"></line>
                <line x1="9" y1="13" x2="15" y2="13"></line>
                <line x1="13" y1="17" x2="15" y2="17"></line>
            </svg>
        `,
        permissions: ["finance.view_invoice"],
        hasSubMenu: false,
        subMenu: []
    },
    {
        title: "Receipts",
        router: "receipts",
        icon: `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2"></path>
            </svg>
        `,
        permissions: ["finance.view_receipts"],
        hasSubMenu: false,
        subMenu: []
    },
    {
        title: "Delivery",
        router: "delivery",
        icon: `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="7" cy="17" r="2"></circle>
                <circle cx="17" cy="17" r="2"></circle>
                <path d="M5 17h-2v-4m-1 -8h11v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5"></path>
                <line x1="3" y1="9" x2="7" y2="9"></line>
            </svg>
        `,
        permissions: ["finance.view_delivery"],
        hasSubMenu: false,
        subMenu: []
    },
    {
        title: "Suppliers",
        router: "suppliers",
        icon: `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M2 3h1a2 2 0 0 1 2 2v10a2 2 0 0 0 2 2h15"></path>
                <rect x="9" y="6" width="10" height="8" rx="3"></rect>
                <circle cx="9" cy="19" r="2"></circle>
                <circle cx="18" cy="19" r="2"></circle>
            </svg>
        `,
        permissions: ["suppliers.view_supplier"],
        hasSubMenu: false,
        subMenu: []
    },
    {
        title: "Transactions",
        router: "",
        icon: `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="12" cy="12" r="9"></circle>
                <path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1 -1.8 -1"></path>
                <path d="M12 6v2m0 8v2"></path>
            </svg>
        `,
        permissions: [],
        hasSubMenu: true,
        subMenu: [
            { title: "Entry", router: "entry", permissions: ["finance.view_entry"]},
            // { title: "Expenses", router: "groups" },
            { title: "Purchases", router: "purchases", permissions: ["finance.view_entry"] },
            { title: "Sales", router: "sales", permissions: ["finance.view_entry"] }
        ]
    },
    {
        title: "Chart of Accounts",
        router: "accounts",
        icon: `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="3" y1="21" x2="21" y2="21"></line>
                <line x1="3" y1="10" x2="21" y2="10"></line>
                <polyline points="5 6 12 3 19 6"></polyline>
                <line x1="4" y1="10" x2="4" y2="21"></line>
                <line x1="20" y1="10" x2="20" y2="21"></line>
                <line x1="8" y1="14" x2="8" y2="17"></line>
                <line x1="12" y1="14" x2="12" y2="17"></line>
                <line x1="16" y1="14" x2="16" y2="17"></line>
            </svg>
        `,  
        permissions: ["finance.view_account"],
        hasSubMenu: false,
        subMenu: []
    },
    {
        title: "Reports",
        router: "reports",
        icon: `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="7" cy="7" r="4"></circle>
                <path d="M7 3v4h4"></path>
                <line x1="9" y1="17" x2="9" y2="21"></line>
                <line x1="17" y1="14" x2="17" y2="21"></line>
                <line x1="13" y1="13" x2="13" y2="21"></line>
                <line x1="21" y1="12" x2="21" y2="21"></line>
            </svg>
        `,  
        permissions: [],
        hasSubMenu: false,
        subMenu: []
    },
    {
        title: "Members",
        router: "members",
        icon: `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="12" cy="7" r="4"></circle>
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
            </svg>
        `,
        permissions: ["management.view_customuser"],
        hasSubMenu: false,
        subMenu: []
    },
    {
        title: "Settings",
        router: "",
        icon: `
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
        `,
        permissions: [],
        hasSubMenu: true,
        subMenu: [
            // { title: "Branch", router: "settings", permissions: [] },
            { title: "Groups", router: "groups", permissions: ["auth.view_group"] },
            { title: "Permissions", router: "permissions", permissions: ["auth.view_permission"] }
        ]
    }
]