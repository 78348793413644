<template>
    <el-dialog
        title="Edit Supplier"
        :visible.sync="showSupplierModal"
        width="50%">

        <el-form ref="form" :model="supplier">

            <el-row :gutter="10">
                <el-col :sm="12" :md="12" :lg="12" :xl="8">
                    <el-form-item label="Name" prop="name" size="mini" required>
                        <el-input type="text" v-model="supplier.name"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="12" :lg="12" :xl="8">
                    <el-form-item label="Email" prop="email" size="mini">
                        <el-input type="email" v-model="supplier.email"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="8" :lg="8" :xl="8">
                    <el-form-item label="Phone" prop="phone" size="mini">
                        <el-input type="text" v-model="supplier.phone" placeholder="eg +255710202020"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="8" :lg="8" :xl="8">
                    <el-form-item label="LandLine" prop="landline" size="mini">
                        <el-input type="text" v-model="supplier.landline" placeholder="eg +255710202020"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="8" :lg="8" :xl="8">
                    <el-form-item label="Region" prop="region" size="mini" required>
                        <el-input type="text" v-model="supplier.region"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="12" :lg="6" :xl="8">
                    <el-form-item label="District" prop="district" size="mini" required>
                        <el-input type="text" v-model="supplier.district"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="12" :lg="12" :xl="8">
                    <el-form-item label="Address" prop="address" size="mini">
                        <el-input type="text" v-model="supplier.address"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="12" :lg="6" :xl="8">
                    <el-form-item label="Website" prop="website" size="mini">
                        <el-input type="text" v-model="supplier.website"></el-input>
                    </el-form-item>
                </el-col>

                 <el-col :sm="12" :md="12" :lg="8" :xl="8">
                    <el-form-item label="TIN" prop="tin" size="mini">
                        <el-input type="text" v-model="supplier.tin"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :sm="12" :md="12" :lg="8" :xl="8">
                    <el-form-item label="VRN" prop="vrn" size="mini">
                        <el-input type="text" v-model="supplier.vrn"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="10">
                <el-col :span="4">
                    <el-form-item>
                        <el-button 
                            @click="updateSupplier" :loading="loading"
                            class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                            Save Changes
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>
    </el-dialog>
</template>

<script>
import { mapActions } from "vuex"

export default {
    name: "SupplierUpdate",

    props: {
        updateObject: {
            type: Object,
            required: true
        },

        showSupplierModal: {
            type: Boolean,
            default: false
        }
    },

    data() {
    	return {
    		supplier: {},
            loading: false
    	}
    },

    watch: {
        updateObject: function(val) {
            this.supplier = val;
        }
    },

    methods: {
        ...mapActions(["handleSupplierUpdate", "fetchSuppliers"]),

        updateSupplier() {
            this.loading = true;

            this.handleSupplierUpdate(this.supplier).then(data => {
                this.$emit("onSuccess")
                this.loading = false;

                this.$notify.success({ 
                    title: "Operation Successful", 
                    message: `Supplier ${data.name} updated` 
                });

                this.fetchSuppliers();
            }).catch(err => {
                this.$emit("onSuccess")
                this.loading = false;

                this.$notify.error({ 
                    title: "Operation Failed", 
                    message: `Fill correct important fields` 
                });
                
            })
        }

    }
}
</script>