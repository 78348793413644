<template>
    <div>
        <DippingStockUpdate :showDippingStockModal="updateModal" :updateObject="updateObject" @onSuccess="updateModal = false" />

        <div class="flex justify-between space-x-4 items-center mb-2">
            <div class="w-full md:w-1/2 xl:w-1/4">
                <el-input v-model="search" size="mini" placeholder="Type to search" @input="searchDippingStock" />
            </div>

            <button class="basic-button" v-if="selectedDippingStock.length > 0" plain>
                <export-excel :data="selectedDippingStock" :fields="exportFields" name="Fuel Stock.xls">
                    Export Excel
                </export-excel>
            </button>
        </div>

        <el-table 
            border size="mini" 
            :data="GET_DIPPING_STOCK.results" 
            @selection-change="handleSelectedDippingStock" 
            @sort-change="OrderDippingStockData" 
            style="width: 100%">

            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column prop="posting_date" label="Date" sortable="custom"></el-table-column>

            <el-table-column prop="product" label="Product" align="center" sortable="custom">
                <template slot-scope="scope">{{ scope.row.product.name }}</template>
            </el-table-column>

            <el-table-column prop="opening" label="Opening" align="center" sortable="custom"></el-table-column>

            <el-table-column prop="added" label="Added" align="center" sortable="custom"></el-table-column>

            <el-table-column prop="sold" label="Sold" align="center" sortable="custom"></el-table-column>

            <el-table-column prop="dipping" label="Dipping" align="center" sortable="custom"></el-table-column>

            <el-table-column prop="actual" label="Actual" align="center" sortable="custom"></el-table-column>

            <el-table-column prop="variation" label="Variation" align="center" sortable="custom"></el-table-column>

            <el-table-column label="Action" align="center" width="150">
				<template slot-scope="scope">
                    <button class="table-button" @click="updateObject = scope.row; updateModal = true" v-if="changePermission">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                    </button>

                    <el-popconfirm
                        title="Are you sure to delete this?"
                        cancel-button-text="No, Thanks"
                        confirm-button-text="OK"
                        @confirm="deleteDippingStock(scope.row)"
                        v-if="deletePermission">

                        <button class="table-button" slot="reference">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </el-popconfirm>

				</template>
            </el-table-column>
        </el-table>

        <div class="flex justify-end mt-4">
            <el-pagination 
                background layout="prev, pager, next" 
                :total="GET_DIPPING_STOCK.count" :page-size="50"
                @current-change="currentPageChanged">
            </el-pagination>
        </div>

    </div>
</template>

<script>
import DippingStockUpdate from "@/components/fuel/DippingStockUpdate"
import { mapActions, mapGetters } from "vuex"

export default {
    name: "DippingStockList",

    components: {
        DippingStockUpdate,
    },

    data() {
        return {
            updateObject: {},
			updateModal: false,
            search: "",
            sortField: "",
            selectedDippingStock: [],
        }
    },

    computed: {
        ...mapGetters(["GET_USER", "GET_DIPPING_STOCK"]),

        changePermission() {
            return this.GET_USER.permissions.some(item => item === "inventory.change_dippingstock")
        },

        deletePermission() {
            return this.GET_USER.permissions.some(item => item === "inventory.delete_dippingstock")
        },

        exportFields() {
            return {
                "Date": "posting_date",
                "Product": {
                    field: "product",
                    callback: (value) => {
                        return value.name;
                    }
                },
                "Opening": "opening",
                "Added": "added",
                "Sold": "sold",
                "Dipping": "dipping",
                "Actual": "actual",
                "Variation": "variation"
            }
        }
    },

    methods: {
        ...mapActions(["handleDippingStockDelete", "fetchDippingStock"]),

        deleteDippingStock(item) {
            this.handleDippingStockDelete(item).then(data => {
                this.fetchDippingStock();

				this.$notify.success({ 
					title: "Operation Successful", 
					message: "Stock deleted" 
				});
            })
        },

        handleSelectedDippingStock(val) {
            this.selectedDippingStock = val;
        },

        OrderDippingStockData(val) {
            if(val.order == "descending") {
                this.sortField = "-" + val.prop
            } else {
                this.sortField = val.prop
            }

            this.fetchDippingStock({"ordering": this.sortField})
        },

        searchDippingStock() {
            this.fetchDippingStock({"search": this.search})
        },

        currentPageChanged(value) {
            this.fetchDippingStock({"page": value})
        }
    }
}
</script>