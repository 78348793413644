<template>
    <div>
        <PumpReadingUpdate 
            :showReadingModal="updateModal" 
            :updateObject="updateObject" 
            @onSuccess="updateModal = false" />

        <div class="flex justify-between space-x-4 items-center mb-2">
            <div class="w-full md:w-1/2 xl:w-1/4">
                <el-input v-model="search" size="mini" placeholder="Type to search" @input="searchReading" />
            </div>

            <button class="basic-button" v-if="selectedReadings.length > 0" plain>
                <export-excel :data="selectedReadings" :fields="exportFields" name="Pump Readings.xls">
                    Export Excel
                </export-excel>
            </button>
        </div>

        <el-table   
            border size="mini" 
            :data="GET_READINGS.results" 
            @selection-change="handleSelectedReading" 
            @sort-change="OrderReadingrData" 
            style="width: 100%">

            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column prop="posting_date" label="Date" sortable="custom"></el-table-column>

            <el-table-column prop="pump" label="Pump" align="center" sortable="custom">
                <template slot-scope="scope">{{ scope.row.pump.name }}</template>
            </el-table-column>

            <el-table-column prop="opening" label="Opening" align="center" sortable="custom">
                <template slot-scope="scope">
                    {{ scope.row.opening | number('0,0') }}
                </template>
            </el-table-column>

            <el-table-column prop="closing" label="Closing" align="center" sortable="custom">
                <template slot-scope="scope">
                    {{ scope.row.closing | number('0,0') }}
                </template>
            </el-table-column>

            <el-table-column prop="fuel_return" label="F/Return" align="center" sortable="custom"></el-table-column>

            <el-table-column prop="sold" label="Sold" align="center" sortable="custom"></el-table-column>

            <el-table-column prop="unit_price" label="Price" align="center" sortable="custom">
                <template slot-scope="scope">
                    {{ scope.row.unit_price | number('0,0') }}
                </template>
            </el-table-column>

            <el-table-column prop="total" label="Value" align="center" sortable="custom">
                <template slot-scope="scope">
                    {{ scope.row.total | number('0,0') }}
                </template>
            </el-table-column>

            <el-table-column label="Action" align="center" width="150">
				<template slot-scope="scope">

                    <button class="table-button" @click="updateObject = scope.row; updateModal = true" v-if="changePermission">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                    </button>

                    <el-popconfirm
                        title="Are you sure to delete this?"
                        cancel-button-text="No, Thanks"
                        confirm-button-text="OK"
                        @confirm="deleteReading(scope.row)"
                        v-if="deletePermission">

                        <button class="table-button" slot="reference">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </el-popconfirm>

				</template>
			</el-table-column>

        </el-table>

        <div class="flex justify-end mt-4">
            <el-pagination 
                background layout="prev, pager, next" 
                :total="GET_READINGS.count" :page-size="50"
                @current-change="currentPageChanged">
            </el-pagination>
        </div>
    </div>
    
</template>

<script>
import PumpReadingUpdate from "@/components/pumps/PumpReadingUpdate"
import { mapActions, mapGetters } from "vuex"
import { errorRecursion } from "@/helpers/index.js"

export default {
    name: "PumpReadingList",
    
    components: {
        PumpReadingUpdate
    },

    data() {
        return {
            search: "",
            sortField: "",
            updateObject: {},
			updateModal: false,
            selectedReadings: []
        }
    },

    computed: {
        ...mapGetters(["GET_USER", "GET_READINGS"]),

        changePermission() {
            return this.GET_USER.permissions.some(item => item === "inventory.change_pumpreading")
        },

        deletePermission() {
            return this.GET_USER.permissions.some(item => item === "inventory.delete_pumpreading")
        },

        exportFields() {
            return {
                "Date": "posting_date",
                "Pump": {
                    field: "pump",
                    callback: (value) => {
                        return value.name;
                    }
                },
                "Opening Reading": "opening",
                "Closing Reading": "closing",
                "Fuel Return": "fuel_return",
                "Unit Price": "unit_price",
                "Sold": "sold",
                "Total Value": "total",
            }
        }
    },

    methods: {
        ...mapActions(["handlePumpReadingDelete", "fetchPumpReadings"]),

        deleteReading(item) {
            this.handlePumpReadingDelete(item).then(data => {
				this.fetchPumpReadings();

				this.$notify.success({ 
					title: "Operation Successful", 
					message: "Reading deleted" 
				});
			}).catch(error => {
                let errorList = []
                let message = ""
                errorRecursion(error.data, errorList);
                errorList.forEach((item) => {
                    message += "<li>" + item + "</li>"
                })

                this.$notify.error({ 
                    title: "Error",
                    dangerouslyUseHTMLString: true, 
                    message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                });
            })
        },

        handleSelectedReading(val) {
            this.selectedReadings = val;
        },

        OrderReadingrData(val) {
            if(val.order == "descending") {
                this.sortField = "-" + val.prop
            } else {
                this.sortField = val.prop
            }

            this.fetchPumpReadings({
                ordering: this.sortField
            })
        },

        searchReading() {
            this.fetchPumpReadings({
                search: this.search
            })
        },

        currentPageChanged(value) {
            this.fetchPumpReadings({
                page: value
            })
        }

    },

    mounted() {
        this.fetchPumpReadings()
    }
}
</script>