import axios from "axios"

export default {
    state: {
        delivery: {}
    },

    getters: {
        GET_DELIVERIES(state) {
            return state.delivery
        }
    },

    mutations: {
        SET_DELIVERIES(state, payload) {
            state.delivery = payload
        }
    },

    actions: {
        fetchDeliveries({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_FINANCE_URL + "/delivery/", {
                params: Object.assign({
                    "ordering": payload.ordering, 
					"page": payload.page, 
					"search": payload.search,
					"branch": getters.GET_ACTIVE_BRANCH.id 
                }, payload.filter),
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_DELIVERIES", res.data);
            })
            .catch((err) => {
                // pass
            })
        },

        handleDeliveryCreate({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_FINANCE_URL + "/delivery/", payload, 
				{
					params: {
                        "branch": getters.GET_ACTIVE_BRANCH.id
                    },
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleDeliveryUpdate({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_FINANCE_URL + "/delivery/" + payload.id + "/", payload, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleDeliveryDelete({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_FINANCE_URL + "/delivery/" + payload.id + "/", {"trashed": true}, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },
    }
}