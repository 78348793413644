<template>
    <el-tabs v-model="activeTabName">
        <el-tab-pane label="Chart of Accounts" name="account">
            <AccountList />
        </el-tab-pane>

        <!-- <el-tab-pane label="Reconcilliation" name="reconcilliation">
        </el-tab-pane> -->
    </el-tabs>
</template>

<script>
import AccountList from "./AccountList.vue"

export default {
    name: "Accounts",

    components: {
        AccountList,
    },

    data() {
        return {
            activeTabName: "account"
        }
    }
}
</script>