<template>
    <div class="flex justify-end mb-5">
		<button class="default-button" @click="showProductModal = true">New Product</button>

        <el-dialog 
            title="New Product" 
            :visible.sync="showProductModal" 
            width="50%">

            <el-form ref="form" :model="product" :rules="rules">

                <el-row :gutter="10">
                    <el-col :xs="24" :sm="12" :md="8" :lg="12" :xl="6">
                        <el-form-item label="Name" prop="name" size="mini" required>
                            <el-input type="text" v-model="product.name" size="small"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                        <el-form-item label="Volume (ml)" size="mini">
                            <el-input-number :min="0" v-model="product.volume" class="w-full" size="small"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                        <el-form-item label="Weight (kg)" size="mini">
                            <el-input-number :min="0" v-model="product.weight" class="w-full" size="small"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                        <el-form-item label="Category" prop="category" size="mini" required>
                            <el-select v-model="product.category" placeholder="select category" class="w-full" size="small">
                                <el-option 
                                    v-for="item in GET_PRODUCT_CATEGORY" 
                                    :label="item.label" 
                                    :value="item.name" 
                                    :key="item.name">   
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="Sell Price" prop="price" size="mini">
                            <el-input-number :min="0" v-model="product.price" class="w-full" size="small"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8">
                        <el-form-item label="Limit" prop="limit" size="mini">
                            <el-input-number :min="0" v-model="product.limit" class="w-full" size="small"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="8">
                        <el-form-item>
                            <el-checkbox v-model="product.dipping">Is Dipping</el-checkbox>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="10">
                    <el-col :span="4">
                        <el-form-item>
                            <el-button 
                                @click="createProduct('form')" :loading="loading"
                                class="text-xs text-white hover:text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-600 rounded-md px-6 py-2 focus:outline-none mt-4">
                                Create
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

        </el-dialog>

    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "ProductCreate",
    
    data() {
        return {
            showProductModal: false,
            loading: false,
            product: {
                name: "",
                weight: 0.0,
                volume: 0.0,
                group: "",
                quantity_per_unit: 0,
                price: 0,
                limit: 0,
                dipping: false
            },
            rules: {
                name: [
                    { required: true, message: "Please input name", trigger: "change" },
                ],
                category: [
                    { required: true, message: "Please input category", trigger: "change" },
                ],
                price: [
                    { required: true, message: "Please input price", trigger: "change" },
                ]
            }
        }
    },

    computed: {
        ...mapGetters(["GET_PRODUCT_CATEGORY"])
    },

    methods: {
        ...mapActions(["handleProductCreate", "fetchProducts"]),

        createProduct(form) {
            this.$refs[form].validate((valid) => {
				if(valid) {
                    this.loading = true;
                    this.handleProductCreate(this.product).then(data => {
                        this.showProductModal = false;
                        this.loading = false; 

                		this.$notify.success({ 
                            title: "Operation Successful", 
                            message: `Product ${data.name} created` 
                        });

                        this.$refs[form].resetFields();
                        this.fetchProducts();
                    }).catch(err => {
                        this.$notify.error({ 
                            title: "Operation Failed", 
                            message: `Fill correct important fields` 
                        });
                        this.loading = false; 
                    })
                    
                } else { return false } 
            })
        }
    }
}
</script>