<template>
    <div>
        <div class="flex justify-between space-x-4 items-center my-5">
            <p>Expense Report</p>

            <div class="flex space-x-2">
                <el-button v-if="selectedExpense.length > 0" size="mini" plain>
                    <export-excel 
                        :data="selectedExpense" 
                        :fields="exportFields" 
                        :name="`Expense ` + currentDate + `.xls`">
                        Export Excel
                    </export-excel>
                </el-button>

                <el-date-picker
                    v-model="expenseDate"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="Start Date"
                    end-placeholder="End date"
                    size="mini">
                </el-date-picker>
            </div>
        </div>

		<div class="overflow-auto">
			<el-table	
				border size="mini" 
				:data="expense"
				style="width: 100%" 
				@selection-change="handleSelectedExpense">

				<el-table-column type="selection" width="55"></el-table-column>
				
				<el-table-column prop="date" label="Date"></el-table-column>

				<el-table-column 
					v-for="account in controlNames" 
					:prop="account" 
					:label="account | splitUnderScore" 
					:key="account" 
					align="center">
				</el-table-column>
			</el-table>
		</div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { DateTime } from "luxon"

export default {
    name: "ExpenseReport",

    data() {
        return {
            currentDate: DateTime.now().toFormat("LLLL - yyyy"),
            expense: [],
            expenseDate: null,
            controlDates: [],
            controlNames: [],
            selectedExpense: [],
        }
    },

    watch: {
        expenseDate: function(val) {
            if (val) {
                let min_date = DateTime.fromFormat(val[0].toDateString(), "EEE MMM dd yyyy").toISODate();

                let max_date = DateTime.fromFormat(val[1].toDateString(), "EEE MMM dd yyyy").toISODate();

                this.fetchExpenseReport(min_date, max_date);
            }
        },
    },

    computed: {
        ...mapGetters(["GET_ACTIVE_BRANCH", "GET_TOKEN"]),

        exportFields() {
            var exportColumns = {}
			Object.keys(this.expense[0]).forEach((k) => {
				exportColumns[k.toUpperCase()] = k;
			})

			return exportColumns
        }
    },

    methods: {
        structureExpenseData(rawData) {
            let content = {};
			let temp = ""
            this.controlDates.forEach((d) => {
                this.controlNames.forEach((name) => {
                    temp = rawData.filter((r) => r.date == d).find((x) => x.category == name)
                    temp ? content[name] = temp.total : content[name] = 0
                })

                content["date"] = d
                this.expense.push(content)
                content = {}
            })
        },

        fetchExpenseReport(min=null, max=null) {
            if(min !== null && max !== null) {
                this.currentDate = DateTime.fromISO(min).toFormat("dd-LLLL-yyyy") + ' to ' + DateTime.fromISO(max).toFormat("dd-LLLL-yyyy");
            }

            this.$http.get(process.env.VUE_APP_REPORT_URL + "expense/", {
                params: Object.assign({
					"branch": this.GET_ACTIVE_BRANCH.id
					}, this.expenseDate !== null ? { "min_date": min, "max_date": max } : {}),
                headers: {
                    "Authorization": "Bearer " + this.GET_TOKEN.access
                }
            }).then(res => {
				this.controlDates = []
				this.controlNames = []
				this.expense = []

                res.data.forEach(element => {
                    if(this.controlNames.find(n => n == element.category) == undefined) {
                        this.controlNames.push(element.category)
                    }

                    if(this.controlDates.find(d => d == element.date) == undefined) {
                        this.controlDates.push(element.date)
                    }
                })

                this.structureExpenseData(res.data);
            })
        },

        handleSelectedExpense(val) {
            this.selectedExpense = val;
        }
    },

    mounted() {
        this.fetchExpenseReport()
    }
}
</script>