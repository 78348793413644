<template>
	<div>
		<div class="flex justify-between space-x-4 items-center my-5">
            <p>Purchase Summary</p>

            <div class="flex space-x-2">
                <el-button v-if="rawData.length > 0" size="mini" plain>
                    <export-excel 
						:data="rawData" 
						:fields="exportFields" 
						:name="`Purchase Report` + currentDate + `.xls`">
                        Export Excel
                    </export-excel>
                </el-button>

				<el-button 
					size="mini" 
					v-print="printOject" 
					v-if="purchases.length > 0" plain>
					Export PDF
				</el-button>

                <el-date-picker
                    v-model="purchaseDate"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="Start Date"
                    end-placeholder="End date"
                    size="mini">
                </el-date-picker>
            </div>
        </div>

		<div class="overflow-auto">
			<table class="w-full bg-white border" id="purchaseReport">
				<thead class="border-b bg-gray-50 text-gray-600 text-sm">
					<tr class="divide-x divide-gray-200">
						<th rowspan="2" class="table-head">Date</th>
						<th colspan="4" class="table-head">PMS</th>
						<th colspan="4" class="table-head">GO</th>
						<th colspan="4" class="table-head">IK</th>
						<th colspan="2" class="table-head">Gas</th>
						<th colspan="2" class="table-head">Lubricants</th>
					</tr>
					<tr class="divide-x divide-gray-200">
						<th class="table-head">Ordered</th>
						<th class="table-head">Received</th>
						<th class="table-head">Variation</th>
						<th class="table-head">Total</th>

						<th class="table-head">Ordered</th>
						<th class="table-head">Received</th>
						<th class="table-head">Variation</th>
						<th class="table-head">Total</th>

						<th class="table-head">Ordered</th>
						<th class="table-head">Received</th>
						<th class="table-head">Variation</th>
						<th class="table-head">Total</th>

						<th class="table-head">Quantity</th>
						<th class="table-head">Total</th>

						<th class="table-head">Quantity</th>
						<th class="table-head">Total</th>
					</tr>
				</thead>
				<tbody class="text-xs 2xl:text-sm divide-y divide-gray-200">
					<tr class="divide-x divide-gray-200 text-center" v-for="item in purchases" :key="item.date">
						<td class="px-4 py-2 truncate">{{ item.date }}</td>
					
						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'PMS')">
								{{ findObject(item.data, "PMS").ordered| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>
						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'PMS')">
								{{ findObject(item.data, "PMS").received| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>
						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'PMS')">
								{{ findObject(item.data, "PMS").short| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>
						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'PMS')">
								{{ findObject(item.data, "PMS").amount| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>

						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'GO')">
								{{ findObject(item.data, "GO").ordered| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>
						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'GO')">
								{{ findObject(item.data, "GO").received| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>
						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'GO')">
								{{ findObject(item.data, "GO").short| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>
						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'GO')">
								{{ findObject(item.data, "GO").amount| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>

						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'IK')">
								{{ findObject(item.data, "IK").ordered| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>
						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'IK')">
								{{ findObject(item.data, "IK").received| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>
						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'IK')">
								{{ findObject(item.data, "IK").short| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>
						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'IK')">
								{{ findObject(item.data, "IK").amount| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>

						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'Gas')">
								{{ findObject(item.data, "Gas").quantity| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>
						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'Gas')">
								{{ findObject(item.data, "Gas").amount| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>

						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'Lubricants')">
								{{ findObject(item.data, "Lubricants").quantity| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>
						<td class="px-4 py-2 truncate">
							<span v-if="findObject(item.data, 'Lubricants')">
								{{ findObject(item.data, "Lubricants").amount| number('0,0') }}
							</span>
							<span v-else>0</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

	</div>
</template>

<script>
import { mapGetters } from "vuex"
import { DateTime } from "luxon"

export default {
	name: "PurchaseReport",

	data() {
		return {
			currentDate: DateTime.now().toFormat("LLLL - yyyy"),
			purchaseDate: null,
			rawData: [],
			purchases: [],
			printOject: {
                id: "purchaseReport",
                popTitle: "Purchase Report"
            }
		}
	},

	watch: {
        purchaseDate: function(val) {
			if (val) {
                let min_date = DateTime.fromFormat(val[0].toDateString(), "EEE MMM dd yyyy").toISODate();

                let max_date = DateTime.fromFormat(val[1].toDateString(), "EEE MMM dd yyyy").toISODate();

				this.fetchPurchaseReport(min_date, max_date);
            }
            
        },
    },

	computed: {
		...mapGetters(["GET_ACTIVE_BRANCH", "GET_TOKEN"]),

        exportFields() {
            return {
                "Date": "date",
                "Product": "commodity",
                "Quantity Ordered": "ordered",
                "Quantity Received": "received",
                "Quantity Short": "short",
                "Quantity": "quantity",
                "Total": "amount"
            }
        }
    },

    methods: {
    	structurePurchaseReport(value) {
			value.forEach((element) => {
            	if(this.purchases.find(item => item.date == element.date)) {
            		// pass
            	} else {
            		this.purchases.push({
            			"date": element.date,
            			"data": value.filter(obj => obj.date == element.date)
            		})
            	}
            })

            this.purchases.sort();
		},

		findObject(data, name) {
			return data.find(element => element.commodity == name)
		},

        fetchPurchaseReport(min, max) {
			if(min !== null && max !== null) {
                this.currentDate = DateTime.fromISO(min).toFormat("dd-LLLL-yyyy") + ' to ' + DateTime.fromISO(max).toFormat("dd-LLLL-yyyy");
            }

            this.$http.get(process.env.VUE_APP_REPORT_URL + "purchases/", {
                params: Object.assign({
					"branch": this.GET_ACTIVE_BRANCH.id
					}, this.purchaseDate !== null ? { "min_date": min, "max_date": max } : {}),
                headers: { 
					"Authorization": "Bearer " + this.GET_TOKEN.access
				}
            })
            .then(res => { 
            	this.rawData = res.data;
            	this.purchases = [];
                this.structurePurchaseReport(res.data);
            })
            .catch(err => {
                this.$notify({title: "Failure", type: "error", text: "" })
            })
        },

    },

	mounted() {
		this.fetchPurchaseReport()
	}
}
</script>