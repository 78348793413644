<template>

    <div>
        <div class="flex justify-between space-x-4 items-center mb-2">
            <div class="w-full md:w-1/2 xl:w-1/4">
            </div>

            <button class="basic-button" v-if="selectedReceivable.length > 0">
                <export-excel :data="selectedReceivable" :fields="exportFields" name="Account Receivable.xls">
                    Export Excel
                </export-excel>
            </button>
        </div>

        <el-table   
            border size="mini" 
            :data="accountReceivable" 
            @selection-change="handleSelectedReceivable" 
            style="width: 100%"
            v-if="accountReceivable">

            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column prop="customer" label="Customer"></el-table-column>


             <el-table-column prop="debit" label="Debit" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.debit !== null">
                        {{ scope.row.debit | number('0,0') }}
                    </span>
                    <span v-else>0</span>
                </template>
            </el-table-column>

             <el-table-column prop="credit" label="Credit" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.credit !== null">
                        {{ scope.row.credit | number('0,0') }}
                    </span>
                    <span v-else>0</span>
                </template>
            </el-table-column>

             <el-table-column prop="balance" label="Balance" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.balance !== null">
                        {{ scope.row.balance | number('0,0') }}
                    </span>
                    <span v-else>0</span>
                </template>
            </el-table-column>

        </el-table>
    </div> 
</template>

<script>
import { mapGetters } from "vuex"

export default {
    name: "AccountReceivable",

    data() {
        return {
            selectedReceivable: [],
            accountReceivable: [],
        }
    },

    computed: {
        ...mapGetters(["GET_ACTIVE_BRANCH", "GET_TOKEN"]),

        exportFields() {
            return {
                "Customer Name": "customer",
                "Debit": "debit",
                "Credit": "credit",
                "Balance": "balance"
            }
        }
    },

    methods: {
        fetchReceivables(payload={}) {
            this.$http.get(process.env.VUE_APP_REPORT_URL + "receivables/", {
                params: Object.assign(
                        { 
                            "branch": this.GET_ACTIVE_BRANCH.id,
                        }, payload.filter
                    ),
                headers: { "Authorization": "Bearer " + this.GET_TOKEN.access }
            })
            .then(res => { 
                this.accountReceivable = res.data;
            })
            
            .catch(err => {
                this.$notify({title: "Failure", type: "error", text: err.response.data.details })
            })
        },

        handleSelectedReceivable(val) {
            this.selectedReceivable = val;
        }
    },

    mounted() {
        this.fetchReceivables();
    }
}
</script>