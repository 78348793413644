import Vue from "vue";
import { DateTime } from "luxon";


Vue.filter("time", value => {
    if(value !== undefined || value !== null) {
        return  DateTime.fromISO(value).toLocaleString(DateTime.TIME_SIMPLE)
    }
    return null
})

// eg 20-05-2020
Vue.filter("africanDate", value => {
    if(value !== undefined || value !== null) {
        return DateTime.fromISO(value).toFormat("dd-LL-kkkk")
    }
    return null
})

// eg Apr 5th, 2020
Vue.filter("writtenDate", value => {
    if(value !== undefined || value !== null) {
        return DateTime.fromISO(value).toLocaleString(DateTime.DATE_FULL)
    }
    return null
})

// eg 20-02-2020 12:00 am
Vue.filter("dateTime", value => {
    if(value !== undefined || value !== null) {
        return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT)
    }
    return null
})

// eg Apr 5th, 2020, 12:00 am
Vue.filter("writtenDateTime", value => {
    if(value !== undefined || value !== null) {
        return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED)
    }
    return null
})