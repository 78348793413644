import axios from "axios"

export default {
    state: {
        customers: {}
    },

    getters: {
        GET_CUSTOMERS(state) {
            return state.customers
        }
    },

    mutations: {
        SET_CUSTOMERS(state, payload) {
            state.customers = payload
        }
    },

    actions: {
        fetchCustomers({ commit, getters }, payload={}) {
            axios.get(process.env.VUE_APP_CUSTOMER_URL, {
                params: Object.assign({
                    "ordering": payload.ordering, 
					"page": payload.page, 
					"search": payload.search,
					"branch": getters.GET_ACTIVE_BRANCH.id 
                }, payload.filter),
                headers: {
                    "Authorization": "Bearer " + getters.GET_TOKEN.access
                }
            })
            .then((res) => {
                commit("SET_CUSTOMERS", res.data);
            })
            .catch((err) => {
                // pass
            })
        },

        handleCustomerCreate({ getters }, payload={}) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_CUSTOMER_URL, payload, 
				{
					params: {
                        "branch": getters.GET_ACTIVE_BRANCH.id
                    },
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleCustomerUpdate({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_CUSTOMER_URL + payload.id + "/", payload, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },

        handleCustomerDelete({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_CUSTOMER_URL + payload.id + "/", {"trashed": true}, {
                    headers: {
                        "Authorization": "Bearer " + getters.GET_TOKEN.access
                    }
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err.response)
                })
            })
        },
    }
}