<template>
    <div>
        <div class="flex justify-between space-x-4 items-center my-5">
            <p>Income & Expenditure Report</p>

            <div class="flex space-x-2">
                <el-button v-print="printOject" size="mini" plain>
                    Export PDF
                </el-button>

                <el-date-picker
                    v-model="incomeExpenditureDate"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="Start Date"
                    end-placeholder="End date"
                    size="mini">
                </el-date-picker>
            </div>
        </div>

		<div class="flex flex-col space-y-4 overflow-auto" v-if="incomeExpenditure" id="income_expenditure">
            <div class="text-center">
                <p class="text-lg font-medium">Aula Investment Company Limited</p>

                <p>Income and Expenditure Report</p>

                <p class="space-x-3" v-if="incomeExpenditureDate !== null">
                    <span>As of</span>
                    <span>{{ incomeExpenditureDate[0].toISOString() | writtenDate }}</span>
                    <span>to</span>
                    <span>{{ incomeExpenditureDate[1].toISOString() | writtenDate}}</span>
                </p>
            </div>

            <table class="w-full bg-white border">
                <thead class="border-b bg-gray-50 text-gray-600 text-sm">
                    <tr class="divide-x divide-gray-200">
                        <th class="table-head" rowspan="2">Context</th>
                        <th class="table-head" colspan="2">PMS</th>
                        <th class="table-head" colspan="2">G/O</th>
                        <th class="table-head" colspan="2">Lubs/Gas</th>
                        <th class="table-head" colspan="2">Total</th>
                    </tr>
                    <tr class="divide-x divide-gray-200">
                        <th class="table-head">Quantity</th>
                        <th class="table-head">Value</th>

                        <th class="table-head">Quantity</th>
                        <th class="table-head">Value</th>

                        <th class="table-head">Quantity</th>
                        <th class="table-head">Value</th>

                        <th class="table-head">Quantity</th>
                        <th class="table-head">Value</th>
                    </tr>
                </thead>

                <tbody class="text-xs divide-y divide-gray-200 text-center">
                    <tr class="divide-x">
                        <td class="table-data font-medium capitalize text-left">Turnover</td>

                        <!-- PMS -->
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.turnover, 'category', 'PMS')">
                                {{ findObject(incomeExpenditure.turnover, 'category', 'PMS').quantity| number('0,0') }}
                            </span>
                            <span v-else>-</span>
                        </td>
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.turnover, 'category', 'PMS')">
                                {{ findObject(incomeExpenditure.turnover, 'category', 'PMS').total| number('0,0') }}
                            </span>
                            <span v-else>-</span>
                        </td>

                        <!-- G/O -->
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.turnover, 'category', 'GO')">
                                {{ findObject(incomeExpenditure.turnover, 'category', 'GO').quantity| number('0,0') }}
                            </span>
                            <span v-else>-</span>
                        </td>
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.turnover, 'category', 'GO')">
                                {{ findObject(incomeExpenditure.turnover, 'category', 'GO').total| number('0,0') }}
                            </span>
                            <span v-else>-</span>
                        </td>

                        <!-- LUBS/GAS -->
                         <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.turnover, 'category', 'Gas') || findObject(incomeExpenditure.turnover, 'category', 'Lubricants')">
                                {{ 
                                    findObject(incomeExpenditure.turnover, 'category', 'Gas').quantity +
                                    findObject(incomeExpenditure.turnover, 'category', 'Lubricants').quantity 
                                }}
                            </span>
                            <span v-else>-</span>
                        </td>
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.turnover, 'category', 'Gas') || findObject(incomeExpenditure.turnover, 'category', 'Lubricants')">
                                {{ 
                                    findObject(incomeExpenditure.turnover, 'category', 'Gas').total +
                                    findObject(incomeExpenditure.turnover, 'category', 'Lubricants').quantity
                                }}
                            </span>
                            <span v-else>-</span>
                        </td>

                        <!-- TOTAL -->
                        <td class="table-data">{{ calculatedTurnover.quantity| number('0,0') }}</td>
                        <td class="table-data">{{ calculatedTurnover.value| number('0,0') }}</td>
                    </tr>
                    <tr class="divide-x">
                        <td class="table-data font-medium capitalize text-left">Opening Stock</td>

                        <!-- PMS -->
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.fuel.opening, 'category', 'PMS')">
                                {{ findObject(incomeExpenditure.fuel.opening, 'category', 'PMS').stock| number('0,0') }}
                            </span>
                            <span v-else>-</span>
                        </td>
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.fuel.opening, 'category', 'PMS')">
                                {{ findObject(incomeExpenditure.fuel.opening, 'category', 'PMS').total| number('0,0') }}
                            </span>
                            <span v-else>-</span>
                        </td>

                        <!-- G/O -->
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.fuel.opening, 'category', 'GO')">
                                {{ findObject(incomeExpenditure.fuel.opening, 'category', 'GO').stock| number('0,0') }}
                            </span>
                            <span v-else>-</span>
                        </td>
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.fuel.opening, 'category', 'GO')">
                                {{ findObject(incomeExpenditure.fuel.opening, 'category', 'GO').total| number('0,0') }}
                            </span>
                            <span v-else>-</span>
                        </td>

                        <!-- LUBS/GAS -->
                         <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.lubs_gas.opening, 'category', 'GO') || findObject(incomeExpenditure.lubs_gas.opening, 'category', 'Lubricants')">
                                {{ 
                                    findObject(incomeExpenditure.lubs_gas.opening, 'category', 'GO').stock +
                                    findObject(incomeExpenditure.lubs_gas.opening, 'category', 'Lubricants').stock
                                }}
                            </span>
                            <span v-else>-</span>
                        </td>
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.lubs_gas.opening, 'category', 'Gas') || findObject(incomeExpenditure.lubs_gas.opening, 'category', 'Lubricants')">
                                {{ 
                                    findObject(incomeExpenditure.lubs_gas.opening, 'category', 'Gas').total +
                                    findObject(incomeExpenditure.lubs_gas.opening, 'category', 'Lubricants').total 
                                }}
                            </span>
                            <span v-else>-</span>
                        </td>

                        <!-- TOTAL -->
                        <td class="table-data">{{ calculatedOpeningStock.quantity| number('0,0') }}</td>
                        <td class="table-data">{{ calculatedOpeningStock.value| number('0,0') }}</td>
                    </tr>

                    <tr class="divide-x">
                        <td class="table-data font-medium capitalize text-left">Purchases</td>

                        <!-- PMS -->
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.purchases, 'category', 'PMS')">
                                {{ findObject(incomeExpenditure.purchases, 'category', 'PMS').ordered| number('0,0') }}
                            </span>
                            <span v-else>-</span>
                        </td>
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.purchases, 'category', 'PMS')">
                                {{ findObject(incomeExpenditure.purchases, 'category', 'PMS').total| number('0,0') }}
                            </span>
                            <span v-else>-</span>
                        </td>

                        <!-- G/O -->
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.purchases, 'category', 'GO')">
                                {{ findObject(incomeExpenditure.purchases, 'category', 'GO').ordered| number('0,0') }}
                            </span>
                            <span v-else>-</span>
                        </td>
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.purchases, 'category', 'GO')">
                                {{ findObject(incomeExpenditure.purchases, 'category', 'GO').total| number('0,0') }}
                            </span>
                            <span v-else>-</span>
                        </td>

                        <!-- LUBS/GAS -->
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.purchases, 'category', 'Gas') || findObject(incomeExpenditure.purchases, 'category', 'Lubricants')">
                                {{ 
                                    findObject(incomeExpenditure.purchases, 'category', 'Gas').quantity +
                                    findObject(incomeExpenditure.purchases, 'category', 'Lubricants').quantity
                                }}
                            </span>
                            <span v-else>-</span>
                        </td>
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.purchases, 'category', 'Gas') || findObject(incomeExpenditure.purchases, 'category', 'Lubricants')">
                                {{ 
                                    findObject(incomeExpenditure.purchases, 'category', 'Gas').total +
                                    findObject(incomeExpenditure.purchases, 'category', 'Lubricants').total
                                }}
                            </span>
                            <span v-else>-</span>
                        </td>

                        <!-- TOTAL -->
                        <td class="table-data">{{ calculatedPurchase.quantity| number('0,0') }}</td>
                        <td class="table-data">{{ calculatedPurchase.value| number('0,0') }}</td>
                    </tr>

                    <tr class="divide-x">
                        <td class="table-data font-medium capitalize text-left">Closing Stock</td>

                        <!-- PMS -->
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.fuel.closing, 'category', 'PMS')">
                                {{ findObject(incomeExpenditure.fuel.closing, 'category', 'PMS').stock| number('0,0') }}
                            </span>
                            <span v-else>-</span>
                        </td>
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.fuel.closing, 'category', 'PMS')">
                                {{ findObject(incomeExpenditure.fuel.closing, 'category', 'PMS').total| number('0,0') }}
                            </span>
                            <span v-else>-</span>
                        </td>

                        <!-- G/O -->
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.fuel.closing, 'category', 'GO')">
                                {{ findObject(incomeExpenditure.fuel.closing, 'category', 'GO').stock| number('0,0') }}
                            </span>
                            <span v-else>-</span>
                        </td>
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.fuel.closing, 'category', 'GO')">
                                {{ findObject(incomeExpenditure.fuel.closing, 'category', 'GO').total| number('0,0') }}
                            </span>
                            <span v-else>-</span>
                        </td>

                        <!-- LUBS/GAS -->
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.lubs_gas.closing, 'category', 'GO') || findObject(incomeExpenditure.lubs_gas.closing, 'category', 'Lubricants')">
                                {{ 
                                    findObject(incomeExpenditure.lubs_gas.closing, 'category', 'GO').stock +
                                    findObject(incomeExpenditure.lubs_gas.closing, 'category', 'Lubricants').stock
                                }}
                            </span>
                            <span v-else>-</span>
                        </td>
                        <td class="table-data">
                            <span v-if="findObject(incomeExpenditure.lubs_gas.closing, 'category', 'Gas') || findObject(incomeExpenditure.lubs_gas.closing, 'category', 'Lubricants')">
                                {{ 
                                    findObject(incomeExpenditure.lubs_gas.closing, 'category', 'Gas').total +
                                    findObject(incomeExpenditure.lubs_gas.closing, 'category', 'Lubricants').total
                                }}
                            </span>
                            <span v-else>-</span>
                        </td>

                        <!-- TOTAL -->
                        <td class="table-data">{{ calculatedClosingStock.quantity| number('0,0') }}</td>
                        <td class="table-data">{{ calculatedClosingStock.value| number('0,0') }}</td>
                    </tr>

                    <tr class="divide-x">
                        <td class="table-data font-medium capitalize text-left" colspan="8">Cost of Goods Sold</td>

                        <!-- TOTAL -->
                        <td>{{ costOfGoods| number('0,0') }}</td>
                    </tr>
                </tbody>
                <tfoot class="divide-y border-t bg-gray-50 text-gray-600 text-xs font-medium capitalize">
                    <tr class="divide-x divide-gray-200">
                        <td colspan="6" rowspan="7" class="bg-white"></td>
                        <td class="table-data" colspan="2">Turnover</td>
                        <td class="table-data">{{ calculatedTurnover.value| number('0,0') }}</td>
                    </tr>

                    <tr class="divide-x divide-gray-200">
                        <td class="table-data border-l" colspan="2">Cost of Goods Sold</td>
                        <td class="table-data">{{ costOfGoods| number('0,0') }}</td>
                    </tr>

                    <tr class="divide-x divide-gray-200">
                        <td class="table-data border-l" colspan="2">Gross Profit</td>
                        <td class="table-data">{{ (calculatedTurnover.value - costOfGoods)| number('0,0') }}</td>
                    </tr>

                    <tr class="divide-x divide-gray-200">
                        <td class="table-data border-l" colspan="2">Other Income</td>
                        <td class="table-data">{{ incomeExpenditure.income| number('0,0') }}</td>
                    </tr>

                    <tr class="divide-x divide-gray-200">
                        <td class="table-data border-l" colspan="2">Operating Expenses</td>
                        <td class="table-data">{{ incomeExpenditure.expense| number('0,0') }}</td>
                    </tr>

                    <tr class="divide-x divide-gray-200">
                        <td class="table-data border-l" colspan="2">Offloading Shortages</td>
                        <td class="table-data">{{ incomeExpenditure.offloading_shortage| number('0,0') }}</td>
                    </tr>

                    <!-- Net Profit = gross_profit + other_income - expenses - offloading_shortage -->
                    <tr class="divide-x divide-gray-200">
                        <td class="table-data border-l" colspan="2">Net Profit</td>
                        <td class="table-data"></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
import { mapGetters} from "vuex"
import { DateTime } from "luxon"

export default {
    name: "IncomeExpenditure",

    data() {
        return {
            incomeExpenditureDate: null,
            incomeExpenditure: null,
            
            printOject: {
                id: "income_expenditure",
                preview: false,
                previewTitle: "Aula Income & Expenditure",
                popTitle: "Aula Income & Expenditure"
            }
        }
    },

    watch: {
        incomeExpenditureDate: function(val) {
            this.fetchIncomeExpenditure()
        },
    },

    computed: {
        ...mapGetters(["GET_ACTIVE_BRANCH", "GET_TOKEN"]),

        calculatedTurnover() {
            let ct = {quantity: 0, value: 0}
            if(this.incomeExpenditure) {
                this.incomeExpenditure.turnover.forEach(element => {
                    ct.quantity += element.quantity;
                    ct.value += element.total;
                });
                return ct
            }
            return ct
        },

        calculatedPurchase() {
            let pt = {quantity: 0, value: 0}
            if(this.incomeExpenditure) {
                this.incomeExpenditure.purchases.forEach(element => {
                    pt.quantity += (element.quantity + element.ordered);
                    pt.value += element.total;
                });
                return pt
            }
            return pt
        },

        calculatedClosingStock() {
            let cst = { quantity: 0, value: 0}
            if(this.incomeExpenditure) {
                this.incomeExpenditure.fuel.closing.forEach(element => {
                    cst.quantity += element.stock;
                    cst.value += element.total;
                });

                this.incomeExpenditure.lubs_gas.closing.forEach(element => {
                    cst.quantity += element.stock;
                    cst.value += element.total;
                });
                return cst
            }
            return cst
        },

        calculatedOpeningStock() {
            let ost = { quantity: 0, value: 0}
            if(this.incomeExpenditure) {
                this.incomeExpenditure.fuel.opening.forEach(element => {
                    ost.quantity += element.stock;
                    ost.value += element.total;
                });
                this.incomeExpenditure.lubs_gas.closing.forEach(element => {
                    ost.quantity += element.stock;
                    ost.value += element.total;
                });
                return ost
            }
            return ost
        },

        costOfGoods() {
            return this.calculatedOpeningStock.value + this.calculatedPurchase.value - this.calculatedClosingStock.value
        }

    },

    methods: {
        findObject(data, key, name) {
			return data.find(element => element[key] == name) ? data.find(element => element[key] == name) : {total: 0, quantity: 0, stock: 0, ordered: 0}
		},

        fetchIncomeExpenditure() {
            this.$http.get(process.env.VUE_APP_REPORT_URL + "income-expenditure/", {
                params: Object.assign({
					"branch": this.GET_ACTIVE_BRANCH.id
					}, this.incomeExpenditureDate !== null ? {
					"min_date": DateTime.fromFormat(this.incomeExpenditureDate[0].toDateString(), "EEE MMM dd yyyy").toISODate(),
					"max_date": DateTime.fromFormat(this.incomeExpenditureDate[1].toDateString(), "EEE MMM dd yyyy").toISODate() 
				} : {}),
                headers: {
                    "Authorization": "Bearer " + this.GET_TOKEN.access
                }
            }).then(res => {
                this.incomeExpenditure = res.data
            })
        }
    },

    mounted() {
        this.fetchIncomeExpenditure()
    }
}
</script>