<template>
    <div>
        <div class="grid grid-col-1 lg:grid-cols-2 2xl:grid-cols-3 gap-10 mb-5">

            <div class="border rounded-lg py-2 px-4 text-xs space-y-4">
                <section>
                    <p class="divide-x divide-gray-200 capitalize mb-2">
                        <span class="pr-2">{{ account.category }}</span>
                        <span class="pl-2">{{ account.purpose }}</span>
                    </p>

                    <p class="text-base 2xl:text-lg font-medium space-x-3">
                        <span>{{ account.code }}</span>
                        <span>{{ account.name }}</span>
                    </p>
                </section>

                <p 
                    class="text-xs py-2 border-b border-t border-gray-100" 
                    v-if="account.description">
                    {{ account.description }}
                </p>

                <section class="flex justify-between items-center">
                    <div>
                        <p>SYSTEM BALANCE</p>
                        <p class="text-base 2xl:text-lg text-gray-600 font-medium">
                            {{ account.balance | number('0,0') }} <small>TZS</small>
                        </p>
                    </div>

                    <div>
                        <p>INITIAL BALANCE</p>
                        <p class="text-sm 2xl:text-base text-gray-600 font-medium">
                            {{ account.initial_balance | number('0,0') }} <small>TZS</small>
                        </p>
                    </div>
                </section>

            </div>
        </div>

        <el-table
            border size="mini" 
            :data="GET_ENTRIES.results" 
            style="width: 100%" 
            header-cell-class-name="bg-gray-50 text-gray-500"
            @sort-change="OrderEntryData">

            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column prop="posting_date" label="Date"></el-table-column>

            <el-table-column prop="category" label="Category" align="center"></el-table-column>

            <el-table-column prop="nodes[0].account" label="Account(DR)" align="center">
                <template slot-scope="scope">
                    {{ scope.row.nodes[0].account.code }}
                </template>
            </el-table-column>

            <el-table-column prop="description" label="Description"></el-table-column>

            <el-table-column prop="nodes[1].account" label="Account(CR)" align="center">
                <template slot-scope="scope">
                    {{ scope.row.nodes[1].account.code }}
                </template>
            </el-table-column>

            <el-table-column prop="amount" label="Amount" align="center">
                <template slot-scope="scope">
                    {{ scope.row.amount | number('0,0') }}
                </template>
            </el-table-column>

            <!-- <el-table-column label="Action" align="center" width="150">
                <template slot-scope="scope">
                    
                    <button 
                        class="table-button" @click="$router.push({name: 'entry-detail', params: { entryId: scope.row.id} })">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                    </button>
                </template>
            </el-table-column> -->
        </el-table>

        <div class="flex justify-end mt-4">
            <el-pagination 
                background layout="prev, pager, next" 
                :total="GET_ENTRIES.count" :page-size="50"
                @current-change="currentPageChanged">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
    name: "AccountDetail",

    props: {
        accountId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            account: {},
        }
    },

    watch: {
        account: function(val) {
            this.fetchEntries({
                filter: {
                    account_code: val.code
                }
            })
        }
    },

    computed: {
        ...mapGetters(["GET_TOKEN", "GET_ENTRIES"])
    },

     methods: {
        ...mapActions(["fetchEntries"]),

        fetchAccountDetail() {
            this.$http.get(process.env.VUE_APP_FINANCE_URL + "/accounts/" + this.accountId + "/", {
                headers: {
                    "Authorization": "Bearer " + this.GET_TOKEN.access
                }
            }).then(res => {
                this.account = res.data
            })
        },

        OrderEntryData(val) {
            if(val.order == "descending") {
                this.sortField = "-" + val.prop
            } else {
                this.sortField = val.prop
            }

            this.fetchEntries({
                ordering: this.sortField,
                filter: {
                    account_code: account.code
                }
            })
        },

        currentPageChanged(value) {
            this.fetchEntries({
                page: value,
                filter: {
                    account_code: account.code
                }
            })
        }
    },

    mounted() {
        this.fetchAccountDetail()
    }
}
</script>