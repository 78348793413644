<template>
    <div>
        <div class="flex justify-between space-x-4 items-center my-5">
            <p>Balance Sheet Report</p>

            <div class="flex space-x-2">
                <el-button v-print="printOject" size="mini" plain>
                    Export PDF
                </el-button> 

                <el-date-picker
                    v-model="balanceSheetDate"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="Start Date"
                    end-placeholder="End date"
                    size="mini">
                </el-date-picker>
            </div>
        </div>

        <div class="flex flex-col space-y-4" id="financial_position">
            <div class="text-center">
                <p class="text-lg font-medium">Aula Investment Company Limited</p>

                <p>Statement of Financial Position</p>

                <p class="space-x-3" v-if="balanceSheetDate !== null">
                    <span>As of</span>
                    <span>{{ balanceSheetDate[0].toISOString() | writtenDate }}</span>
                    <span>to</span>
                    <span>{{ balanceSheetDate[1].toISOString() | writtenDate}}</span>
                </p>
            </div>

            <div class="overflow-auto">
                <table class="w-full bg-white border">
                    <thead class="border-b bg-gray-50 text-gray-600 text-sm">
                        <tr class="divide-x divide-gray-200">
                            <th class="table-head">Particulars</th>
                            <th class="table-head">Amount (Tshs)</th>
                        </tr>
                    </thead>

                    <tbody class="text-xs divide-y divide-gray-200">
                        <tr class="font-medium">
                            <td class="px-4 py-2 truncate" colspan="2">ASSETS</td>
                        </tr>

                        <tr class="font-medium">
                            <td class="px-4 py-2 truncate" colspan="2">Current Assets</td>
                        </tr>
                        <tr class="divide-x"
                            v-for="current in filterBy(balanceSheet, 'CURRENT-ASSETS', 'category')" 
                            :key="current.id">
                            <td class="px-8 py-2 truncate">{{ current.name }}</td>
                            <td class="px-4 py-2 truncate">{{ current.balance| number('0,0') }}</td>
                        </tr>
                        
                        <tr class="font-medium">
                            <td class="px-4 py-2 truncate" colspan="2">Fixed Assets</td>
                        </tr>
                        <tr class="divide-x"
                            v-for="non_current in filterBy(balanceSheet, 'FIXED-ASSETS', 'category')" 
                            :key="non_current.id">
                            <td class="px-8 py-2 truncate">{{ non_current.name }}</td>
                            <td class="px-4 py-2 truncate">{{ non_current.balance| number('0,0') }}</td>
                        </tr>

                        <tr class="divide-x font-medium bg-gray-50">
                            <td class="px-4 py-2 truncate">TOTAL ASSETS</td>
                            <td class="px-4 py-2 truncate">
                                {{ balanceSheetObject.assets | number('0,0') }}
                            </td>
                        </tr>

                        <tr class="font-medium">
                            <td class="px-4 py-2 truncate" colspan="2">EQUITY & LIABILITIES</td>
                        </tr>

                        <tr class="font-medium">
                            <td class="px-4 py-2 truncate" colspan="2">Equity</td>
                        </tr>

                        <tr class="divide-x"
                            v-for="equity in filterBy(balanceSheet, 'EQUITY', 'category')" 
                            :key="equity.id">
                            <td class="px-4 py-2 truncate">{{ equity.name }}</td>
                            <td class="px-4 py-2 truncate">{{ equity.balance| number('0,0') }}</td>
                        </tr>

                        <tr class="divide-x">
                            <td class="px-8 py-2 truncate">Net Profit</td>
                            <td class="px-4 py-2 truncate">PLACEHOLDER</td>
                        </tr>

                        <tr class="font-medium">
                            <td class="px-4 py-2 truncate" colspan="2">Current Liability</td>
                        </tr>

                        <tr class="divide-x" 
                            v-for="current in filterBy(balanceSheet, 'CURRENT-LIABILITY', 'category')" 
                            :key="current.id">
                            <td class="px-8 py-2 truncate">{{ current.name }}</td>
                            <td class="px-4 py-2 truncate">{{ current.balance| number('0,0') }}</td>
                        </tr>

                        <tr class="font-medium">
                            <td class="px-4 py-2 truncate" colspan="2">Long Term Liability</td>
                        </tr>

                        <tr class="divide-x"
                            v-for="non_current in filterBy(balanceSheet, 'LONG-TERM-LIABILITY', 'category')" 
                            :key="non_current.id">
                            <td class="px-8 py-2 truncate">{{ non_current.name }}</td>
                            <td class="px-4 py-2 truncate">{{ non_current.balance| number('0,0') }}</td>
                        </tr>

                        <tr class="divide-x font-medium bg-gray-50">
                            <td class="px-4 py-2 truncate">TOTAL EQUITY & LIABILITY</td>
                            <td class="px-4 py-2 truncate">
                                {{ (totalEquity.amount + balanceSheetObject.liability) | number('0,0') }}
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</template>

<script>
import Vue2Filters from "vue2-filters"
import { mapGetters } from "vuex"
import { DateTime } from "luxon"

export default {
    name: "BalanceSheet",

    mixins: [Vue2Filters.mixin],

    data() {
        return {
            balanceSheet: [],
            balanceSheetDate: null,
            totalAssets: [
                { category: "CURRENT-ASSETS", text: "Current Assets",  amount: 0 }, 
                { category: "FIXED-ASSETS", text: "Fixed Assets",  amount: 0 }
            ],
            totalEquity: { category: "EQUITY", text: "Equity",  amount: 0},
            totalLiability: [
                { category: "CURRENT-LIABILITY", text: "Current Liability",  amount: 0 }, 
                { category: "LONG-TERM-LIABILITY", text: "Long Term Liability",  amount: 0 }
            ],
            balanceSheetObject: {
                assets: 0,
                liability : 0
            },
            printOject: {
                id: "financial_position",
                preview: false,
                previewTitle: "Aula Statement of Financial Position",
                popTitle: "Aula Statement of Financial Position"
            }
        }
    },

    watch: {
        balanceSheetDate: function(val) {
            if (val) {
                let min_date = DateTime.fromFormat(val[0].toDateString(), "EEE MMM dd yyyy").toISODate();

                let max_date = DateTime.fromFormat(val[1].toDateString(), "EEE MMM dd yyyy").toISODate();

                this.fetchTrialBalance(min_date, max_date);
            }
        },

        balanceSheet: function(val) {
            val.forEach(element => {
                if (element.category === "CURRENT-ASSETS") {
                    this.totalAssets[0].amount += element.balance;

                } else if(element.category === "FIXED-ASSETS") {
                    this.totalAssets[1].amount += element.balance;

                } else if(element.category === "CURRENT-LIABILITY") {
                    this.totalLiability[0].amount += element.balance;

                } else if (element.category === "LONG-TERM-LIABILITY") {
                    this.totalLiability[1].amount += element.balance;;

                } else {
                    this.totalEquity.amount += element.balance;
                }
            });

            this.populateBalanceSheet();
        }
    },

    computed: {
        ...mapGetters(["GET_ACTIVE_BRANCH", "GET_TOKEN"]),
    },
     
    methods: {

        populateBalanceSheet() {
            this.balanceSheetObject.assets = this.totalAssets[0].amount +  this.totalAssets[1].amount;
            this.balanceSheetObject.liability = this.totalLiability[0].amount + this.totalLiability[1].amount;

        },

        fetchTrialBalance(min=null, max=null) {
            let params = new URLSearchParams();
            params.append("ordering", "category")
            params.append("category", "CURRENT-ASSETS")
            params.append("category", "FIXED-ASSETS")
            params.append("category", "EQUITY")
            params.append("category", "CURRENT-LIABILITY")
            params.append("category", "LONG-TERM-LIABILITY")
            params.append("branch", this.GET_ACTIVE_BRANCH.id)

            this.$http.get(process.env.VUE_APP_REPORT_URL + "trial-balance/", {
                params: Object.assign(
                    params, 
                    this.balanceSheetDate !== null ? { "min_date": min, "max_date": max } : {}
                ),
                headers: {
                    "Authorization": "Bearer " + this.GET_TOKEN.access
                }
            }).then(res => {
                // clear first before calculating new total value
                this.balanceSheetObject.assets = this.balanceSheetObject.liability = 0;

                this.totalEquity.amount = this.totalAssets[0].amount = this.totalAssets[1].amount = 0;
                this.totalLiability[0].amount = this.totalLiability[1].amount = 0;


                this.balanceSheet = res.data;
            })
        },
    },

    mounted() {
        this.fetchTrialBalance();
    }
}
</script>
