<template>
    <div>
        <AccountCreate v-if="addPermission" />

        <AccountUpdate 
            :showAccountModal="updateModal" 
            :updateObject="updateObject" 
            @onSuccess="updateModal = false" />

        <div class="flex justify-between space-x-4 items-center mb-2">
            <div class="w-full md:w-1/2 xl:w-1/4">
                <el-input 
                    v-model="search" 
                    size="mini" 
                    placeholder="Type to search" 
                    @input="searchAccount" />
            </div>

            <button 
                class="basic-button" 
                v-if="selectedAccounts.length > 0">
                <export-excel 
                    :data="selectedAccounts" 
                    :fields="exportFields" 
                    name="Accounts.xls">
                    Export Excel
                </export-excel>
            </button>
        </div>

        <el-table 
			border size="mini" 
			:data="GET_ACCOUNTS.results" 
			style="width: 100%"
            @selection-change="handleSelectedAccounts"
            @sort-change="OrderAccountData">

            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column prop="code" label="Code" align="center" width="80"></el-table-column>
			
			<el-table-column prop="name" label="Name"></el-table-column>

            <el-table-column prop="category" label="Type"></el-table-column>

			<el-table-column prop="purpose" label="Purpose" align="center"></el-table-column>

            <el-table-column prop="initial_balance" label="I/Balance" align="center">
                <template slot-scope="scope">
                    {{ scope.row.initial_balance | number('0,0') }}
                </template>
            </el-table-column>

            <el-table-column prop="balance" label="C/Balance" align="center">
                <template slot-scope="scope">
                    {{ scope.row.balance | number('0,0') }}
                </template>
            </el-table-column>

			<el-table-column label="Action" align="center" width="150">
				<template slot-scope="scope">
                    
                    <button 
                        class="table-button" 
                        @click="$router.push({name: 'account-detail', params: { accountId: scope.row.id} })">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                    </button>

                    <button 
                        class="table-button"
                        v-if="changePermission && !scope.row.default" 
                        @click="updateObject = scope.row; updateModal = true">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                    </button>

                    <el-popconfirm
                        title="Are you sure to deactivate this?"
                        cancel-button-text="No, Thanks"
                        confirm-button-text="OK"
                        @confirm="deleteAccount(scope.row)"
                        v-if="deletePermission">

                        <button class="table-button" slot="reference">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </el-popconfirm>

				</template>
			</el-table-column>
        </el-table>

        <div class="flex justify-end mt-4">
            <el-pagination 
                background layout="prev, pager, next" 
                :total="GET_ACCOUNTS.count" :page-size="50"
                @current-change="currentPageChanged">
            </el-pagination>
        </div>

    </div>
</template>

<script>
import AccountCreate from "@/components/accounts/AccountCreate"
import AccountUpdate from "@/components/accounts/AccountUpdate"
import { mapActions, mapGetters } from "vuex"
import { errorRecursion } from "@/helpers/index.js"

export default {
    name: "AccountList",

    components: {
		AccountCreate,
		AccountUpdate
	},

    data() {
    	return {
			updateObject: {},
			updateModal: false,
            search: "",
            selectedAccounts: []
    	}
    },

    computed: {
        ...mapGetters(["GET_ACCOUNTS", "GET_USER"]),

        addPermission() {
            return this.GET_USER.permissions.some(item => item === "finance.add_account")
        },

        changePermission() {
            return this.GET_USER.permissions.some(item => item === "finance.change_account")
        },

        deletePermission() {
            return this.GET_USER.permissions.some(item => item === "finance.delete_account")
        },

        exportFields() {
            return {
                "Name": "name",
                "Code": "code",
                "Type": "parent_category",
                "Category": "child_category",
                "Purpose": "purpose",
                "Description": "description",
                "Initial Balance": "initial_balance",
                "Current Balance": "balance",
            }
        }
    }, 
    
    methods: {
        ...mapActions(["handleAccountDelete", "fetchAccounts"]),

        deleteAccount(item) {
            this.handleAccountDelete(item).then(data => {
				this.fetchAccounts();

				this.$notify.success({ 
					title: "Operation Successful", 
					message: "Account deleted" 
				});
			}).catch(error => {
                let errorList = []
                let message = ""
                errorRecursion(error.data, errorList);
                errorList.forEach((item) => {
                    message += "<li>" + item + "</li>"
                })

                this.$notify.error({ 
                    title: "Error",
                    dangerouslyUseHTMLString: true, 
                    message: `<ul class='list-disc list-inside text-red-600 space-y-1.5'>${message}</ul>` 
                });
            })
        },

        handleSelectedAccounts(val) {
            this.selectedAccounts = val
        },

        OrderAccountData(val) {
            if(val.order == "descending") {
                this.sortField = "-" + val.prop
            } else {
                this.sortField = val.prop
            }

            this.fetchAccounts({
                ordering: this.sortField
            })
        },

        searchAccount() {
            this.fetchAccounts({
                search: this.search
            })
        },

        currentPageChanged(value) {
            this.fetchAccounts({
                page: value
            })
        }
    },
    
    mounted() {
        this.fetchAccounts()
    }
}
</script>